import { CONTACT_STEP } from "./quoteConstants";
import { isEmpty } from "lodash";

export default {
    setStep(state, data) {
        state.step = data
    },

    setUserData(state, data) {
        state.order.userData = data
    },

    setSelectedLayers(state, data) {
        let multiple = this.getters['quote/website'].multiple;

        if (multiple) {
            //find if current layer already in selectedLayers array
            let index = state.order.selectedLayers.findIndex(function(element) {
                return data.id === element.id;
            });

            //if not -> delete all selectedOptions for this layer
            if (index !== -1 && state.order.selectedLayers.length) {
                state.upgrades = state.upgrades.filter(updade => updade.parentAttributeId != state.order.selectedLayers[index].id)
                state.order.selectedOptions = state.order.selectedOptions.filter(option => {
                    let check = option.pivot.parent_attribute_id !== state.order.selectedLayers[index].id;

                    if (check) {
                        this.dispatch('quote/getUpgrades', { id: option.id, mutationName: 'setUpgrades' });
                    }

                    return check;
                });

                //delete selectedLayer from array
                state.order.selectedLayers.splice(index, 1);
            } else {
                //if yes -> add layer to array and set some new data to him

                data.isSelected = false;
                data.feet = 0;
                data.idDisabled = false;
                data.idClear = false;

                state.order.selectedLayers.push(data);
            }
        } else {
            //if selectedLayers array is empty -> add layer inside with empty selectedOptions

            state.order.selectedOptions = [];
            data.isSelected = false;
            data.feet = 0;
            data.idDisabled = false;
            data.idClear = false;

            state.order.selectedLayers = [data];
        }

        if (data.id) {
            this.dispatch('quote/getAttributes', { id: data.id, mutationName: 'setOptions' });
        }

        /*if (currentSelectedLayers.id && currentSelectedLayers.id !== data.id) {
            this.commit('quote/clearCurrentStep', 'type_style');
        }*/
    },

    setSimpleSelectedLayers(state, data) {
        for (const item of data) {
            item.isSelected = false;
            item.feet = 0;
            item.idDisabled = false;
            item.idClear = false;
        }

        state.order.selectedLayers = data;
    },

    setSelectedOptions(state, data) {
        let index = state.order.selectedOptions.findIndex(function(element) {
            return data.id === element.id;
        });

        let indexParent = state.order.selectedOptions.findIndex(function(element) {
            return data.pivot.parent_attribute_id === element.pivot.parent_attribute_id;
        });

        if (index !== -1) {
            state.order.selectedOptions.splice(index, 1);
        } else if (indexParent !== -1) {
            state.order.selectedOptions[indexParent] = data;
        } else {
            state.order.selectedOptions.push(data);
        }

        this.dispatch('quote/getUpgrades', { id: data.id, mutationName: 'setUpgrades' });
    },

    setSelectedUpgrades(state, data) {
        let index = state.order.selectedUpgrades.findIndex(function(element) {
            return data.id === element.id;
        });

        if (index !== -1) {
            state.order.selectedUpgrades.splice(index, 1);
        } else {
            state.order.selectedUpgrades.push(data);
        }
    },

    setSelectedUnitItem(state, data) {
        let items = state.order.selectedSingleUnitItems;

        let elemIndex = items.findIndex((element) => {
            return data === element;
        });

        if (elemIndex === -1 && data.quantity > 0) {
            items.push(data);
        } else {
            for (let i in items) {
                if (items[i].id === data.id && data.quantity > 0) {
                    items[i].quantity = data.quantity;
                } else if (items[i].id === data.id && data.quantity < 1) {
                    items.splice(Number(i), 1);
                }
            }
        }

        state.order.selectedSingleUnitItems = items;
    },

    setLayers(state, data) {
        state.layers = data
    },

    setOptions(state, data) {
        let filtersData = data.filter(e => e.type === '2');

        for (const layer of state.order.selectedLayers) {
            let elemIndex = state.options.findIndex((element) => layer.id === element.layerId);

            if (elemIndex === -1) {
                let items = filtersData.filter(e => e.pivot.parent_attribute_id === layer.id);

                if (items.length > 0) {
                    state.options.push({
                        layerId: layer.id,
                        layerName: layer.value,
                        items: items
                    });
                }
            }
        }

        state.options = state.options.filter(option => {
            return state.order.selectedLayers.some(layer => layer.id === option.layerId);
        });
    },

    setUpgrades(state, data) {
        let filtersData = data.filter(e => e.type === '3');

        for (const option of state.order.selectedOptions) {
            let elemIndex = state.upgrades.findIndex((element) => option.id === element.optionId);

            if (elemIndex === -1) {
                let items = filtersData.filter(e => e.pivot.parent_attribute_id === option.id);
                let checkParentAttribute = state.upgrades.findIndex(e => e.parentAttributeId === option.pivot.parent_attribute_id);

                if (items.length > 0 && checkParentAttribute === -1) {
                    state.upgrades.push({
                        optionId: option.id,
                        optionName: option.parents[0]['value'],
                        items: items,
                        parentAttributeId: option.pivot.parent_attribute_id
                    });
                } else if (items.length > 0 && checkParentAttribute !== -1) {
                    state.upgrades[checkParentAttribute] = {
                        optionId: option.id,
                        optionName: option.parents[0]['value'],
                        items: items,
                        parentAttributeId: option.pivot.parent_attribute_id
                    }
                } else if (items.length < 1 && checkParentAttribute !== -1) {
                    state.upgrades.splice(checkParentAttribute, 1);
                }
            }
        }

        for (const upgrade of state.upgrades) {
            let elem = state.order.selectedOptions.filter((element) => element.id === upgrade.optionId);

            if(isEmpty(elem)) {
                let index = state.upgrades.findIndex(e => e.optionId === upgrade.optionId);
                state.upgrades.splice(index, 1);
            }
        }

        for (const selectedUpgrade in state.order.selectedUpgrades) {
            let find = state.upgrades.filter(e => e.optionId === state.order.selectedUpgrades[selectedUpgrade].pivot.parent_attribute_id);

            if (find.length === 0) {
                state.order.selectedUpgrades.splice(selectedUpgrade, 1);
            }
        }
    },

    setUnitItems(state, data) {
        state.singleUnitItems = data;
    },

    imageData(state, data) {
        state.order.imageData = data
    },

    setQuoteWebsite(state, data) {
        state.website = data.website;
        state.website.settings = data.settings;
    },

    setButtonSettings(state, data) {
        state.buttonSettings = data;
    },

    setSettings(state, data) {
        state.settings = data;
    },

    setRanges(state, data) {
        state.ranges.low = data.low
        state.ranges.high = data.high
    },

    setSummaryData(state, data) {
        state.summaryData.items = data?.items;
        state.summaryData.total = data?.total;
        state.summaryData.currency = data?.currency;
        state.summaryData.metric = data?.metric;
        state.summaryData.simpleUnitItems = data?.simpleUnitItems;
        state.summaryData.perUnitLayers = data?.perUnitLayers;
    },

    setMapLoader(state, data) {
        state.mapLoader = data
    },

    setWebsite(state, data) {
        state.website = data
    },

    setdisabledSize(state, data) {
        state.disabledSize = data
    },

    setStepTransform(state, data) {
        state.transformData.stepTransform = Number(data)
    },

    setIndent(state, data) {
        state.transformData.indent = Number(data)
    },

    setStepHeight(state, data) {
        state.transformData.height = data
    },

    setLocationData(state, { unitSystem, currencyConverter, currencyCode, currencySymbol }) {
        state.locationData.unitSystem = unitSystem
        state.locationData.currency.converter = currencyConverter
        state.locationData.currency.code = currencyCode
        state.locationData.currency.symbol = currencySymbol
    },

    clearState(state) {
        state.order.selectedLayers = [];
        state.order.selectedOptions = [];
        state.order.selectedUpgrades = [];
        state.order.selectedSingleUnitItems = [];

        state.options = [];
        state.upgrades = [];

        state.singleUnitItems.forEach(item => {
            item.quantity = 0;
        });

        state.transformData.stepTransform = 0;
        state.transformData.indent = 0;
        state.step = CONTACT_STEP;
    },

    clearCurrentStep(state, nameStep) {
        if (nameStep === 'type_style') {
            state.order.selectedOptions = [];
            state.options = [];
            state.upgrades = [];
            state.order.selectedUpgrades = [];
            state.order.selectedSingleUnitItems = [];
        }
    },

    updateLayerData (state, data) {
        let index = state.order.selectedLayers.findIndex((element) => data.id === element.id);

        if (index !== -1) {
            if (data.hasOwnProperty('isSelected')) {
                for (let layerItem of state.order.selectedLayers) {
                    layerItem.isSelected = layerItem.id === state.order.selectedLayers[index].id;
                }
            }

            Object.assign(state.order.selectedLayers[index], data);
        }
    },

    updateControlPanelLayersData (state, data) {
        let index = state.layers.findIndex((element) => data.id === element.id);

        if (index !== -1) {
            if (data.hasOwnProperty('isSelected')) {
                for (let layerItem of state.layers) {
                    layerItem.isSelected = layerItem.id === state.layers[index].id;
                }
            }

            Object.assign(state.layers[index], data);
        }
    },

    setWindowSize(state, data) {
        state.windowSize.height = data.height;
        state.windowSize.width = data.width;
    },

    clearWithNone(state, data) {
        if (data.none === 'upgrade') {
            state.order.selectedUpgrades = state.order.selectedUpgrades.filter(item => item.pivot.parent_attribute_id !== data.parentId);
        }
    },

    clearOptions(state) {
        state.order.selectedOptions = [];
    },

    clearLayersFeet(state) {
        for (const layer of state.order.selectedLayers) {
            layer.feet = 0;
            layer.isClear = false;
            layer.isSelected = false;
        }
    }
}
