export const filteringFields = [{
        name: 'Customer',
        value: 'customer_email'
    },
    {
        name: 'Invoice number',
        value: 'number'
    },
    {
        name: 'Amount',
        value: 'amount'
    }
    /* {
         name: 'Payment date',
         value: 'period_start'
     }*/
]