<template>
    <GeneralLayout>
        <div>
            <LogoutItem />
            <DarkModeSwitcher />
            <transition name="payment">
                <SelectSubscribe
                    v-show="showPayment"
                    :disabled-buttons="disabledButtons"
                    @buyClick="buy"
                />
            </transition>
        </div>
    </GeneralLayout>
</template>

<script setup>
import { computed, inject, ref, getCurrentInstance, onMounted } from 'vue';
import DarkModeSwitcher from '@components/DarkModeSwitcher.vue';
import LogoutItem from '@components/LogoutItem.vue';
import SelectSubscribe from './SelectSubscribe.vue';
import axios from 'axios';
import GeneralLayout from "../../Layouts/GeneralLayout.vue";


const toast = inject('toast');
const disabledButtons = ref(false);
const showPayment = ref(true);
const { proxy } = getCurrentInstance();
const darkMode = computed(() => store.state.main.darkMode);
const subData = ref({
    priceId: '',
});


const subscribe = async () => {
    await axios.post('/api/v1/user/subscribe', {priceId: subData.value.priceId}).then((response) => {
        window.location = response.data;
    }).catch((e) => {
        proxy.$errorHandler(e);
        disabledButtons.value = false;
    });
}

const buy = async (val, sub) => {
    disabledButtons.value = true;

    subData.value.name = sub.name;
    subData.value.price = sub.price;
    subData.value.priceId = sub.priceId;

    await subscribe();
}

onMounted(() =>  {
    if (disabledButtons.value) {
        disabledButtons.value = false;
    }
});
</script>

<style lang="scss">
.payment-enter-active, .payment-leave-active {
    transition: opacity 0.5s ease;
}

.payment-enter-from, .payment-leave-to {
    opacity: 0;
}

.Input {
    color: white !important;
}
</style>
