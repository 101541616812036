export default {
    register (context, payload) {
        return this.axios.post('/api/v1/register', payload)
    },
    login (context, payload) {
        return this.axios.post('custom-login', payload);
    },
    logout () {
        return this.axios.post('/api/v1/logout')
    },
    csrfCookie () {
        return this.axios.get('/api/v1/csrf-cookie ')
    },
    user () {
        return this.axios.get('/api/v1/user')
    },
    settings () {
        return this.axios.get('/api/get-app-all-settings')
    },
    changePassword (context, payload) {
        return this.axios.put('/api/v1/user/password', payload)
    },
    changeProfileInformation (context, payload) {
        return this.axios.put('/api/v1/user/profile-information', payload)
    },
    changeAvatar (context, payload) {
        return this.axios.post('/api/v1/user/update-avatar', payload)
    },
    removeAvatar () {
        return this.axios.post('/api/v1/user/remove-avatar')
    },
    forgotPassword (context, payload) {
        return this.axios.post('/api/v1/forgot-password', payload)
    },
    resetPassword (context, payload) {
        return this.axios.post('/api/v1/reset-password', payload)
    },
    createPassword (context, payload) {
        return this.axios.post('/api/v1/user/create-password', payload)
    }
}
