<template>
    <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="flex gap-3">
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                <input
                    id="tabulator-html-filter-value"
                    v-model="searchQuery"
                    type="text"
                    class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                    placeholder="Search..."
                >
            </div>
            <div class="mt-2 xl:mt-0">
                <MainButton
                    title="Reset"
                    size-button="medium"
                    id="tabulator-html-filter-reset"
                    class-map="w-full"
                    @click="onResetFilter"
                />
            </div>
        </form>
    </div>
</template>

<script setup>
import { ref, defineEmits, watch } from 'vue';
import MainButton from "@components/Button/MainButton.vue";


const emit = defineEmits(['search']);
const searchQuery = ref('');

const onResetFilter = () => {
    searchQuery.value = '';
}

watch(
    () => searchQuery.value,
    (value) => {
        emit('search', value);
    }
);
</script>
