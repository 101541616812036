export default {
    step: state => state.step,

    selectedLayers: state => state.order.selectedLayers,
    selectedOptions: state => state.order.selectedOptions,
    selectedUpgrades: state => state.order.selectedUpgrades,
    selectedSingleUnitItems: state => state.order.selectedSingleUnitItems,

    selectedLayersId: state => state.order.selectedLayers ? state.order.selectedLayers.map(obj => obj.id) : [],
    selectedOptionsId: state => state.order.selectedOptions ? state.order.selectedOptions.map(obj => obj.id) : [],
    selectedUpgradesId: state => state.order.selectedUpgrades ? state.order.selectedUpgrades.map(obj => obj.id) : [],
    selectedUpgradesPrice: state => state.order.selectedUpgrades ? state.order.selectedUpgrades.map(obj => obj.price) : [],

    getLayers: state => state.layers,
    getOptions: state => state.options,
    getUpgrades: state => state.upgrades,
    getSingleUnitItems: state => state.singleUnitItems,

    getMapLoader: state => state.mapLoader,
    getPlace: state => state.order.userData.place,
    getTransformData: state => state.transformData,
    getLocationData: state => state.locationData,
    website: state => state.website,
    getSettings: state => state.settings,
    getButtonSettings: state => state.buttonSettings,
    getOrderData: state => state.order,
    getRanges: state => state.ranges,
    getSummaryData: state => state.summaryData,
    getUserData: state => state.order.userData,
    getImageData: state => state.order.imageData,
    getWindowSize: state => state.windowSize,
    getdisabledSize: state => state.disabledSize,
}
