export default function guide ({ next, store }){
    if (!store.getters['auth/loggedIn']){
        return next({
            name: 'login'
        });
    }

    store.getters['auth/user'].roles.forEach((e)=>{
        if (e.code === 'admin') {
            return next({
                name: 'users-management'
            });
        }
    });

    if (store.getters['auth/loggedIn']) {
        const user = store.getters['auth/user'];

        if (user.hasAttributes) {
            return next({
                name: 'dashboard'
            })
        }
    }

    return next();
}
