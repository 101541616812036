<template>
    <GeneralLayout>
        <div>
            <DarkModeSwitcher />
            <div class="container">
                <div
                    class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
                >
                    <div class="-intro-x lg:mr-20">
                        <img
                            :src="image"
                            alt="This page has gone missing."
                            class="h-48 lg:h-auto"
                        >
                    </div>
                    <div class="mt-10 lg:mt-0">
                        <div class="intro-x text-8xl font-medium">
                            404
                        </div>
                        <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
                            Oops. This page has gone missing.
                        </div>
                        <div class="intro-x text-lg mt-3">
                            You may have mistyped the address or the page may have moved.
                        </div>
                        <router-link
                            :to="{ name: 'dashboard' }"
                            tag="a"
                            class="intro-x btn py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10"
                        >
                            Back to Home
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
      <a href="/new-guide">new guide</a>
    </GeneralLayout>
</template>

<script setup>
import { onMounted } from 'vue'
import DarkModeSwitcher from '@components/DarkModeSwitcher.vue'
import cash from 'cash-dom/dist/cash';
import image from '@images/error-illustration.svg';
import GeneralLayout from "../../Layouts/GeneralLayout.vue";


onMounted(() => {
    cash('body').
    removeClass('main').
    removeClass('login').
    addClass('error-page');
});
</script>
