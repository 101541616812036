const TYPE_PLAN_MEASURE_BASIC = 0;
const TYPE_PLAN_MEASURE_PRO = 1;
const TYPE_PLAN_BASIC = 2;
const TYPE_PLAN_PREMIUM = 3;


export {
    TYPE_PLAN_MEASURE_BASIC,
    TYPE_PLAN_MEASURE_PRO,
    TYPE_PLAN_BASIC,
    TYPE_PLAN_PREMIUM,
}