<template>
    <GeneralLayout>
        <DarkModeSwitcher />
        <div>
            <div class="min-h-screen">
                <div class="flex justify-center text-center items-center flex-col h-screen mx-4">
                    <div class="pb-[4rem]">
                        <img :src="logoTransparent" alt="" class="w-[205px]">
                    </div>
                    <MainBlock class-map="w-full max-w-[550px]" title="Create Password">
                        <template #body>
                            <form class="w-full" @submit.prevent="save">
                                <fieldset>
                                    <div class="mt-8">
                                        <FormInput
                                            v-model="validate.password.$model"
                                            :errors="validate.password.$errors"
                                            label="Password"
                                            type="password"
                                            placeholder="Enter password"
                                        />
                                    </div>
                                    <div class="flex justify-center gap-4 mt-4">
                                        <MainButton title="Save" type="submit" />
                                    </div>
                                </fieldset>
                            </form>
                        </template>
                    </MainBlock>
                </div>
            </div>
        </div>
    </GeneralLayout>
</template>

<script setup>
import { onMounted, inject, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import DarkModeSwitcher from '@components/DarkModeSwitcher.vue';
import logoTransparent from '@images/svg/logo.svg';
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import MainBlock from "@components/MainBlock.vue";
import GeneralLayout from "../../Layouts/GeneralLayout.vue";


const store = useStore();
const router = useRouter();
const token = router.currentRoute._value.params.token;
const passwordData = reactive({
    password: '',
    token: token
});
const passwordData_v = {
    password: { required, minLength: minLength(8) },
}
const toast = inject('toast');
const validate = useVuelidate(passwordData_v, toRefs(passwordData));


const save = async () => {
    validate.value.$touch();

    if (!validate.value.$invalid) {
        await store.dispatch('auth/createPassword', passwordData).then((response) => {
            toast({
                text: response.data.message,
                type: 'success'
            });

            router.push({ name: 'login' });
        }).catch((e) => {
            toast({
                text: e.response.data.message,
                type: 'danger'
            });
        });
    }
}


onMounted(() => {
    document.getElementsByTagName('body')[0].classList.add('login');
});
</script>
