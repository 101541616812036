<template>
    <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form
            id="tabulator-html-filter-form"
            @submit.prevent="onFilter"
        >
            <div class="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-6 gap-y-3 md:gap-x-3">
                <div class="sm:flex items-center xl:mt-0 gap-2 col-span-1">
                    <div>
                        <label>
                            Field
                        </label>
                    </div>
                    <div class="flex-grow">
                        <CustomSelect
                            v-model="filter.field"
                            :options="filteringFields"
                            placeholder="Select Field"
                        />
                    </div>
                </div>
                <div class="sm:flex items-center gap-2 col-span-2">
                    <div>
                        <label>
                            Value
                        </label>
                    </div>
                    <input
                        id="tabulator-html-filter-value"
                        v-model="filter.value"
                        type="text"
                        class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                        placeholder="Search..."
                    >
                </div>
                <div class="flex gap-4 col-span-1">
                    <MainButton
                        id="tabulator-html-filter-go"
                        title="Go"
                        class-map="w-full"
                        @click="onFilter"
                    />
                    <MainButton
                        id="tabulator-html-filter-reset"
                        title="Reset"
                        class-map="w-full"
                        @click="onResetFilter"
                    />
                </div>
            </div>
        </form>
    </div>
</template>
<script setup>
import { defineEmits, reactive } from 'vue';
import MainButton from "../Button/MainButton.vue";
import CustomSelect from "../UI/CustomSelect.vue";


const props = defineProps({
    filteringFields: {
        type: Array,
        default: () => []
    }
});
const emit = defineEmits(['filter']);
const filter = reactive({
    field: 'style',
    type: 'like',
    value: ''
});


const onFilter = () => {
    emit('filter', filter);
}

const onResetFilter = () => {
    filter.field = 'style';
    filter.type = 'like';
    filter.value = '';

    emit('filter', { filter });
}
</script>
