<template>
    <div
        :id="id"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="p-5 text-center">
                        <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>
                        <div class="flex justify-center">
                            <img v-if="typeImage === 'bucket'" :src="deleteImage" alt="">
                            <img v-else :src="cancelActiveSub" alt="">
                        </div>
                        <div v-text="title" class="text-3xl mt-5" />
                        <div v-html="text" class="text-gray-600 mt-2" />
                    </div>
                    <div class="px-5 pb-8 text-center flex justify-center gap-4">
                        <button type="button" data-dismiss="modal" class="button danger">
                            {{ leftButtonText }}
                        </button>
                        <button type="button" class="button" @click="emit('delete')">
                            {{ rightButtonText }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import deleteImage from "@images/svg/delete-modal.svg";
import cancelActiveSub from "@images/svg/cancel-active-sub.svg";


const props = defineProps({
    id: {
        type: [ Number, String ],
        required: true
    },
    title: {
        type: String,
        default: 'Are you sure?'
    },
    text: {
        type: String,
        default: "Do you really want to delete? This  process cannot be undone."
    },
    leftButtonText: {
        type: String,
        default: 'Cancel'
    },
    rightButtonText: {
        type: String,
        default: 'Yes'
    },
    typeImage: {
        type: String,
        default: 'bucket'
    }
});

const emit = defineEmits(['cancel', 'delete']);
</script>

<style scoped>

</style>
