<template>
    <div>
        <div
            :style="{backgroundColor: modelValue ?? '#000000'}"
            class="w-[25px] h-[25px] rounded-full cursor-pointer border border-theme-32"
            id="selectColor"
            @click="showColorPicker=!showColorPicker"
        />

        <div
            ref="colorPicker"
            class="absolute translate-y-1"
        >
            <div
                v-if="showColorPicker"
                class="p-4 bg-white overflow-y-auto rounded-lg"
            >
                <div class="color-grid">
                    <div
                        v-for="(color, index) in colorList"
                        :key="index"
                        :style="{backgroundColor: color.hex}"
                        :class="['hover:scale-125 rounded-full cursor-pointer w-[25px] h-[25px] border border-theme-32', {'border-2' : color.isSelected}]"
                        :title="color.isSelected ? 'Selected' : 'Not Selected'"
                        @click="onColorChange(color.hex)"
                    />
                </div>
            </div>
        </div>

        <template v-if="errors && errors.length > 0">
            <div
                v-for="(error, index) in errors"
                :key="index"
                class="text-theme-6 mt-2"
            >
                {{ error.$message }}
            </div>
        </template>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref, watch } from 'vue';
import "vue3-colorpicker/style.css";
import colors from "@json/colors.json";


const props = defineProps({
    modelValue: {
        type: [ String, Number ],
        default: '#000000',
    },
    classMap: {
        type: String,
        required: false,
        default: '',
    },
    errors: {
        type: Array,
        required: false,
        default: '',
    },
});

const emit = defineEmits([ 'update:modelValue' ]);
const showColorPicker = ref(false);
const colorPicker = ref(null);
const colorList = ref([]);

const onColorChange = (newColor) => {
    emit('update:modelValue', newColor);
}


const closeColorPicker = (event) => {
    if (event.target.getAttribute('id') !== 'selectColor' && showColorPicker.value && colorPicker.value && !colorPicker.value.contains(event.target)) {
        showColorPicker.value = false;
    }
};

const handleClickOutside = (event) => {
    if (showColorPicker.value) {
        closeColorPicker(event);
    }
};


watch(
    () => props.modelValue,
    (hex) => {
        for (let color of colorList.value) {
            color.isSelected = color.hex === hex;
        }
    }
);

onMounted(() => {
    for (const color of colors) {
        colorList.value.push({ hex: color.hex, isSelected: false })
    }

    window.addEventListener('click', handleClickOutside);
});
</script>

<style lang="scss" scoped>
.color-grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-gap: 0.5rem;

    @media (min-width: 350px) {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }
}
</style>