export const usersManagementColumns = [
    {
        title: 'Name',
        minWidth: 200,
        responsive: 0,
        field: 'full_name',
        titleFormatter: function(cell, formatterParams, onRendered){
            return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
        }
    },
    {
        title: 'Company name',
        minWidth: 200,
        responsive: 0,
        field: 'company_name',
        hozAlign: 'center',
        titleFormatter: function(cell, formatterParams, onRendered){
            return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
        }
    },
    {
        title: 'Email',
        field: 'email',
        hozAlign: 'center'
    },
    {
        title: 'Owner name',
        minWidth: 200,
        responsive: 0,
        field: 'owner.full_name',
        hozAlign: 'center'
    }
]
