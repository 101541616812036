export const subscribeManagementColumns = [
    {
        formatter: 'responsiveCollapse',
        width: 40,
        minWidth: 30,
        hozAlign: 'center',
        vertAlign: 'middle',
        resizable: false,
        headerSort: false
    },
    {
        title: 'Customer',
        minWidth: 200,
        responsive: 0,
        field: 'customer'
    },
    {
        title: 'Status',
        minWidth: 200,
        responsive: 0,
        field: 'status',
        hozAlign: 'center'
    },
    {
        title: 'Product',
        minWidth: 200,
        responsive: 0,
        field: 'product',
        hozAlign: 'center'
    },
    {
        title: 'Period',
        minWidth: 200,
        responsive: 0,
        field: 'period',
        hozAlign: 'center'
    }
]