<template>
  <!-- Customer Information -->
  <div class="customer-info-wrapper info-wrapper">
    <div class="customer-info info">
      <h2>Customer Information</h2>
      <h3>Primary Contact</h3>
      <div class="row">
        <span>Full Name:</span>
        <span>{{ order.userData.name }}</span>
      </div>
      <div class="row">
        <span>Email:</span>
        <span>{{ order.userData.email }}</span>
      </div>
      <div class="row">
        <span>Phone Number:</span>
        <span>{{ order.userData.phone }}</span>
      </div>
      <div class="row">
        <span>Address:</span>
        <span>{{ order.userData.address }}</span>
      </div>
    </div>
    <!--            <button class="edit-information"></button>-->
  </div>

  <!-- Budget Summary -->
  <div class="budget-summary-wrapper info-wrapper">
    <div class="info">
      <h2>Budget Summary</h2>
      <h3>Options</h3>
      <div
        v-for="(item, index) in summaryData.items"
        :key="index"
        class="service"
      >
        <div class="row row-title">
          <span>Service:</span>
          <span>{{ item.layer.name }}</span>
        </div>
        <div class="row">
          <span>Option:</span>
          <span>{{ item.option.name }}</span>
        </div>
        <div class="row">
          <span>{{ item.layer.metric.fullName }}:</span>
          <span>{{ item.layer.feet }} {{item.layer.metric.shortName}}</span>
        </div>
        <div class="row">
          <span>Option Cost:</span>
          <span>
                    <template v-if="item.option.price === 'Price TBD'">
                      {{ item.option.price }}
                    </template>
                    <template v-else-if="typeof item.option.price === 'object'">
                      {{ currency }}{{ setFormatPrice(item.option.price.low) }} -
                      {{ currency }}{{ setFormatPrice(item.option.price.high) }}
                    </template>
                    <template v-else>
                      {{ currency }}{{ setFormatPrice(item.option.price) }}
                    </template>
                  </span>
        </div>

        <div v-if="!isEmpty(item.upgrades)" class="upgrate-block">
          <h3>Upgrades</h3>
          <div
            v-for="(upgrade, upgradeIndex) in item.upgrades"
            :key="upgradeIndex"
            class="upgrate"
          >
            <div class="row row-title">
              <span>Upgrade:</span>
              <span>{{ upgrade.name }}</span>
            </div>
            <div class="row">
              <span>Price:</span>
              <span>
                          <template v-if="upgrade.price === 'Price TBD'">
                            {{ upgrade.price }}
                          </template>
                          <template v-else-if="typeof upgrade.price === 'object'">
                            {{ currency }}{{ setFormatPrice(upgrade.price.low) }} - {{ currency }}{{ setFormatPrice(upgrade.price.high) }}
                          </template>
                          <template v-else>
                            {{ currency }}{{ setFormatPrice(upgrade.price) }}
                          </template>
                        </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isEmpty(summaryData.simpleUnitItems) && summaryData.perUnitLayers.length > 0" class="unit-items-block">
        <h3>Single Unit Items:</h3>
        <div v-for="unitLayer in summaryData.perUnitLayers" :key="'per-unit' + unitLayer.id">
          <h3>{{ unitLayer.layer.name }}</h3>
          <div
            v-for="(simpleUnitItem, simpleUnitItemIndex) in summaryData.simpleUnitItems.filter(unit => unit.parents[0].id == unitLayer.layer.id)"
            :key="simpleUnitItemIndex"
            class="unit-item"
          >
            <div class="row row-title">
              <span>{{ simpleUnitItem.name }}:</span>
              <span>{{ simpleUnitItem.quantity }} {{ simpleUnitItem.quantity > 1 ? 'units': 'unit'}}</span>
            </div>
            <div class="row">
              <span>Cost:</span>
              <span>
                        <template v-if="typeof simpleUnitItem.price === 'object'">
                        {{ currency }}{{ setFormatPrice(simpleUnitItem.price.low) }} - {{ currency }}{{ setFormatPrice(simpleUnitItem.price.high) }}
                      </template>
                      <template v-else>
                        {{ currency }}{{ setFormatPrice(simpleUnitItem.price) }}
                      </template>
                      </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="total-summary info-wrapper">
      <div class="info">
        <div class="row row-title">
          <h3>Total Cost:</h3>
          <h3>
            <template v-if="typeof summaryData.total === 'object'">
              {{ currency }}{{ setFormatPrice(summaryData.total.low) }} - {{ currency }}{{ setFormatPrice(summaryData.total.high) }}
            </template>
            <template v-else>
              {{ currency }}{{ setFormatPrice(summaryData.total) }}
            </template>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { setFormatPrice } from "@scripts/plugins/functions";
import { isEmpty } from "lodash";
const showSpinner = ref(false);

const store = useStore();
const selectedLayers = computed({
  get() {
    return store.getters['quote/selectedLayers'];
  },
  set(value) {
    store.commit('quote/setSelectedLayers', value);
  }
});
const summaryData = store.getters['quote/getSummaryData'];
const order = computed(() => store.getters['quote/getOrderData']);
const innerWidth = computed(() => store.getters['quote/getWindowSize'].width);
const currency = computed(() => summaryData.currency ?? '$');
const metric = computed(() => summaryData.metric === 'Meters' ? 'Meters' : 'SQFT');
</script>

<style lang="scss" scoped>
.info-wrapper {
  margin: 0 0 32px;

  .info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 24px;
    }

    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 12px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      border-bottom: 1px solid #D9D9D9;

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      span:last-child {
        font-weight: 400;
      }
    }

    .row-title {
      padding: 12px 0;
      span:first-child {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .upgrate-block, .unit-items-block {
      margin-top: 24px;
    }

    .service, .upgrate, .unit-item {
      padding: 12px 0;
      border-bottom: 1px solid #D9D9D9;
      border-top: 1px solid #D9D9D9;

      .row {
        border-bottom: none;
      }
    }
  }
}
</style>
