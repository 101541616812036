export const columns = [
    {
        title: 'Date',
        responsive: 0,
        field: 'created_at',
        hozAlign: 'center',
        titleFormatter: function(cell, formatterParams, onRendered){
            return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
        }
    },
    {
        title: 'Name',
        responsive: 0,
        field: 'name',
        hozAlign: 'center',
        titleFormatter: function(cell, formatterParams, onRendered){
            return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
        }
    },
    {
        title: 'Phone',
        field: 'phone',
        hozAlign: 'center'
    },
    {
        title: 'Email',
        field: 'email',
        hozAlign: 'center'
    },
    {
        title: 'Address',
        field: 'address',
        hozAlign: 'center'
    },
    {
        title: 'City',
        field: 'city',
        hozAlign: 'center'
    }
]
