<template>
    <div style="margin-top: -0.3rem">
        <form @submit.prevent="save">
            <div class="flex items-center">
                <Title title="Change Password" type-title="medium" class="!mb-2 !mt-0" />
            </div>
            <fieldset>
                <FormInput
                    v-model="validate.current_password.$model"
                    :errors="validate.current_password.$errors"
                    label="Old Password"
                    id="change-password-form-1"
                    type="password"
                    placeholder="Input old password"
                />

                <div class="mt-3">
                    <FormInput
                        v-model="validate.password.$model"
                        :errors="validate.password.$errors"
                        label="New Password"
                        id="change-password-form-2"
                        type="password"
                        placeholder="Input new password"
                    />
                </div>

                <div class="mt-3">
                    <FormInput
                        v-model="validate.password_confirmation.$model"
                        :errors="validate.password_confirmation.$errors"
                        label="Confirm New Password"
                        id="change-password-form-3"
                        type="password"
                        placeholder="Input new password"
                    />
                </div>
                <div class="items-center flex justify-center">
                    <MainButton
                        :type-button="loading ? 'disabled' : ''"
                        type="submit"
                        class-map="mt-4"
                        title="Change Password"
                    />
                </div>
            </fieldset>
        </form>
    </div>
</template>

<script setup>
import { minLength, required, sameAs } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { computed, inject, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import FormInput from "@components/UI/Input.vue";
import Title from "@components/Title.vue";
import MainButton from "@components/Button/MainButton.vue";


const store = useStore();
const toast = inject('toast');
const loading = computed(() => store.getters['main/loading']);
const formData = reactive({
    current_password: '',
    password: '',
    password_confirmation: ''
});
const formData_v = {
    current_password: { required, minLength: minLength(8) },
    password: { required, minLength: minLength(8) },
    password_confirmation: { required, sameAs: sameAs(computed(()=> formData.password))},
}
const validate = useVuelidate(formData_v, toRefs(formData));


const resetForm = () => {
    formData.current_password = '';
    formData.password = '';
    formData.password_confirmation = '';

    validate.value.$reset();
}

const save = async () => {
    store.commit('main/setLoading', true)
    validate.value.$touch();

    if (!validate.value.$invalid) {
        try {
            await store.dispatch('auth/changePassword', formData);
            resetForm();
            toast({
                type: 'success',
                text: 'Password successfully updated!'
            });
        } catch (e) {
            toast({
                type: 'danger',
                text: e.response.data.message
            });
        }
    }

    store.commit('main/setLoading', false);
}
</script>
