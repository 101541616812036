import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

//Auth routes
import Login from '~/Pages/Auth/Login.vue';
import Register from '~/Pages/Auth/Register.vue';
import ForgotPassword from '~/Pages/Auth/ForgotPassword.vue';
import ResetPassword from '~/Pages/Auth/ResetPassword.vue';
import CreatePassword from '~/Pages/Auth/CreatePassword.vue';
import AdminPage from '~/Pages/SuperAdmin/AdminPage.vue';

//Website routes
import Website from '~/Pages/Website/WebsitePage.vue';
import WebsiteGeneralTab from '~/Pages/Website/Tabs/GeneralTab.vue';
import WebsiteMainPageTab from '~/Pages/Website/Tabs/MainPageTab.vue';

//Guide route
//import Guide from '~/Pages/Guide/GuidePage.vue';
import NewGuide from '~/Pages/Guide/NewGuidePage.vue';
import GuideRedirect from '~/Pages/Guide/GuideRedirectPage.vue'

//PaymentMethod route
import PaymentMethod from '~/Pages/PaymentMethod/PaymentMethodPage.vue';

//Dashboard route
import Dashboard from '~/Pages/Dashboard/DashboardPage.vue';

//Notifications route
import NotificationsPage from '~/Pages/Notification/NotificationsPage.vue';

//Profile routes
import Profile from '~/Pages/Profile/ProfilePage.vue';
import UpdateProfile from '~/Pages/Profile/UpdateProfilePage.vue';
import ChangePassword from '~/Pages/Profile/ChangePassword.vue';

//SideMenu route
import SideMenu from '@layouts/SideMenu/Main.vue';

import ErrorPage from '~/Pages/ErrorPage/ErrorPage.vue';

//Tabulator route
import Tabulator from '~/Pages/Tabulator/TabulatorPage.vue';

import ProductsPage from '~/Pages/Products/ProductsPage.vue';
import Customers from '~/Pages/Customers/CustomersPage.vue';
import CustomerPage from '~/Pages/Customers/CustomerPage.vue';
import PricingRules from '~/Pages/PricingRules/PricingRulesPage.vue';
import EmailSettings from '~/Pages/EmailSettings/EmailSettingsPage.vue';
import PriceCatalog from '~/Pages/PriceCatalog/PriceCatalogsPage.vue';
import PriceCatalogPage from '~/Pages/PriceCatalog/PriceCatalogPage.vue';
import UserPage from '~/Pages/UsersManagement/UserPage.vue';
import UsersPage from '~/Pages/UsersManagement/UsersPage.vue';
import PlansPage from '~/Pages/Plan/PlansPage.vue';


// SuperAdmin routes
import SuperAdminUsersPage from '~/Pages/SuperAdmin/UsersManagement/UsersPage.vue';
import SuperAdminUserPage from '~/Pages/SuperAdmin/UsersManagement/UserPage.vue';

import websitesManagement from '~/Pages/SuperAdmin/WebsitesManagement/WebsitesPage.vue';
import websiteDetails from '~/Pages/SuperAdmin/WebsitesManagement/WebsitePage.vue';

import transactionManagement from '~/Pages/SuperAdmin/TransactionManagement/TransactionsPage.vue';

import SubscriptionPlans from '~/Pages/SuperAdmin/SubscriptionPlans/SubscriptionsPage.vue';
import subscribeManagement from '~/Pages/SuperAdmin/SubscribeManagement/SubscribesPage.vue';

import influencersManagement from '~/Pages/SuperAdmin/InfluencersManagement/InfluencersPage.vue';

import SubscriptionSettings from '~/Pages/SuperAdmin/SubscriptionSettings/SubscriptionSettingsPage.vue';

//Langing
import MainPage from '~/Landing/Pages/MainPage.vue';
import QuotePage from '~/Landing/Pages/QuotePage.vue';
import PrintQuote from '~/Landing/Pages/PrintQuote.vue'

//middlewares
import auth from '../middleware/auth';
import guest from '../middleware/guest';
import subscribed from '../middleware/subscribed';
import superAdmin from '../middleware/superAdmin';
import checkSubType from '../middleware/checkSubType';
import selectedWebsite from '../middleware/selectedWebsite';
import checkInfluencer from '../middleware/checkInfluencer';
import checkPaymentWebsite from '../middleware/checkPaymentWebsite';
import notAccessForSalesRole from '../middleware/salesRole';
import haveAccess from '../middleware/haveAccess.js';
import managersManagement from '../middleware/routes/managersManagement.js';
import website from '../middleware/routes/website.js';
import guideMiddleware from '../middleware/routes/guide.js';
import GuideRedirectPage from '../../views/Pages/Guide/GuideRedirectPage.vue'

const routes = [
    {
        path: '/admin/',
        component: SideMenu,
        meta: {
            middleware: [ auth ]
        },
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: Dashboard,
                meta: {
                    middleware: [ checkPaymentWebsite ]
                }
            },
            {
                path: 'managers-management',
                name: 'managers-management',
                component: UsersPage,
                meta: {
                    middleware: [ managersManagement ]
                }
            },
            {
                path: 'managers-management/:id',
                name: 'manager-details',
                component: UserPage,
                meta: {
                    middleware: [ managersManagement ]
                }
            },
            {
                path: 'notifications',
                name: 'notifications',
                component: NotificationsPage,
                meta: {
                    middleware: [ checkSubType, selectedWebsite ]
                }
            },
            {
                path: 'products',
                name: 'products',
                component: ProductsPage,
                meta: {
                    middleware: [ selectedWebsite, notAccessForSalesRole ]
                }
            },
            {
                path: 'products/:id',
                name: 'product-details',
                component: ProductsPage,
                meta: {
                    middleware: [ notAccessForSalesRole ]
                }
            },
            {
                path: 'website',
                component: Website,
                meta: {
                    middleware: [ website ]
                },
                children: [
                    {
                        path: '',
                        name: 'website',
                        component: WebsiteGeneralTab,
                    },
                    {
                        path: 'main',
                        name: 'website-main',
                        component: WebsiteMainPageTab,
                    }
                ],
            },
            {
                path: 'customers',
                name: 'customers',
                component: Customers,
                meta: {
                    middleware: [ checkSubType, selectedWebsite ]
                }
            },
            {
                path: 'customers/:id',
                name: 'customer-details',
                component: CustomerPage,
                meta: {
                    middleware: [ checkSubType, selectedWebsite ]
                }
            },
            {
                path: 'pricing-rules',
                name: 'SideMenu-pr-rules',
                component: PricingRules,
                meta: {
                    middleware: [ checkSubType, selectedWebsite, notAccessForSalesRole ]
                }
            },
            {
                path: 'price-catalog',
                name: 'SideMenu-pr-catalog',
                component: PriceCatalog,
                meta: {
                    middleware: [ checkSubType, selectedWebsite, notAccessForSalesRole ]
                }
            },
            {
                path: 'price-catalog/:id',
                name: 'SideMenu-pr-catalog-details',
                component: PriceCatalogPage,
                meta: {
                    middleware: [ checkSubType, selectedWebsite, notAccessForSalesRole ]
                }
            },
            {
                path: 'email-settings',
                name: 'EmailSettings',
                component: EmailSettings,
                meta: {
                    middleware: [ checkSubType, selectedWebsite ]
                }
            },
            {
                path: 'plans',
                name: 'plans',
                component: PlansPage,
                meta: {
                    middleware: [ checkInfluencer ]
                }
            },
            {
                path: 'users-management/:page?',
                name: 'users-management',
                component: SuperAdminUsersPage,
                meta: {
                    middleware: [ superAdmin ]
                }
            },
            {
                path: 'users-management/:id/:page',
                name: 'user-details',
                component: SuperAdminUserPage,
                meta: {
                    middleware: [ superAdmin ]
                }
            },
            {
                path: 'websites-management',
                name: 'websites-management',
                component: websitesManagement,
                meta: {
                    middleware: [ superAdmin ]
                }
            },
            {
                path: 'websites-management/:id',
                name: 'website-details',
                component: websiteDetails,
                meta: {
                    middleware: [ superAdmin ]
                }
            },
            {
                path: 'transaction-management',
                name: 'transaction-management',
                component: transactionManagement,
                meta: {
                    middleware: [ superAdmin ]
                }
            },
            {
                path: 'subscribe-management',
                name: 'subscribe-management',
                component: subscribeManagement,
                meta: {
                    middleware: [ superAdmin ]
                }
            },
            {
                path: 'influencers-management',
                name: 'influencers-management',
                component: influencersManagement,
                meta: {
                    middleware: [ superAdmin ]
                }
            },
            {
                path: 'settings',
                name: 'settings-admin',
                component: AdminPage,
                meta: {
                    middleware: [ superAdmin ]
                }
            },
            {
                path: 'subscription-settings',
                name: 'subscription-settings',
                component: SubscriptionSettings,
                meta: {
                    middleware: [ superAdmin ]
                }
            },
            {
                path: 'subscription-plans',
                name: 'subscription-plans',
                component: SubscriptionPlans,
                meta: {
                    middleware: [ superAdmin ]
                }
            },
            {
                path: 'profile',
                component: Profile,
                children: [
                    {
                        path: '',
                        name: 'profile',
                        component: UpdateProfile
                    },
                    {
                        path: 'change-password',
                        name: 'profile-change-password',
                        component: ChangePassword
                    }
                ],
            }
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            middleware: [ guest ]
        }
    },
    {
        path: '/add-plan',
        name: 'add-plan',
        component: PaymentMethod,
        meta: {
            middleware: [ auth ]
        }
    },
    {
        path: '/guide',
        name: 'guide',
        component: NewGuide,
        meta: {
            middleware: [ guideMiddleware ]
        }
    },
    {
        path: '/guide-redirect',
        name: 'guide-redirect',
        component: GuideRedirect,
        meta: {
            middleware: [ auth ]
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            middleware: [ guest ]
        }
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword,
        meta: {
            middleware: [ guest ]
        }
    },
    {
        path: '/create-password/:token',
        name: 'createPassword',
        component: CreatePassword,
        meta: {
            middleware: [ guest ]
        }
    },
    {
        path: '/reset-password/:token',
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            middleware: [ guest ]
        }
    },
    {
        path: '/error-page',
        name: 'error-page',
        component: ErrorPage
    },
    {
        path: '/',
        name: 'landing-page',
        component: MainPage
    },
    {
        path: '/quote',
        name: 'landing-page-quote',
        component: QuotePage
    },
    {
       path: '/print-quote',
       name: 'print-quote',
       component: PrintQuote
    },
    {
        path: '/:pathMatch(.*)*',
        component: ErrorPage
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || {
            left: 0,
            top: 0
        }
    }
});


function middlewarePipeline(context, middleware, index) {
    const nextMiddleware = middleware[index];

    if (!nextMiddleware) {
        return context.next;
    }

    return () => {
        nextMiddleware({
            ...context,
            next: middlewarePipeline(context, middleware, index + 1),
        });
    };
}

router.beforeEach(async(to, from, next) => {
    subscribed({ next, store });
    haveAccess({ next, store });

    const middleware = to.meta.middleware;

    if (middleware) {
        const context = { to, from, next, store }

        return middleware[0]({
            ...context,
            next: middlewarePipeline(context, middleware, 1),
        });
    }

    return next();
});

export default router
