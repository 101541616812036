const setFormatPrice = (price) => {
    if (price) {
        let number = Number(price);
        if (number > 0) {
            let parts = number.toFixed(4).split(".");

            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            parts[1] = parts[1].replace(/0+$/, '');

            return parts[1] === '' || parseInt(parts[1]) === 0 ? parts[0] : parts.join(".");
        }
    }

    return price;
}

const copy = (value) => {
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}


const generateRandomString = (strLength = 20, withSpecialChars = true) => {
    const chars = withSpecialChars
        ? "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        : "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let str = "";

    for (let i = 0; i <= strLength; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        str += chars.substring(randomNumber, randomNumber + 1);
    }

    return str;
}

export {
    copy,
    setFormatPrice,
    generateRandomString
};
