<template>
    <div
        class="w-2 h-2 rounded-full mr-3 inline-block"
        :class="{
            'bg-theme-12': color === 'success',
            'bg-theme-11': color === 'warning',
            'bg-theme-6': color === 'danger'
        }"
    />
</template>

<script>
export default {
    name: 'UIDot',
    props: {
        color: {
            type: String,
            validator (color) {
                return ['success', 'warning', 'danger'].includes(color)
            },
            default: 'success'
        }
    }
}
</script>
