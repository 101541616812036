<template>
    <div class="my-2 inline-block">
        <div class="flex gap-4 cursor-pointer text-theme-37" @click="switchContent">
            <div class="block text-decoration-underline">{{ title }}</div>
            <span :style="`transform: rotate(${showContent ? '90deg' : '270deg'}); transition: 0.3s;`">&#10094;</span>
        </div>
        <slot v-if="showContent" class="block" name="body" />
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';


const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    content: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(['switchContent']);
const showContent = ref(false);


const switchContent = () => {
    showContent.value = !showContent.value;
    emit('switchContent', showContent.value);
}
</script>

<style scoped>

</style>
