<script setup>
import SelectBlockTitle from "@components/BlockTitle.vue";
import QuoteSummaryContent from '../Components/QuoteSummaryContent.vue'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
const router = useRoute()

import html2pdf from 'html2pdf.js'

function downloadPDF() {
  try {
    const element = document.getElementById('print-quote');

    const opt = {
      margin: 1,
      filename: 'quote.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(element).toPdf().get('pdf').then(function (pdf) {
      window.open(pdf.output('bloburl'));
    });
    
  } catch (error) {
    console.log(error)
  }
}

onMounted(() => {
  const flag = router.query.flag
  if (flag === 'print') {
    window.print()
  }
  if (flag === 'download') {
    downloadPDF()
  }
})
</script>

<template>
  <div id="print-quote">
    <SelectBlockTitle title="Quote Summary" />
    
    <QuoteSummaryContent />
  </div>
</template>

<style scoped>
#print-quote {
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
}
</style>
