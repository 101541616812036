export default {
    install: (app) => {
        const $toast = app.config.globalProperties.$toast

        app.config.globalProperties.$errorHandler = (e) => {
            if (e.response?.data?.errors) {
                return Object.values(e.response.data.errors).forEach(err => {
                    $toast({
                        type: 'danger',
                        text: err
                    })
                })

            } else if (e.response?.data) {
                return Object.values(e.response.data).forEach(err => {
                    $toast({
                        type: 'danger',
                        text: err
                    })
                })
            } else if (e.response?.data?.message) {
                return $toast({
                    type: 'danger',
                    text: e.response.data.message
                })
            } else {
                return $toast({
                    type: 'danger',
                    text: 'Unexpected error'
                })
            }
        }
    }
}
