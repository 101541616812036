<template>
    <component
        :is="tag"
        ref="tippyRef"
    >
        <slot />
    </component>
</template>

<script>
import { defineComponent, ref, inject, onMounted, watch } from 'vue'
import tippy, { roundArrow, animateFill } from 'tippy.js'
import 'tippy.js/dist/tippy.css';


export default defineComponent({
    props: {
        content: {
            type: String,
            required: true
        },
        tag: {
            type: String,
            default: 'span'
        },
        options: {
            type: Object,
            default: () => ({})
        },
        refKey: {
            type: String,
            default: null
        },
        placement: {
            type: String,
            default: 'top'
        },
        mobileResponsive: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const tippyRef = ref()
        const init = () => {
            tippy(tippyRef.value, {
                plugins: [ animateFill ],
                content: props.content,
                placement: props.placement,
                arrow: roundArrow,
                popperOptions: {
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                rootBoundary: 'viewport',
                            }
                        }
                    ]
                },
                onShow(instance) {
                    if (window.innerWidth < 535 && props.mobileResponsive) {
                        instance.popper.style.width = '125px'
                    }
                },
                animateFill: false,
                animation: 'shift-away',
                ...props.options
            })
        }

        const bindInstance = () => {
            if (props.refKey) {
                const bind = inject(`bind[${props.refKey}]`)
                if (bind) {
                    bind(tippyRef.value)
                }
            }
        }

        watch(
            () => props.content,
            (content) => {
                tippy(tippyRef.value, {
                    plugins: [ animateFill ],
                    content: content,
                    placement: props.placement,
                    arrow: roundArrow,
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'preventOverflow',
                                options: {
                                    rootBoundary: 'viewport',
                                }
                            }
                        ]
                    },
                    onShow(instance) {
                        if (window.innerWidth < 535 && props.mobileResponsive) {
                            instance.popper.style.width = '125px'
                        }
                    },
                    animateFill: false,
                    animation: 'shift-away',
                    ...props.options
                })
            }
        );

        onMounted(() => {
            init()
            bindInstance()
        })

        return {
            tippyRef
        }
    }
})
</script>


