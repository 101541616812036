export const transactionManagementColumns = [
    {
        formatter: 'responsiveCollapse',
        width: 40,
        minWidth: 30,
        hozAlign: 'center',
        vertAlign: 'middle',
        resizable: false,
        headerSort: false
    },
    {
        title: 'Amount',
        minWidth: 200,
        responsive: 0,
        field: 'amount'
    },
    {
        title: 'Invoice number',
        minWidth: 200,
        responsive: 0,
        field: 'invoice_number',
        hozAlign: 'center'
    },
    {
        title: 'Customer',
        minWidth: 200,
        responsive: 0,
        field: 'customer',
        hozAlign: 'center'
    },
    {
        title: 'Payment date',
        minWidth: 200,
        responsive: 0,
        field: 'period_start',
        hozAlign: 'center'
    }
]