<template>
    <Layout title="Influencers">
        <template #body>
            <div class="col-span-12 flex flex-wrap sm:flex-nowrap items-center">
                <MainButton
                    title="Add influencer"
                    @click="openModal(null)"
                />
            </div>

            <tabulator
                :ajax-url="options.ajaxUrl"
                :ajax-params="options.ajaxParams"
                :columns="options.columns"
                :default-filter-field="options.defaultFilterField"
                :filtering-fields="options.filteringFields"
                :row-click-callback="rowClick"
                :ditailing-filter="false"
                :set-page="page"
                :is-refresh="isRefresh"
                title="Users"
            />
        </template>
    </Layout>

    <div
        id="modalUpdateOrCreateInfluencer"
        class="modal"
        style="z-index: 9998 !important;"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>
                    <form @submit.prevent="updateInfluencer">
                        <FormInput
                            type="text"
                            label="Email:"
                            v-model="validate.email.$model"
                            :errors="validate.email.$errors"
                        />

                        <div class="flex justify-center gap-4 mt-4">
                            <MainButton
                                v-if="selectedInfluencer"
                                title="Delete"
                                class-map="danger"
                                @click="deleteInfluencer"
                            />

                            <MainButton title="Save" type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div
        id="modalUpdateOrCreateInfluencerPromoCode"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>
                    <form @submit.prevent="generatePromoCode">
                        <FormInput
                            v-model="validatePromoCode.name.$model"
                            :errors="validatePromoCode.name.$errors"
                            type="text"
                            label="Name"
                            class="pb-4"
                        />
                        <div class="pb-4">
                            <div class="grid grid-cols-1 lg:grid-cols-3 gap-2">
                                <div class="col-span-2">
                                    <FormInput
                                        v-model="validatePromoCode.promo_code.$model"
                                        :errors="validatePromoCode.promo_code.$errors"
                                        type="text"
                                        label="Promo Code Name"
                                    />
                                </div>
                                <div class="pt-[1.8rem] text-right">
                                    <button
                                        class="btn border border-theme-32 p-2 text-theme-32"
                                        type="button"
                                        @click="generateCode"
                                    >
                                        Generate
                                    </button>
                                </div>
                            </div>
                        </div>
                        <TomSelect
                            v-model="validatePromoCode.discountType.$model"
                            :errors="validatePromoCode.discountType.$errors"
                            :options="{placeholder: 'Select Discount Type'}"
                            label="Discount Type"
                            class="w-full pb-4"
                        >
                            <option
                                v-for="(type, index) in [{name: 'Fixed', value: 'fixed'},{name: 'Percentage', value: 'percentage'}]"
                                v-text="type.name"
                                :key="index"
                                :value="type.value"
                            />
                        </TomSelect>
                        <TomSelect
                            v-model="validatePromoCode.duration.$model"
                            :errors="validatePromoCode.duration.$errors"
                            :options="{placeholder: 'Select Duration'}"
                            label="Duration"
                            class="w-full pb-4"
                        >
                            <option
                                v-for="(type, index) in [{name: 'Once', value: 'once'}, {name: 'Multiple months', value: 'repeating'}]"
                                v-text="type.name"
                                :key="index"
                                :value="type.value"
                            />
                        </TomSelect>

                        <template v-if="validatePromoCode.duration.$model === 'repeating'">
                            <FormInput
                                v-model="validatePromoCode.duration_in_months.$model"
                                :errors="validatePromoCode.duration_in_months.$errors"
                                type="number"
                                label="Duration In Months"
                                class="pb-4"
                            >
                                <template #tip>
                                    <DurationPromoCode />
                                </template>
                            </FormInput>
                        </template>

                        <FormInput
                            v-if="validatePromoCode.discountType.$model === 'percentage'"
                            v-model="validatePromoCode.percent_off.$model"
                            :errors="validatePromoCode.percent_off.$errors"
                            type="number"
                            label="Percent"
                            class="pb-4"
                        />

                        <FormInput
                            v-if="validatePromoCode.discountType.$model === 'fixed'"
                            v-model="validatePromoCode.amount_off.$model"
                            :errors="validatePromoCode.amount_off.$errors"
                            type="number"
                            label="Amount"
                            class="pb-4"
                        />

                        <div>
                            <label>Expired Date</label>
                            <div>
                                <input
                                    v-model="validatePromoCode.expiredDate.$model"
                                    :max="maxDate"
                                    type="datetime-local"
                                    class="form-control"
                                >
                            </div>
                            <template v-if="validatePromoCode.expiredDate.$errors.length > 0">
                                <div
                                    v-for="(error, index) in validatePromoCode.expiredDate.$errors"
                                    :key="index"
                                    class="text-theme-6 mt-2"
                                >
                                    {{ error?.$params?.message }}
                                </div>
                            </template>
                        </div>
                        <div class="flex justify-center gap-4 mt-4">
                            <MainButton
                                v-if="selectedInfluencer?.promo_code"
                                title="Delete"
                                class-map="danger"
                                @click="deletePromoCode"
                            />

                            <MainButton :title="`${selectedInfluencer?.promo_code ? 'Update' : 'Save'}`" type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div
        id="modalConfirmationPromoCode"
        class="modal"
        style="z-index: 9998 !important;"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal" />
                    <div class="text-center text-2xl text-black font-medium">
                        {{ promoCodeActionType === 'delete'
                        ? 'Are you sure? Referral code will be deleted and deactivated'
                        : promoCodeActionType === 'update'
                            ? 'If important parameters (duration, discount %, etc) are changed, the old code will be deactivated and a new code will be generated.'
                            : ''
                        }}
                    </div>

                    <div class="flex justify-center gap-4 mt-4">
                        <MainButton
                            title="Yes"
                            @click="confirmationPromoCode(true)"
                        />

                        <MainButton
                            title="No"
                            class-map="danger"
                            @click="confirmationPromoCode(false)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, getCurrentInstance, inject, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { email, helpers, maxLength, maxValue, minValue, numeric, required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { copy, generateRandomString } from "@scripts/plugins/functions";
import cash from "cash-dom/dist/cash";
import axios from "axios";
import Layout from "@layouts/Layout.vue";
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import Tabulator from '@components/Tabulator/TabulatorInfluencer.vue';
import DurationPromoCode from "@components/Tooltips/DurationPromoCode.vue";
import moment from "moment";


const copyCode = (promoCode) => {
    copy(promoCode);

    toast({
        type: 'success',
        text: 'Promo Code successful copied'
    });
}

const options = {
    ajaxUrl: '/api/super-admin/influencers',
    columns:
        [
            {
                title: 'Email',
                minWidth: 200,
                responsive: 0,
                field: 'email',
                hozAlign: 'center',
                titleFormatter: function (cell, formatterParams, onRendered) {
                    return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
                }
            },
            {
                title: 'Promo Code',
                minWidth: 200,
                responsive: 0,
                field: 'promo_code',

                formatter: function (cell, formatterParams, onRendered) {
                    const promoCodeValue = cell.getValue();

                    if (!promoCodeValue) {
                        return `<div style='text-align:center;'>None</div>`;
                    }

                    let container = document.createElement("div");
                    container.className = "flex justify-center";

                    let wrapper = document.createElement("div");
                    wrapper.className = "flex flex-col gap-3";

                    let div1 = document.createElement("div");
                    div1.style.cursor = 'copy';
                    div1.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg> ${promoCodeValue.promoCode}`;
                    div1.addEventListener('click', function () {
                        copyCode(promoCodeValue.promoCode);
                    });

                    wrapper.appendChild(div1);
                    container.appendChild(wrapper);

                    return container;
                },
                titleFormatter: function (cell, formatterParams, onRendered) {
                    return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
                }
            },
            {
                title: 'Expired Date Promo Code',
                minWidth: 200,
                responsive: 0,
                field: 'promo_code',
                hozAlign: 'center',
                formatter: function (cell, formatterParams, onRendered) {
                    const promoCodeValue = cell.getValue();

                    if (!promoCodeValue || !promoCodeValue.expiredDate) {
                        return 'None';
                    }

                    const d = new Date(promoCodeValue.expiredDate);

                    return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
                },
                titleFormatter: function (cell, formatterParams, onRendered) {
                    return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
                }
            },
            {
                title: "Actions",
                minWidth: 200,
                headerSort: false,
                formatter: function (cell, formatterParams, onRendered) {
                    const influencer = cell.getData();
                    let container = document.createElement("div");
                    container.className = "flex justify-center";

                    let buttonsWrapper = document.createElement("div");
                    buttonsWrapper.className = "flex flex-col gap-3";

                    let editButton = document.createElement("button");
                    editButton.textContent = "Edit";
                    editButton.className = "button small";
                    editButton.onclick = function () {
                        openModal(influencer);
                    };

                    let promoButton = document.createElement("button");
                    promoButton.textContent = influencer.promo_code ? "✎ Promo Code" : "+ Promo Code";
                    promoButton.className = "button small";
                    promoButton.onclick = function () {
                        openModalUpdateOrCreatePromoCode(influencer);
                    };

                    buttonsWrapper.appendChild(editButton);
                    buttonsWrapper.appendChild(promoButton);
                    container.appendChild(buttonsWrapper);

                    return container;
                },
                titleFormatter: function (cell, formatterParams, onRendered) {
                    return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
                }
            },
        ],
    filteringFields: [],
    defaultFilterField: 'created_at'
}
const router = useRouter();
const route = useRoute();
const toast = inject('toast');
const influencers = ref([]);
const page = ref(1);
const selectedInfluencer = ref(null);
const promoCodeActionType = ref(null);
const isRefresh = ref(false);
const requestData = reactive({
    email: '',
});
const rules = {
    email: { required, email, maxLength: maxLength(40) },
}
const validate = useVuelidate(rules, requestData);
const formPromoCode = reactive({
    name: '',
    promo_code: '',
    amount_off: 0,
    percent_off: 0,
    expiredDate: '',
    duration: 'once',
    duration_in_months: 0,
    discountType: 'percentage',
    influencerId: null,
});
const rulesPromoCode = reactive({
    name: { required, maxLength: maxLength(40) },
    duration: { required },
    discountType: { required },
    duration_in_months: { minValue: minValue(1), numeric },
    percent_off: { numeric, minValue: minValue(1) },
    amount_off: { numeric, minValue: minValue(1) },
    promo_code: { maxLength: maxLength(8) },
    expiredDate: { maxLength: maxLength(8) },
});
const validatePromoCode = useVuelidate(rulesPromoCode, formPromoCode);
const { proxy } = getCurrentInstance();
const maxPromoCodeFixedAmount = ref(100);
const maxDate = computed(() => {
    const today = new Date();
    const maxYear = today.getFullYear() + 2;
    const maxDate = new Date(maxYear, 11, 31, 23, 59);
    return maxDate.toISOString().substring(0, 16);
});
const futureDate = helpers.withParams({ type: 'futureDate', message: 'The date cannot be in the past' }, value => {
    if (!value) {return true;}
    return moment(value).isAfter(moment());
});
const withinNextTwoYears = helpers.withParams(
    { type: 'withinNextTwoYears', message: 'The date must not exceed the current year by more than two years' },
    value => {
        if (!value) {return true;}
        const inputDate = moment(value);
        const twoYearsLater = moment().add(2, 'years');
        return inputDate.isSameOrBefore(twoYearsLater, 'year');
    }
);


const setRules = () => {
    if (formPromoCode.duration === 'repeating') {
        rulesPromoCode.duration_in_months = { minValue: minValue(1), numeric };
    } else {
        rulesPromoCode.duration_in_months = {};
    }

    if (formPromoCode.discountType === 'fixed') {
        rulesPromoCode.amount_off = {
            minValue: minValue(1),
            maxValue: maxValue(maxPromoCodeFixedAmount.value),
            numeric
        };
        rulesPromoCode.percent_off = {};
    } else {
        rulesPromoCode.amount_off = {};
        rulesPromoCode.percent_off = { minValue: minValue(1), maxValue: maxValue(100), numeric };
    }

    if (formPromoCode.expiredDate) {
        rulesPromoCode.expiredDate = { required, futureDate, withinNextTwoYears };
    } else {
        rulesPromoCode.expiredDate = {}
    }
}

const updateIsRefresh = () => {
    isRefresh.value = true;

    setTimeout(() => {
        isRefresh.value = false;
    }, 200);
}


const getData = async () => {
    const price = await axios.get('/api/super-admin/get-max-price');

    maxPromoCodeFixedAmount.value = price.data;
}

const openModal = (influencer) => {
    requestData.email = '';
    validate.value.$reset();

    if (influencer) {
        requestData.email = influencer.email;
        requestData.id = influencer.id;
    }

    selectedInfluencer.value = influencer;

    cash('#modalUpdateOrCreateInfluencer').modal('show');
}


const openModalUpdateOrCreatePromoCode = (influencer) => {
    validatePromoCode.value.$reset();

    if (influencer) {
        requestData.email = influencer.email;
        requestData.id = influencer.id;
    }

    selectedInfluencer.value = influencer;

    if (influencer.promo_code) {
        formPromoCode.name = influencer.promo_code['couponName'];
        formPromoCode.promo_code = influencer.promo_code['promoCode'];
        formPromoCode.duration = influencer.promo_code['couponDuration'];
        formPromoCode.discountType = influencer.promo_code['discountType'];
        formPromoCode.duration_in_months = influencer.promo_code['couponDurationInMonths'];
        formPromoCode.percent_off = influencer.promo_code['couponPercent'];
        formPromoCode.amount_off = influencer.promo_code['couponAmount'];
        formPromoCode.expiredDate = influencer.promo_code['expiredDate'];
        formPromoCode.influencerId = influencer.id;
    } else {
        formPromoCode.name = '';
        formPromoCode.promo_code = '';
        formPromoCode.duration = 'once';
        formPromoCode.discountType = 'percentage';
        formPromoCode.duration_in_months = 0;
        formPromoCode.percent_off = 0;
        formPromoCode.amount_off = 0;
        formPromoCode.expiredDate = '';
        formPromoCode.influencerId = influencer.id;
    }

    setRules();
    cash('#modalUpdateOrCreateInfluencerPromoCode').modal('show');
}

const updateInfluencer = () => {
    validate.value.$touch();

    if (!validate.value.$invalid) {
        const influencerId = selectedInfluencer.value?.id;
        Object.assign(requestData, { _method: influencerId ? 'put' : 'post' });

        const url = influencerId ? `/api/super-admin/influencers/${influencerId}` : '/api/super-admin/influencers';

        axios.post(url, requestData).then(() => {
            toast({
                type: 'success',
                text: influencerId ? 'Influencer successful updated' : 'Influencer successful created'
            });

            cash('#modalUpdateOrCreateInfluencer').modal('hide');

            selectedInfluencer.value = null;

            updateIsRefresh();
        }).catch((e) => {
            toast({
                type: 'danger',
                text: e.response.data.errors.email[0]
            });

            validate.value.$reset();
        });
    }
}

const deleteInfluencer = () => {
    axios.post(`/api/super-admin/influencers/${selectedInfluencer.value.id}`, {
        _method: 'delete'
    }).then(() => {
        selectedInfluencer.value = null;

        cash('#modalUpdateOrCreateInfluencer').modal('hide');

        updateIsRefresh();
    });
}

const generatePromoCode = () => {
    if (selectedInfluencer.value.promo_code) {
        cash('#modalConfirmationPromoCode').modal('show');
        promoCodeActionType.value = 'update';
    } else {
        validatePromoCode.value.$touch();

        if (!validatePromoCode.value.$invalid) {
            axios
                .post(`/api/super-admin/influencers/${selectedInfluencer.value.id}/generate-promo-code`, formPromoCode)
                .then(() => {
                    toast({
                        type: 'success',
                        text: 'Promo Code successful generated'
                    });

                    cash('#modalUpdateOrCreateInfluencerPromoCode').modal('hide');
                    selectedInfluencer.value = null;
                    updateIsRefresh();
                })
                .catch((e) => {
                    proxy.$errorHandler(e);
                    validatePromoCode.value.$reset();
                });
        }
    }
}

const deletePromoCode = () => {
    cash('#modalConfirmationPromoCode').modal('show');
    promoCodeActionType.value = 'delete';
}

const confirmationPromoCode = (state) => {
    if (state) {
        if (promoCodeActionType.value === 'update') {
            validatePromoCode.value.$touch();

            if (!validatePromoCode.value.$invalid) {
                axios
                    .put(`/api/super-admin/influencers/${selectedInfluencer.value.id}/update-promo-code`, formPromoCode)
                    .then(() => {
                        toast({
                            type: 'success',
                            text: 'Promo Code successful generated'
                        });

                        cash('#modalUpdateOrCreateInfluencerPromoCode').modal('hide');
                        selectedInfluencer.value = null;
                        updateIsRefresh();
                    })
                    .catch((e) => {
                        proxy.$errorHandler(e);
                        validatePromoCode.value.$reset();
                    });
            }
        }
        if (promoCodeActionType.value === 'delete') {
            axios
                .delete(`/api/super-admin/influencers/${selectedInfluencer.value.id}/delete-promo-code`)
                .then(() => {
                    toast({
                        type: 'success',
                        text: 'Promo Code successful deleted'
                    });

                    cash('#modalUpdateOrCreateInfluencerPromoCode').modal('hide');
                    selectedInfluencer.value = null;
                    updateIsRefresh();
                })
                .catch((e) => {
                    proxy.$errorHandler(e);
                    cash('#modalUpdateOrCreateInfluencerPromoCode').modal('hide');
                    validatePromoCode.value.$reset();
                });
        }

        cash('#modalConfirmationPromoCode').modal('hide');
    } else {
        promoCodeActionType.value = null;
        cash('#modalUpdateOrCreateInfluencerPromoCode').modal('hide');
        cash('#modalConfirmationPromoCode').modal('hide');
    }
}
const rowClick = (ev, row) => {
    // openModal(row._row.data);
}

const generateCode = () => {
    formPromoCode.promo_code = generateRandomString(7, false);
}


watch(
    () => formPromoCode,
    (form) => {
        setRules();
    }, { deep: true }
);

watch(
    route,
    (route) => {
        page.value = route.params.page ? Number(route.params.page) : 1;
    },
    { immediate: true, deep: true }
);

onMounted(() => {
    getData();
});
</script>

<style>
.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
}

#modalUpdateOrCreateInfluencerPromoCode {
    z-index: 9998 !important;
}
</style>