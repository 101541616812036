export default {
    website: state => state.website,
    websiteSettings: state => state.websiteSettings,
    getEmailSettings: state => state.emailSettings,
    guideStep: state => state.website.current_step,

    websiteId: state => state.website.id,
    statuses: state => state.statuses,

    privacyPolicy: state => state.pages.privacyPolicy,
    privacyPolicyTitle: state => state.pages.privacyPolicy.title,
    termsOfUse: state => state.pages.termsOfUse,

    mainPage: state => state.pages.mainPage,
    mainPageContent: state => state.pages.mainPage.content,

    domain: state => state.website.code,
    status: state => state.website.status,
    title: state => state.website.company_name,
    logo: state => state.websiteSettings.logo,

    footer: state => state.websiteSettings.footer,

    emailSettings: state => state.websiteSettings.emailSettings,
    fromName: state => state.websiteSettings.emailSettings.name,
    mailHeading: state => state.websiteSettings.emailSettings.heading,

    mainPageAboutUs: state => state.websiteSettings.about_us,
    mainPageContactUs: state => state.websiteSettings.contact_us,

    onlyQuote: state => state.websiteSettings.onlyQuote ? state.websiteSettings.onlyQuote : false,

    metrics: state => state.website.metrics,
    currency: state => state.website.currency,
    metricValue: state => (item) => {
        if (item.attribute_metric) {
            return item.attribute_metric === 1 ? 'SQFT' : 'LF';
        }

        return '';
    },
    metricValueFullText: state => (item) => {
        if (item.attribute_metric) {
            return item.attribute_metric === 1 ? 'Square Feet' : 'Linear Feet';
        }

        return '';
    }
}