<template>
    <div class="switch" @click="switchMode">
        <input type="checkbox" :checked="checked_" :data-type="side">
        <span class="slider" :data-type="side" />
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';


const props = defineProps({
    type: {
        type: String,
        required: false,
        default: 'text',
    },
    checked: {
        type: Boolean,
        default: false,
    },
    classMap: {
        type: String,
        required: false,
        default: '',
    },
    side: {
        type: String,
        default: 'default'
    }
});
const emit = defineEmits(['switchAction']);
const checked_ = ref(props.checked);


watch(
    () => props.checked,
    (checked) => {
        checked_.value = checked;
    }
)

const switchMode = () => {
    checked_.value = !checked_.value;

    emit('switchAction', checked_.value);
}
</script>

<style lang="scss" scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 68px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    border: 1px solid #B7B9BF;

    &[data-type="left"] {
        border-color: #3EAF69 !important;

        &:before {
            background-color: #3EAF69 !important;
        }
    }

    &[data-type="right"] {
        border-color: #3EAF69 !important;

        &:before {
            background-color: #3EAF69 !important;
        }
    }
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 3px;
    bottom: 3px;
    background-color: #B7B9BF;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input {
    &:checked + .slider {
        border-color: #3EAF69;

        &:before {
            background-color: #3EAF69;
            left: 11px;
        }

        &[data-type="left"] {
            &:before {
                background-color: #3EAF69;
            }
        }
    }
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
</style>