<template>
  <div class="dark:text-white text-theme-32 text-center text-2xl mt-10">What Services do you offer?</div>
  <div class="title text-center text-xl my-2">
    *Don't see something? You can add more & customize later
  </div>

  <div class="services-wrapper">
    <div class="service-item-wrapper" v-for="service in services" :key="'service-' + service.id" >
      <div class="service-item p-8 rounded-lg" :class="{ 'selected': service.isSelected }" @click="service.isSelected = !service.isSelected">
        <img :src="service.image" :alt="service.title" class="mx-auto mb-2 h-16 w-16">
        <p class="service-title text-center font-bold">{{ service.title }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineExpose, inject, onMounted, ref } from 'vue'
import axios from 'axios'
const toast = inject('toast');

const services = ref([])

async function saveServices() {
  const ids = []

  for (const service of services.value) {
    if (service.isSelected) {
      ids.push(service.id)
    }
  }

  try {
    await axios.post('api/main-services', {
      service_ids: ids
    })

    toast({
      text: 'Your seleted services are set succesfully!',
      type: 'success'
    })
  } catch (e) {
    proxy.$errorHandler(e)
    toast({
      text: 'Something went wrong!',
      type: 'danger'
    })
  }
}
defineExpose({
  saveServices
});

onMounted(async () => {
  const { data } = await axios.get('api/main-services')

  services.value = [ ...data.map((el) => { return { ...el, isSelected: false } }) ]
})
</script>

<style scoped lang="scss">
.services-wrapper {
  padding: 0 20px;
  box-sizing: border-box;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    max-width: 900px;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 640px;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;

    .service-item-wrapper {
      justify-self: center;
    }
  }

  .service-item {
    height: 180px;
    width: 100%;
    max-width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }

    .service-title {
      margin-top: 14px;
      font-size: 20px;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #579e3d;
    }

    &.selected {
      border: 2px solid #579e3d;
    }
  }
}
</style>
