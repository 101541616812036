export const ERROR_MESSAGE_TYPE = 1
export const SUCCESS_MESSAGE_TYPE = 2

const state = () => ({ messages: [] })

const getters = {
    errorMessages: (state) => state.messages.filter((message) => message.type === ERROR_MESSAGE_TYPE),
    successMessages: (state) => state.messages.filter((message) => message.type === SUCCESS_MESSAGE_TYPE)
}

/*
 * Export const actions = {
 *
 * }
 */

const mutations = {
    pushErrorMessage(state, message) {
        state.messages = [
...state.messages, {
message,
type: ERROR_MESSAGE_TYPE
}
]
    },

    pushSuccessMessage(state, message) {
        state.messages = [
...state.messages, {
message,
type: SUCCESS_MESSAGE_TYPE
}
]
    },

    clearMessages(state) {
        state.messages = []
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
