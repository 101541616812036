<template>
    <Layout>
        <template #body>
            <div class="wrap-container">
                <div class="container">
                    <div style="display: flex; align-items: flex-start; justify-content: center">
                        <div>
                            <div class="flex flex-col justify-center">
                                <div class="label__ mb-5 text_desc">{{ settings?.websiteSettings?.about_us?.title ?? '' }}</div>
                                <div class="text mb-5 text_desc" v-html="nl2br(settings?.websiteSettings?.about_us?.description ?? '')" />
                                <div class="flex justify-center mb-10 img_iframe_block">
                                    <img
                                        v-if="settings?.websiteSettings?.about_us?.media_type && settings?.websiteSettings?.about_us?.media_type === 'image' && settings?.websiteSettings?.about_us?.image"
                                        :src="settings?.websiteSettings?.about_us?.image"
                                        width="550"
                                        height="550"
                                        alt=""
                                    >
                                    <iframe
                                        v-else-if="settings?.websiteSettings?.about_us?.media_type && settings?.websiteSettings?.about_us?.media_type === 'video'"
                                        :src="settings?.websiteSettings?.about_us?.video"
                                        width="560"
                                        height="315"
                                        frameborder="0"
                                        allowfullscreen
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex w-100 justify-center">
                        <a href="/quote" class="mb-[3rem]">
                            <MainButton title="Generate Quote" />
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </Layout>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from "vuex";
import Layout from "../Layouts/Layout.vue";
import MainButton from "@components/Button/MainButton.vue";


const store = useStore();
const settings = computed(() => store.getters['quote/getSettings']);
const nl2br = (str) => {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
}
</script>

<style lang="scss" scoped>
.label__ {
    font-size: 30px;
    line-height: 24px;
    text-align: center;
}

.text {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.wrap-container {
    min-height: 100%;
    padding-bottom: 150px;
}

.text_desc {
    @media screen and (max-width: 550px) {
        max-width: 80%;
        display: block;
        margin-right: auto;
        margin-left: auto;
    }


    @media screen and (max-width: 450px) {
        max-width: 70%;
    }

    @media screen and (max-width: 370px) {
        max-width: 55%;
    }

    @media screen and (max-width: 320px) {
        max-width: 40%;
    }
}



.img_iframe_block {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: 550px) {
        width: 85% !important;
    }

    @media screen and (max-width: 450px) {
        width: 70% !important;
    }

    @media screen and (max-width: 370px) {
        width: 50% !important;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @media screen and (max-width: 320px) {
        max-width: 35%;
    }
}
</style>
