import axios from "axios";

export default function subscribed({ next, store }) {
    if (store.getters['auth/loggedIn'] && store.getters['auth/hasRole'] && store.getters['auth/isAdmin']) {
        return next();
    }

    if (store.getters['auth/loggedIn']) {
        if (store.getters['auth/hasRole'] && (store.getters['auth/isSales'] || store.getters['auth/isManager']) && !store.getters['auth/ownerSubscribed'] && document.location.pathname !== '/login') {
            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            axios.post('/api/v1/logout', { headers: { 'X-CSRF-TOKEN': token } }).then(r => {
                store.commit('auth/setUser', {});
            });
        }

        if (store.getters['auth/hasRole'] && store.getters['auth/isOwner'] && !store.getters['auth/subscribed'] && document.location.pathname !== '/add-plan') {
            return document.location.replace('/add-plan');
        }

        if (document.location.pathname === '/add-plan' && (store.getters['auth/isSales'] || store.getters['auth/isManager']) && store.getters['auth/ownerSubscribed']) {
            return next({
                name: 'dashboard'
            });
        }
    }
}
