const initialState = () => ({
    websites: [],
    website: {
        id: '',
        code: '',
        company_name: '',
        user_id: '',
        status: null,
        current_step: 0,
    },
    pages: {
        privacyPolicy: {
            content: '',
            title: '',
            seo_description: ''
        },
        termsOfUse: {
            content: '',
            title: '',
            seo_description: ''
        },
        mainPage: {
            content: '',
            title: '',
            seo_description: ''
        }
    },
    websiteSettings: {
        footer: {
            facebook: '',
            twitter: '',
            youtube: '',
            instagram: '',
            contact_phone: '',
            email: '',
            address: ''
        },
        about_us: {
            image: '',
            title: '',
            description: ''
        },
        contact_us: {
            email: ''
        },
        onlyQuote: false,
        logo: null
    },
    emailSettings: {
        mailInfo: {
            text: '',
            title: '',
            sender: {
                name: '',
                email: '',
                phone: '',
                photo: '',
            }
        },
        companyInfo: {
            name: '',
            email: '',
            phone: '',
        },
    },
    statuses: [{
            text: 'Active',
            value: 1
        },
        {
            text: 'Inactive',
            value: 0
        }
    ]
})

export default {
    resetWebsiteData(state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
    setWebsite(state, data) { state.website = typeof data === 'object' && Object.keys(data).length > 0 ? data : state.website },
    updateWebsite(state, data) { Object.assign(state.website, data); },
    setEmailSettings(state, data) { state.emailSettings = data },
    setWebsiteSetting(state, data) { state.website.settings = data },
    setWebsiteSettings(state, data) { state.websiteSettings = data },

    setMainPage(state, data) { state.pages.mainPage = data },
    setMainPageContent(state, data) { state.pages.mainPage.content = data },

    setDomain(state, data) {
        state.website.code = data
    },

    setStatus(state, data) { state.website.status = data },
    setTitle(state, data) { state.website.company_name = data },
    setWebsiteFooter(state, data) { state.websiteSettings.footer = data },

    setLogo(state, data) { state.websiteSettings.logo = data },

    setGuideStep(state, data) { state.website.current_step = data },

    setFromName(state, data) { state.websiteSettings.emailSettings.name = data },
    setMailHeading(state, data) { state.websiteSettings.emailSettings.heading = data },

    setMainPageAboutUs(state, data) { state.websiteSettings.about_us = data },
    setMainPageContactUs(state, data) { state.websiteSettings.contact_us = data },

    setOnlyQuote(state, data) { state.websiteSettings.onlyQuote = data }
}