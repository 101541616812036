const state = () => ({
    menu: [
        {
            icon: 'HomeIcon',
            image: 'dashboard',
            pageName: 'dashboard',
            title: 'Dashboard',
            roles: [ 'owner', 'office_manager', 'sales_team' ],
        },
        {
            icon: 'ShoppingBagIcon',
            image: 'products',
            pageName: 'products',
            title: 'Products',
            roles: [ 'owner', 'office_manager', 'sales_team' ]
        },
        {
            icon: 'LayoutIcon',
            image: 'website',
            pageName: 'website',
            title: 'Website',
            roles: [ 'owner' ]
        },
        {
            icon: 'UserIcon',
            image: 'website-managers',
            pageName: 'managers-management',
            title: 'Website managers',
            roles: [ 'owner' ],
            unwantedSubType: [ 'subTypeMeasureBasic', 'subTypeMeasurePro' ],
        },
        {
            icon: 'UsersIcon',
            image: 'customers',
            pageName: 'customers',
            title: 'Customers',
            roles: [ 'owner', 'office_manager', 'sales_team' ],
            unwantedSubType: [ 'subTypeMeasureBasic', 'subTypeMeasurePro' ],
        },
        {
            icon: 'DollarSignIcon',
            image: 'pricing',
            title: 'Pricing',
            roles: [ 'owner', 'office_manager' ],
            unwantedSubType: [ 'subTypeMeasureBasic', 'subTypeMeasurePro' ],
            subMenu: [ {
                icon: 'TagIcon',
                image: 'rules',
                pageName: 'SideMenu-pr-rules',
                roles: [ 'owner', 'office_manager' ],
                title: 'Pricing Rules'
            },
                {
                    icon: 'ListIcon',
                    image: 'catalog',
                    pageName: 'SideMenu-pr-catalog',
                    roles: [ 'owner', 'office_manager' ],
                    title: 'Price Catalog'
                }
            ]
        },
        {
            icon: 'MailIcon',
            image: 'email-settings',
            pageName: 'EmailSettings',
            title: 'Email Settings',
            roles: [ 'owner', 'office_manager', 'sales_team' ],
            unwantedSubType: [ 'subTypeMeasureBasic', 'subTypeMeasurePro' ],
        },
        {
            icon: 'UsersIcon',
            image: 'users-websites',
            pageName: 'users-management',
            title: 'Users',
            roles: [ 'admin' ]
        },
        {
            icon: 'UsersIcon',
            image: 'influencers',
            pageName: 'influencers-management',
            title: 'Influencers',
            roles: [ 'admin' ]
        },
        {
            icon: 'LayoutIcon',
            image: 'website',
            pageName: 'websites-management',
            title: 'Websites',
            roles: [ 'admin' ]
        },
        {
            icon: 'KeyIcon',
            image: 'stripe-account',
            pageName: 'subscribe-management',
            link: 'https://dashboard.stripe.com/login',
            title: 'Stripe Account',
            roles: [ 'admin' ],
        },
        {
            icon: 'ListIcon',
            image: 'website-settings',
            pageName: 'app-settings',
            title: 'Application settings',
            roles: [ 'admin' ]
        },
        {
            icon: 'ListIcon',
            image: 'subscription-plans',
            pageName: 'subscription-plans',
            title: 'Subscription plans',
            roles: [ 'admin' ]
        },
    ]
})


const getters = { menu: (state) => state.menu }
const actions = {}
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
