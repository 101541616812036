import axios from "axios";

export default {
    async actionGetMetrics (context, payload) {
        const metrics = await axios.get('/api/metrics')
        this.commit('general/setMetrics', metrics.data)
    },

    async actionGetCommonTypes (context) {
        const common_types = await axios.get('/api/common-types')
        this.commit('general/setAttributeCommonTypes', common_types.data)
    }
}
