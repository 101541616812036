<template>
    <Layout title="Super Admin Manager Details">
        <template #body>
            <ButtonBack title="Users list" :to="{ name: 'users-management', page: page }" />
            <div>
                <div class="grid grid-cols-12 gap-6">
                    <div class="col-span-6 wrap-form-group-admin">
                        <div class="form-group">
                            <FormInput
                                type="text"
                                label="First Name:"
                                v-model="validate.first_name.$model"
                                :errors="validate.first_name.$errors"
                            />
                        </div>
                        <div class="form-group">
                            <FormInput
                                type="text"
                                label="Last Name:"
                                v-model="validate.last_name.$model"
                                :errors="validate.last_name.$errors"
                            />
                        </div>
                        <div class="form-group">
                            <FormInput
                                type="email"
                                label="Email:"
                                v-model="validate.email.$model"
                                :errors="validate.email.$errors"
                            />
                        </div>
                        <div class="form-group">
                            <InputPhone
                                v-model="validate.contact_number.$model"
                                :default-val="validate.contact_number.$model"
                                :errors="validate.contact_number.$errors"
                                label="Phone:"
                            />
                        </div>

                        <div v-if="userId > 0" class="form-group">
                            <label class="mr-3">Roles:</label>
                            <TomSelect
                                v-model="validate.userRolesList.$model"
                                :options="{placeholder: 'Select roles'}"
                                class="w-full md:w-80"
                                :remove-button="false"
                            >
                                <option
                                    v-for="(role, index) in roles"
                                    :key="index"
                                    :value="role.id"
                                    v-text="role.name"
                                />
                            </TomSelect>
                            <template v-if="validate.userRolesList.$error">
                                <div
                                    v-for="(err, index) in validate.userRolesList.$errors"
                                    :key="index"
                                    class="text-theme-6 mt-2"
                                >
                                    {{ err.$message }}
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="col-span-6">
                        <div v-if="owner" class="col-span-6 xxl:col-span-6">
                            <h3 class="text-lg mb-4">
                                Owner site
                            </h3>

                            <div class="flex items-center mb-3">
                                <label class="mr-3">Name:</label>
                                <div
                                    class="alert alert-warning show cursor-pointer py-1"
                                    @click="router.push({ name: 'user-details', params: { id: owner.id } })"
                                    role="alert"
                                >
                                    {{ owner.full_name }}
                                </div>
                            </div>
                            <div class="flex">
                                <label class="mr-3">Email:</label>
                                <span>{{ owner.email }}</span>
                            </div>
                            <div class="flex">
                                <label class="mr-3">Contact number:</label>
                                <span>{{ owner.contact_number }}</span>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-6">
                            <div
                                v-if="ownerWebsites.length"
                                class="col-span-6"
                            >
                                <h3 class="text-lg mb-1">
                                    Website
                                </h3>
                                <ul>
                                    <li
                                        v-for="(website, wIndex) in ownerWebsites"
                                        :key="wIndex"
                                        class="alert-warning show mb-2 p-2 cursor-pointer rounded inline-block ml-2"
                                        @click="router.push({ name: 'website-details', params: { id: website.id } })"
                                    >
                                        {{ website.code }}
                                    </li>
                                </ul>
                            </div>
                            <div
                                v-if="ownerManagers.length"
                                class="row col-span-6"
                            >
                                <h3 class="text-lg mb-1">
                                    Owner managers
                                </h3>
                                <ul>
                                    <li
                                        v-for="(manager, mIndex) in ownerManagers"
                                        :key="mIndex"
                                        class="alert alert-warning show cursor-pointer py-2 rounded inline-block mb-2"
                                        @click="router.push({ name: 'user-details', params: { id: manager.id } })"
                                    >
                                        <span>{{ manager.full_name }}</span> - <span>{{ manager.email }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex my-4 justify-between">
                    <div class="gap-2">
                        <MainButton
                            :type-button="processing ? 'disabled' : ''"
                            title="Update"
                            @click="updateUser"
                        />
                        <MainButton
                            class-map="ml-2 px-3"
                            title="Login like this user"
                            @click="loginLikeUser(userId)"
                        />
                    </div>
                    <a
                        type="button"
                        class="button danger"
                        data-toggle="modal"
                        data-target="#delete-modal"
                    >
                        Delete
                    </a>
                </div>

                <DeleteModal id="delete-modal" @delete="deleteManager" />

            </div>
        </template>
    </Layout>
</template>

<script setup>
import { inject, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useVuelidate } from "@vuelidate/core";
import { email, maxLength, minLength, required } from "@vuelidate/validators";
import axios from 'axios';
import cash from 'cash-dom/dist/cash';
import InputPhone from "@components/UI/InputPhone.vue";
import ButtonBack from "@components/Button/ButtonBack.vue";
import Layout from "@layouts/Layout.vue";
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import DeleteModal from "@components/Modal/DeleteModal.vue";


const router = useRouter();
const route = useRoute();
const roles = ref([]);
const toast = inject('toast');
const store = useStore();
const processing = ref(false);
const owner = ref({});
const ownerWebsites = ref([]);
const ownerManagers = ref([]);
// eslint-disable-next-line no-underscore-dangle
let userId = 0;
const page = ref(1);
const userData = ref({
    last_name: '',
    first_name: '',
    email: '',
    contact_number: '',
    userRolesList: ''
});
const rules = {
    last_name: { required, maxLength: maxLength(40) },
    first_name: { required, maxLength: maxLength(40) },
    email: { required, email },
    contact_number: { required },
    userRolesList: { required, minLength: minLength(1) },
}
const validate = useVuelidate(rules, userData);


const getData = () => {
    axios.get(`/api/super-admin/users/${ userId }`).then((response) => {
        const { data } = response;
        userData.value = data.user ?? {};
        owner.value = data.user.owner ?? null;
        ownerWebsites.value = data.user.websites ?? [];
        ownerManagers.value = data.user.managers ?? [];
        roles.value = data.roles;
        userData.value.userRolesList = data.user?.roles.length ? data.user.roles.map(item => item.id) : [];
    }).catch((e) => {
        toast({
            type: 'danger',
            text: e.response.data
        });
        router.push({ name: 'users-management' });
    })

}

const updateUser = () => {
    validate.value.$touch();

    if (!validate.value.$invalid) {
        processing.value = true;
        Object.assign(userData.value, { _method: 'put' }, { userRoles: userData.value.userRolesList })

        axios.post(`/api/super-admin/users/${ userId }`, userData.value).then(() => {
            toast({
                type: 'success',
                text: 'User data Updated'
            });
            router.push({ name: 'users-management' });
            processing.value = false;
        }).catch((e) => {
            processing.value = false;
        });
    }
}

const loginLikeUser = (id) => {
    store.dispatch('main/setImpersonated', true);
    document.location.replace(`/login-like-user/${ id }`);
}

const deleteManager = () => {
    axios.post(`/api/super-admin/users/${ userId }`, {
        id: userId,
        _method: 'delete'
    }).then(() => {
        cash('#delete-modal').modal('hide');
        router.push({ name: 'users-management' });
    })
}


watch(
    route,
    (route) => {
        userId = route.params.id;
        page.value = route.params.page;
        if (userId) {
            getData();
        }
    },
    { immediate: true, deep: true }
);
</script>

<style lang="css" scoped>
.form-group {
    margin: .75rem 0;
}
</style>
