<template>
    <Layout title="User Guide">
        <template #body>
            <div :class="['gap-[3rem] grid grid-cols-1', unwantedSubTypes && store.getters['auth/isOwner'] ? 'md:grid-cols-2 xl:grid-cols-3' : 'md:grid-cols-2']">
                <MainBlock v-if="!store.getters['auth/isSales']">
                    <template #body>
                        <div class="py-10">
                            <router-link :to="{ name: 'products' }">
                                <img :src="imageAddNewProduct" alt="" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                                <div class="font-medium text-center text-base mt-3">
                                    Add New Products
                                </div>
                                <div class="text-gray-600 mt-2 w-3/4 text-center mx-auto">
                                    Go to product builder
                                </div>
                            </router-link>
                        </div>
                    </template>
                </MainBlock>
                <MainBlock v-if="!store.getters['auth/isSales'] && unwantedSubTypes" class-map="py-10">
                    <template #body>
                        <router-link :to="{ name: 'SideMenu-pr-catalog' }">
                            <img :src="imagePricingRules" alt="" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                            <div class="font-medium text-center text-base mt-3">
                                Check Your Pricing
                            </div>
                            <div class="text-gray-600 mt-2 w-3/4 text-center mx-auto">
                                Go to pricing table
                            </div>
                        </router-link>

                    </template>
                </MainBlock>
                <MainBlock v-if="store.getters['auth/isOwner']" class-map="py-10">
                    <template #body>
                        <router-link :to="{ name: 'website' }">
                            <img :src="imageWebsiteSettings" alt="" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                            <div class="font-medium text-center text-base mt-3">
                                Setup Website Settings
                            </div>
                            <div class="text-gray-600 mt-2 w-3/4 text-center mx-auto">
                                Go to website settings
                            </div>
                        </router-link>
                    </template>
                </MainBlock>

                <MainBlock v-if="store.getters['auth/isOwner'] && unwantedSubTypes" class-map="py-10">
                    <template #body>
                        <router-link :to="{ name: 'managers-management' }">
                            <img :src="imageManageTeam" alt="" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                            <div class="font-medium text-center text-base mt-3">
                                Manage Your Team
                            </div>
                            <div class="text-gray-600 mt-2 w-3/4 text-center mx-auto">
                                Go to managers page
                            </div>
                        </router-link>
                    </template>
                </MainBlock>
                <MainBlock v-if="unwantedSubTypes" class-map="py-10">
                    <template #body>
                        <router-link :to="{ name: 'customers' }">
                            <img :src="imageWebsiteCustomers" alt="" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                            <div class="font-medium text-center text-base mt-3">
                                Check Your Customers
                            </div>
                            <div class="text-gray-600 mt-2 w-3/4 text-center mx-auto">
                                Go to customers page
                            </div>
                        </router-link>
                    </template>
                </MainBlock>
                <MainBlock v-if="unwantedSubTypes" class-map="py-10">
                    <template #body>
                        <router-link :to="{ name: 'EmailSettings' }">
                            <img :src="imageManagersEmailSettings" alt="" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                            <div class="font-medium text-center text-base mt-3">
                                Adjust Email Settings
                            </div>
                            <div class="text-gray-600 mt-2 w-3/4 text-center mx-auto">
                                Go to email settings
                            </div>
                        </router-link>
                    </template>
                </MainBlock>
            </div>
        </template>
    </Layout>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import MainBlock from "@components/MainBlock.vue";
import imageAddNewProduct from '@images/svg/dashboard/add-product.svg';
import imageManagersEmailSettings from '@images/svg/dashboard/managers-email-settings.svg';
import imagePricingRules from '@images/svg/dashboard/pricing-rules.svg';
import imageWebsiteCustomers from '@images/svg/dashboard/website-customers.svg';
import imageWebsiteSettings from '@images/svg/dashboard/website-settings.svg';
import imageManageTeam from '@images/svg/dashboard/manage-team.svg';
import Layout from "@layouts/Layout.vue";


const store = useStore();
const unwantedSubTypes = computed(() => store.getters['auth/unwantedSubTypes']);
</script>
