<template>
    <div>
        <h2 class="text-lg font-medium mt-10">
            Super admin transaction
        </h2>
        <div class="p-5 mt-5">
            <TabulatorFilter
                :filtering-fields="filteringFields"
                @filter="filter"
            />
            <div class="overflow-x-auto scrollbar-hidden">
                <div
                    id="tabulator"
                    ref="tableRef"
                    class="mt-5 table-report table-report--tabulator"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';
import { filteringFields } from '@scripts/component/TransactionManagementFilteringFields';
import { transactionManagementColumns } from '@scripts/component/TransactionManagementColumns';
import Tabulator from 'tabulator-tables';
import TabulatorFilter from '@components/Tabulator/TabulatorFilter.vue';

const tabulator = ref();
const tableData = ref([]);
const tableRef = ref();
const store = useStore();

const filter = ({ field, type, value }) => {
    tabulator.value.setFilter(field, type, value)
}

const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: '/api/super-admin/admin-stripe/transactions',
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        ajaxFiltering: true,
        paginationSize: store.getters['main/transactionSize'] ?? 10,
        paginationSizeSelector: [ 10, 20, 30, 40 ],
        paginationInitialPage: store.getters['main/transactionPage'] ?? 1,
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: transactionManagementColumns,
        rowClick(ev, row) {
            window.open(row._row.data.url);
        },
        pageLoaded(pageNumber) {
            store.commit('main/setTransactionPage', pageNumber);
            store.commit('main/setTransactionSize', tabulator.value.getPageSize());
        }
    })
}


onMounted(() => {
    initTabulator();
});
</script>
