<template>
    <Layout :title="title">
        <template #body>
            <div class="p-5 mt-5">
                <div class="overflow-x-auto scrollbar-hidden">
                    <div
                        id="tabulator"
                        ref="tableRef"
                        class="mt-5 table-report table-report--tabulator"
                    />
                </div>
            </div>
        </template>
    </Layout>
</template>

<script setup>
import { computed, ref, reactive, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import router from '@router';
import feather from 'feather-icons';
import Tabulator from 'tabulator-tables';
import axios from "axios";
import Layout from "../../Layouts/Layout.vue";


const props = defineProps({
    title: {
        type: String,
        default: 'Default title'
    },
    preventSubmit: {
        type: Boolean,
        default: false
    },
    ajaxUrl: {
        type: String,
        required: true
    },
    columns: {
        type: Array,
        default: () => []
    },
    filteringFields: {
        type: Array,
        default: () => []
    }
});

const store = useStore();
const tableRef = ref();
const tabulator = ref();
const notificationsPage = computed(() => store.getters['main/notificationsPage']);
const currentWebsite = computed(() => store.getters['website/website']);
const notifications = computed({
    get() {
        return store.getters['website/getNotifications'];
    },
    set(data) {
        store.commit('website/setNotifications', data);
    }
});
const filter = reactive({
    field: 'name',
    type: 'like',
    value: ''
});


const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: props.ajaxUrl,
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        paginationInitialPage: notificationsPage.value ?? 1,
        pagination: 'remote',
        paginationSize: 10,
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: props.columns,
        pageLoaded(pageNumber) {
            store.commit('main/setNotificationsPage', pageNumber);
            store.commit('main/setNotificationsSize', tabulator.value.getPageSize());
        },
        rowClick(ev, row) {
            const data = row.getData();
            if (!data.is_seen) {
                axios.put(`/api/notification-mark-seen/${data.notification_id}`).then(({ data }) => {
                    notifications.value = data;
                });
            }

            if (data && data.link) {
                if (data?.morph_model?.customer_id) {
                    router.push({
                        name: 'customer-details',
                        params: { id: data.morph_model.customer_id }
                    });
                }
            }
        },
        renderComplete() {
            feather.replace({ 'stroke-width': 1.5 })
        },
    })
}

const reInitOnResizeWindow = () => {
    window.addEventListener('resize', () => {
        tabulator.value.redraw();
        feather.replace({ 'stroke-width': 1.5 });
    })
}

const onFilter = () => {
    tabulator.value.setFilter(filter.field, filter.type, filter.value);
}

const onResetFilter = () => {
    filter.field = 'name';
    filter.type = 'like';
    filter.value = '';
    onFilter();
}


watch(() => currentWebsite.value, () => {
    initTabulator();
});

watch(() => filter.field, (currentFilter) => {
    if (currentFilter.field !== 'created_at') {
        filter.value = '';
    }
});

onMounted(() => {
    initTabulator();
    reInitOnResizeWindow();
});
</script>
