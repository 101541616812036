<template>
    <StepLayout>
      <template #body>
            <div class="slide-form-group">
                <label class="block text-lg sm:my-4 my-2 text-center">
                    Please enter your contact information to start building a quote!
                </label>

                <div class="my-8 max-w-md mx-auto">
                    <div class="grid grid-cols-1 gap-6">
                        <label class="block">
                            <span class="text-gray-700">Full name</span>
                            <input
                                v-model.trim="validate.name.$model"
                                :class="{ 'validate-alert': validate.name.$error }"
                                :tabindex="tabIndex"
                                id="name-input"
                                type="text"
                                class="mt-1 block form-input w-full"
                                autofocus
                                placeholder="John Doe"
                            >
                            <template v-if="validate.name.$error">
                                <div
                                    v-for="(error, index) in validate.name.$errors"
                                    :key="index"
                                    class="text-theme-6 mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </label>

                        <label class="block">
                            <span class="text-gray-700">Address</span>
                            <input
                                id="address-input"
                                v-model.trim="validate.address.$model"
                                type="text"
                                class="mt-1 block form-input w-full"
                                :class="{ 'validate-alert': validate.address.$error }"
                                :tabindex="tabIndex"
                            >
                            <template v-if="validate.address.$error">
                                <div
                                    v-for="(error, index) in validate.address.$errors"
                                    :key="index"
                                    class="text-theme-6 mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </label>

                        <div class="block">
                            <InputPhone
                                v-model="validate.phone.$model"
                                :errors="validate.phone.$errors"
                                :class-map="['mt-1', 'w-full', 'form-input']"
                                label="Phone"
                                id="telephone"
                                placeholder=""
                            />
                        </div>

                        <label class="block">
                            <span class="text-gray-700">Email address</span>
                            <input
                                v-model.trim="validate.email.$model"
                                :class="['mt-1 block form-input w-full', { 'validate-alert': validate.email.$error }]"
                                :tabindex="tabIndex"
                                type="email"
                                placeholder="john@example.com"
                            >
                            <template v-if="validate.email.$error">
                                <div
                                    v-for="(error, index) in validate.email.$errors"
                                    :key="index"
                                    class="text-theme-6 mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </label>

                        <div class="flex justify-center contact-save-button">
                            <MainButton
                                :tabindex="tabIndex"
                                title="Start"
                                @click.prevent.stop="save"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </StepLayout>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, reactive, toRefs, computed, watch } from 'vue';
import { useVuelidate } from "@vuelidate/core";
import { CONTACT_STEP, OPTION_STEP } from '@scripts/store/quote/quoteConstants';
import { useStore } from "vuex";
import { email, helpers, minLength, required } from "@vuelidate/validators";
import { telInputValid } from "@mixins/composition/phoneMask";
import { Loader } from "@googlemaps/js-api-loader";
import InputPhone from "@components/UI/InputPhone.vue";
import StepLayout from "../Layouts/StepLayout.vue";
import MainButton from "../../Components/Button/MainButton.vue";


const props = defineProps({
    tabIndex: {
        type: Number
    },
    active: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['changeStepTransform']);
const store = useStore();
const places = ref(null);
const step = computed({
    get() {
        return store.getters['quote/step'];
    },
    set(value) {
        store.commit('quote/setStep', value);
    }
});
const mapLoader = computed(() => store.getters['quote/getMapLoader']);
const userData = reactive({
    name: '',
    email: '',
    phone: '',
    address: '',
    place: {}
});
const userData_v = {
    name: { required, minLength: minLength(2) },
    email: { required, email },
    phone: { required, phone: helpers.withMessage('Phone Number is invalid', telInputValid) },
    address: { required, address: helpers.withMessage('Address not selected', () => {
            return places.value !== null
        }) },
    city: ''
}
const validate = useVuelidate(userData_v, toRefs(userData));


const save = async () => {
    validate.value.$touch();

    if (validate.value.$invalid) return;

    store.commit('quote/setUserData', userData);

    step.value = OPTION_STEP;

    store.commit('quote/setIndent', 25)

    await store.dispatch('quote/changeStepTransform', { next: true });
}

// const handleEnter = async (e) => {
//     if (e.key === 'Enter' && step.value === CONTACT_STEP) {
//         e.preventDefault();
//         await save();
//     }
// }

const handleTab = (e) => {
    if (e.key === 'Tab' && e.target.getAttribute('class') === 'bg-theme-31 hover:bg-theme-20 text-white font-bold py-2 px-4 rounded') {
        e.preventDefault();
    }
}

const init = () => {
   setHeightSlideFormItem();
  
    const loader = new Loader({
        apiKey: process.env.MIX_GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: [
            'geometry',
            'places',
            'drawing'
        ]
    });

    store.commit('quote/setMapLoader', loader);

    mapLoader.value.load().then((google) => {
        const input = document.getElementById('address-input');

        const autocomplete = new google.maps.places.Autocomplete(input, {
            fields: ['place_id', 'geometry', 'name', 'address_components', 'formatted_address']
        });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (!place.place_id) {
                return
            }

            places.value = autocomplete.getPlace();
            userData.place = place;
            userData.address = place.formatted_address;

            let cityName = place?.address_components?.find(item => item?.types[0] === 'locality')?.long_name;
            userData.city = cityName ?? '';
        })
    });
}


watch(
    () => userData.address,
    (address) => {
        if(places.value && places.value.formatted_address && address !== places.value.formatted_address) {
            places.value = null;
        }
    }
);

const setPercentage = (height) => {
  let percentage;

  switch (true) {
    case height > 700 && height <= 800:
      percentage = 18;
      break;
    case height > 600 && height <= 700:
      percentage = 21;
      break;
    case height > 500 && height <= 600:
      percentage = 24;
      break;
    case height > 400 && height <= 500:
      percentage = 27;
      break;
    case height > 300 && height <= 400:
      percentage = 40;
      break;
    case height <= 300:
      percentage = 53;
      break;
    default:
      percentage = 15;
      break;
  }

  return percentage;
}

const setHeightSlideFormItem = async () => {
  const baseHeight = window.innerHeight;
  const slideFormItem = document.querySelectorAll('.slide-form-item');
  const percentage = setPercentage(baseHeight);
  const height = Number(baseHeight - (baseHeight * percentage / 100).toFixed()) + 25;

  console.log('height', height, slideFormItem);
  slideFormItem.forEach(slide => {
    slide.style.height = `${ height }px`;
  });

  await store.commit('quote/setStepHeight', height);
  await store.commit('quote/setWindowSize', { height: window.innerHeight, width: window.innerWidth });
}

onMounted(async () => {
    document.body.addEventListener('keydown', handleTab);

    await store.commit('quote/clearLayersFeet');

    init();
});
</script>

<style scoped>

</style>
