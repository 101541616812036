<template>
    <div class="wizard-progress z-50">
        <div
            v-for="(stepItem, stepIndex) in steps"
            :key="stepIndex"
            :class="['step ' +  stepItem.state, {'text-gray-600': stepItem.skip}]"
            :data-step="stepIndex"
        >
            <div :class="['node', {'cursor-pointer': stepItem.state === 'complete'}]">
                <i v-show="stepItem.state === 'complete'" class="gg-check"></i>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { CONTACT_STEP, OPTION_STEP, SUMMARY_STEP, PRESIZE_STEP } from "@scripts/store/quote/quoteConstants";


const store = useStore();
const disabledSize = computed(() => store.getters['quote/getdisabledSize']);
const step = computed({
    get() {
        return store.getters['quote/step']
    },
    set(value) {
        store.commit('quote/setStep', value)
    }
});

const steps = ref([
    {
        value: CONTACT_STEP,
        text: 'Contact',
        state: 'in-progress',
        skip: false,
    },
    {
        value: OPTION_STEP,
        text: 'Option',
        state: 'default',
        skip: false,
    },
    {
        value: PRESIZE_STEP,
        text: 'Size',
        state: 'default',
        skip: false,
    }, {
        value: SUMMARY_STEP,
        text: 'Summary',
        state: 'default',
        skip: false,
    }
]);

const goToStep = (toStep, stepState) => {
    if (stepState === 'complete') {
        let transformData = store.getters['quote/getTransformData'];
        let stepIndex = steps.value.findIndex(st => st.value === toStep);

        store.commit('quote/setStepTransform', transformData.indent * stepIndex * -1);
        step.value = toStep;

        steps.value.forEach((step, index) => {
            if (index > stepIndex) {
                step.state = '';
            }
        })
    }
}


watch(
    () => disabledSize.value,
    (value) => {
        let index = steps.value.findIndex(st => st.value === PRESIZE_STEP);

        if (value) {
            steps.value[index].skip = true;
        } else {
            steps.value[index].skip = false;
        }
    }
);


watch(step, (first, second) => {
    let stepIndexFirst = steps.value.findIndex(st => st.value === first);
    let stepIndexSecond = steps.value.findIndex(st => st.value === second);
    let index = steps.value.findIndex(st => st.value === PRESIZE_STEP);

    if (stepIndexSecond < stepIndexFirst) {
        steps.value[stepIndexSecond].state = 'complete';
        steps.value[stepIndexFirst].state = 'in-progress';
    } else {
        steps.value[stepIndexSecond].state = '';
        steps.value[stepIndexFirst].state = 'in-progress';
    }

    steps.value.forEach((step, index) => {
        if (index > stepIndexFirst) {
            step.state = '';
        }
    });

    if (disabledSize.value) {
        steps.value[index].state = step.value === SUMMARY_STEP ? 'complete' : step.value === OPTION_STEP ? 'skip' : '';
        steps.value[index].skip = true;
    } else {
        steps.value[index].skip = false;
    }
});
</script>

<style lang="scss" scoped>
$mainColor: #3EAF69;
$baseColor: #B7B9BF;

.wizard-progress {
    display: table;
    width: 100%;
    table-layout: fixed;
    position: relative;
    z-index: 2;
    margin-bottom: 8rem;

    .step {
        display: table-cell;
        text-align: center;
        vertical-align: bottom !important;
        overflow: visible;
        position: relative;
        font-size: 16px;
        font-weight: 500;
        color: #B7B9BF;

        &:not(:last-child):before {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 38px;
            background-color: $baseColor;
            height: 4px;
            width: 100%;
        }

        .node {
            display: inline-block;
            border: 6px solid $baseColor;
            background-color: $baseColor;
            border-radius: 18px;
            height: 28px;
            width: 28px;
            position: absolute;
            top: 25px;
            left: 50%;
            margin-left: -18px;
        }

        .node {
            border-color: $baseColor;
            background-color: $baseColor;
        }

        &.default, &.in-progress, &.complete {
            &:before {
                background: $baseColor;
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#B7B9BF', endColorstr='#{$baseColor}', GradientType=1);
            }
        }

        &.complete {
            .node {
                background-color: $mainColor;
                border-color: $mainColor;
                box-shadow: 0px 2px 7px rgba(62, 175, 105, 0.35);
            }
        }

        &.in-progress {
            .node {
                border: 3px solid $mainColor;
                box-shadow: 0px 2px 7px rgba(62, 175, 105, 0.35);
                background-color: white;
            }

            color: #333333;
        }

        @media (min-width: 200px) and (max-width: 240px) {
            font-size: 11px !important;
        }
    }
}

.wizard-progress .step > div {
    position: absolute;
    bottom: -5.5rem;
    left: 0;
    right: 0;
}

.gg-check {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 28px;
    height: 28px;
    border: 2px solid transparent;
    border-radius: 100px;
    margin-left: -12px;
    margin-top: -11px;

    &:after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        left: 8px;
        top: 1px;
        width: 8px;
        height: 18px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        transform-origin: bottom left;
        transform: rotate(45deg)
    }
}

@media (max-width: 452px) {
    .node {
        margin-top: 14px;
    }
    .step {
        &:before {
            margin-top: 14px;
        }
    }
}
</style>
