<template>
    <div ref="mapRef" />
</template>

<script setup>
import { onMounted, ref, defineProps } from 'vue';
import { initializeMap } from './index';


const props = defineProps({
    init: {
        type: Function,
        required: true
    }
});
const mapRef = ref();


onMounted(() => {
    props.init((mapConfig) => initializeMap(mapRef.value, mapConfig));
});
</script>
