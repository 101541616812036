<template>
        <div
          :id="props.id"
          class="modal"
          style="z-index: 9998 !important;"
          tabindex="-1"
          aria-hidden="false"
      >
        <div class="modal-dialog" style="width: 100%; max-width: 485px;">
          <div class="modal-content">
            <div class="modal-body p-4">
              <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>
              <div class="text-center">
                <form @submit.prevent="savePreSetSize">
                  <div class="form-group product-modal-image-wrapper">
                    <ImageUploader
                        :remove-file="removeFile"
                        @fileUploaded="fileUploadedSize"
                        :url="currentSize?.image?.url"
                    />
                  </div>
                  <div class="form-group mt-5">
                    <FormInput
                        v-model="sizeValidate.title.$model"
                        :errors="sizeValidate.title.$errors"
                        type="text"
                        label="Title"
                        placeholder="Enter title"
                        :required="true"
                    />
                  </div>
                  <div class="mt-2">
                    <FormInput
                        v-model.lazy.trim="sizeValidate.size.$model"
                        :errors="sizeValidate.size.$errors"
                        :step="0.01"
                        label="Pre-set Size"
                        type="number"
                        placeholder="0"
                    >
                      <template #append>
                        <div
                            v-text="units"
                            class="input-group-text"
                        />
                      </template>
                    </FormInput>
                  </div>
                  <div class="flex justify-center my-2">
                    <MainButton
                        title="Save"
                        type="submit"
                        class-map="inline-block my-2"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, defineEmits, watch } from 'vue';
import { maxLength, minLength, minValue, numeric, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ImageUploader from '@components/UI/ImageUploader';
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import axios from 'axios';
import cash from 'cash-dom/dist/cash';
const emit = defineEmits(['preSetSize']);
const props = defineProps({
    id: {
        type: String
    },
    currentSize: {
      type: Object,
      required: false
    },
    optionPreSizes: {
      type: Array,
      required: false
    },
    units: {
      type: String
    }
});
const isUpdated = ref(false)
const preSizes = ref([])

const sizeData = reactive({
  id: 0,
  title: '',
  size: 0,
  image: null
})
const newRules = {
  title: {  },
  size: {  }
}

const sizeValidate = useVuelidate(newRules, toRefs(sizeData))
const removeFile = ref(false);

const generateId = () => {
  return (
      "_" +
      Math.random()
          .toString(36)
          .substr(2, 9)
  );
}

const fileUploadedSize = async (file) => {
  let formData = new FormData()
  formData.append('file', file)

  removeFile.value = false

  let response = await axios.post('/api/v1/media', formData).catch((er) => console.log(er))

  if (response.status == 200) {
    sizeData.image = response.data.media

    sizeValidate.value.$reset();
  }
}

const savePreSetSize = async (e) => {
    sizeValidate.value.$touch()

  if (!sizeValidate.value.$invalid) {
    removeFile.value = true

    sizeValidate.value.$reset();

    //add new presize to array
    if (sizeData.id == 0) {
      sizeData.id = generateId()
      preSizes.value.push(JSON.parse(JSON.stringify(sizeData)))
    }
    //edit existing presize in array
    else {
      const index = preSizes.value.findIndex(el => el.id === sizeData.id);
      if (index !== -1) {
        preSizes.value[index] = { ...preSizes.value[index], ...sizeData };
      }
    }
  }

  await emit('preSetSize', preSizes.value)

  sizeData.id = 0
  sizeData.title = '',
  sizeData.size = 0,
  sizeData.image = null

  cash('#' + props.id).modal('hide')
}

onMounted(() => {

});
watch(
  () => props.currentSize,
  (value) => {
      sizeData.id = value.id
      sizeData.title = value.title,
      sizeData.size = value.size,
      sizeData.image = value.image
    }, {
      deep: true
    }
)
watch(
    () => props.optionPreSizes,
    (value) => {
      if (value) {
        preSizes.value = [ ...JSON.parse(JSON.stringify(value)) ]
      }
    }
)
</script>

<style lang="scss" scoped>
.attribute-item {
  padding: 0.3rem;
}

.product-modal-image-wrapper :deep(.height-img-wrapper) {
  height: 10rem;
  width: 10rem;
}
</style>
