<template>
    <div>
        <Tabulator
            :ajax-url="options.ajaxUrl"
            :ajax-params="options.ajaxParams"
            :columns="options.columns"
            :filtering-fields="options.filteringFields"
            :prevent-submit="true"
            title="Customers"
        />
    </div>
</template>

<script setup>
import { columns } from '@scripts/component/CustomerTabulatorColumns.js';
import Tabulator from '~/Pages/Tabulator/TabulatorPage.vue';


const options = {
    ajaxUrl: '/api/customer',
    columns: columns,
    initialSort: [
        { column: "created_at", dir: "asc" }
    ],
    filteringFields: [
        {
            name: 'Date',
            value: 'created_at'
        },
        {
            name: 'Name',
            value: 'name'
        },
        {
            name: 'Phone',
            value: 'phone'
        },
        {
            name: 'Email',
            value: 'email'
        },
        {
            name: 'Address',
            value: 'address'
        },
        {
            name: 'City',
            value: 'city'
        }
    ]
}
</script>
