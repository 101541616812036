<template>
    <div>
        <div id="contentManager">
            <template v-once>
                {{ modelValue }}
            </template>
        </div>
        <div id="contentBlocks" />
    </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import grapeConfig from './grapeConfig';


const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    }
});

let editor = null;


onMounted(() => {
    const store = useStore();
    const config = grapeConfig();
    const currentWebsite = computed(() => store.getters['website/website']);

    config.storageManager.urlLoad = `/api/landing-page?website_id=${ currentWebsite.value.id }`;
    config.storageManager.urlStore = `/api/page?website_id=${ currentWebsite.value.id }`;
    editor = grapesjs.init(config);
    editor.setComponents(props.modelValue);
});
</script>


<style>

.gjs-cv-canvas {
    height: 100%;
    width: 100%;
    top: 0;
}

.gjs-one-bg {
    background-color: rgb(35 42 59);
}

.gjs-block {
    font-family: 'Poppins', sans-serif;
    width: auto;
    min-height: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    border-width: 1px;
    padding: 0.5rem 0.75rem;
    font-weight: 500;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: rgba(28, 63, 170, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

</style>
