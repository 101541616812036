<template>
    <Layout title="Subscription settings">
        <template #body>
            <div
                v-if="subSettings.length > 0"
                class="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 mt-5"
            >
                <template
                    v-for="(setting, index) in subSettings"
                    :key="index"
                >
                    <div
                        class="box rounded-md relative cursor-pointer h-full p-4"
                        @click="setActive(setting)"
                    >
                        <div class="block font-medium text-center">
                            <div>{{ setting.subName }}</div>
                            <Line />
                            <div class="flex flex-col justify-start text-left gap-2">
                                <div
                                    v-for="(settingItem, settingIndex) in setting.items"
                                >
                                    {{ formatString(settingIndex) }}: {{ settingItem }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </Layout>

    <div
        id="updateSettings"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal" @click="closeModalUpdate"></i>
                    <form v-if="activeSetting" @submit.prevent="updateSub">
                        <FormInput
                            v-for="(settingItem, settingIndex) in requestData.items"
                            v-model="requestData.items[settingIndex]"
                            :errors="validate.items[settingIndex].$errors"
                            :key="settingItem"
                            :label="formatString(settingIndex)"
                            type="number"
                            class="mb-4"
                            required
                        />

                        <div class="flex justify-center gap-4">
                            <MainButton
                                :type-button="disabledButton ? 'disabled' : ''"
                                title="Save"
                                type="submit"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, onBeforeMount, reactive, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { minValue, numeric, required } from "@vuelidate/validators";
import axios from "axios";
import Line from "@components/Line.vue";
import MainBlock from "@components/MainBlock.vue";
import cash from "cash-dom/dist/cash";
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import Layout from "@layouts/Layout.vue";


const subSettings = ref([]);
const activeSetting = ref(null);
const disabledButton = ref(false);
const toast = inject('toast');
const requestData = reactive({
    items: {
        users: "",
        layers: "",
        "map_searches": ""
    },
    subId: '',
});
const rules = {
    items: {
        users: { numeric, minValue: minValue(1) },
        layers: { numeric, minValue: minValue(1) },
        "map_searches": { numeric, minValue: minValue(1) }
    },
    subId: { required },
}
const validate = useVuelidate(rules, requestData);


const formatString = (str) => {
    let words = str.split('_');

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }

    return words.join(' ');
}

const setActive = (settings) => {
    requestData.items = settings.items;
    requestData.subId = settings.subId;

    activeSetting.value = settings;
    disabledButton.value = false;

    cash('#updateSettings').modal('show');
}

const closeModalUpdate = () => {
    activeSetting.value = null;
    cash('#updateSettings').modal('hide');
}


const updateSub = async () => {
    validate.value.$touch();

    if (!validate.value.$invalid) {
        disabledButton.value = true;

        axios.put(`/api/update-app-setting/${activeSetting.value.id}`, requestData).then((res) => {
            toast({
                type: 'success',
                text: res.data.message
            });

            cash('#updateSettings').modal('hide');
        }).catch((e) => {
            toast({
                type: 'danger',
                text: e.response.data.message
            });
        }).finally(() => {
            disabledButton.value = false;
        });
    }
}

onBeforeMount(() => {
    axios.get('/api/get-app-settings').then((response) => {
        subSettings.value = response.data;
    });
});
</script>


<style scoped>

</style>
