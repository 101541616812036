<template>
    <div class="w-full">
        <label v-if="label.length > 0" class="form-label text-left w-full">{{ label }}</label>
        <div
            v-click-outside="onClickOutside"
            :class="classMaps"
            :style="{zIndex: indexZ, paddingTop: '0.35rem', paddingBottom: '0.35rem', paddingLeft: '0.5rem', gap: 0}"
        >
            <div
                :class="['vti__dropdown', {'open': isOpen}]"
                aria-label="phoneList Code Selector"
                aria-haspopup="listbox"
                aria-expanded="false"
                role="button"
            >
                <span class="vti__selection" @click="isOpen = !isOpen;">
                    <span :class="[flag, 'vti__flag']"></span>
                    <DropdownArrow :active="isOpen" class-map="mr-3" />
                </span>
                <ul v-if="isOpen" class="vti__dropdown-list below" role="listbox">
                    <li
                        v-for="item in phoneList"
                        :class="['vti__dropdown-item', {'preferred' : item.preferred}, {'last-preferred' : item.lastPreferred}]"
                        role="option"
                        tabindex="-1"
                        aria-selected="false"
                        @click="selectCountry(item)"
                    >
                        <span class="vti__flag" :class="[item.flag]"></span>
                        <strong>{{ item.name }}</strong>
                        <span class="ml-2">{{ item.code }}</span>
                    </li>
                </ul>
            </div>
            <input
                v-maska="{ mask: phoneMask }"
                :value="modelValue"
                :id="id"
                :placeholder="placeholder"
                class="vti__input"
                type="tel"
                @input="(event) => emit('update:modelValue', event.target.value)"
            >
        </div>

        <template v-if="errors && errors.length > 0">
            <div
                v-for="(error, index) in errors"
                :key="index"
                class="text-theme-6 mt-2"
            >
                {{ error.$message }}
            </div>
        </template>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, onMounted, computed } from 'vue';
import { phoneList } from '@scripts/component/phoneList.js';
import DropdownArrow from "./DropdownArrow.vue";


const props = defineProps({
    placeholder: {
        type: String,
        default: 'Contact Number'
    },
    errors: {
        type: Array,
        required: false,
        default: '',
    },
    modelValue: {
        type: String,
        default: '+1'
    },
    classMap: {
        type: [ String, Array ],
        default: () => []
    },
    id: {
        type: String,
        default: 'phone'
    },
    defaultVal: {
        type: String,
        default: ''
    },
    label: {
        type: String,
        default: 'Company phone'
    },
    indexZ: {
        type: Number,
        default: 50
    }
});
const tempPhone = ref('');
const emit = defineEmits([ 'update:modelValue', 'code' ]);
const isOpen = ref(false);
const flag = ref('us');
const phoneMask = ref([ "+1 ### ### ####", "" ]);
const classMaps = computed(() => [ 'vue-tel-input form-control block', {'border !border-theme-40': props.errors && props.errors.length > 0}, ...props.classMap ]);
const regulars = ref([]);
const validMask = ref('');


const onClickOutside = () => {
    isOpen.value = false;
}

const changePhoneMask = (mask) => {
    phoneMask.value = mask[0];
    document.getElementById(props.id).setAttribute('data-mask', mask[0]);
    tempPhone.value = '';
}

const selectCountry = (item) => {
    flag.value = item.flag;
    isOpen.value = false;

    changePhoneMask(item.phoneMask);

    emit('update:modelValue', '');
}

const usePhoneMask = (value) => {
    let codeVal = '';

    regulars.value.forEach(mask => {
        if (mask.code.test(value)) {
            codeVal = mask.code;
            flag.value = mask.flag;
        }
    });

    return codeVal;
}

const inputValid = (value) => {
    regulars.value = [];

    for (const i in phoneList) {
        if (phoneList[i].regulars !== undefined && phoneList[i].regulars !== '') {
            regulars.value.push({
                code: phoneList[i].regulars,
                flag: phoneList[i].flag,
                phoneMask: phoneList[i].phoneMask
            })
        }
    }
}


watch(
    () => tempPhone.value,
    (value) => {
        inputValid(value);

        let validMaskData = usePhoneMask(value);

        if (validMaskData !== '') {
            phoneList.filter(item => {
                if (item.regulars && item.regulars === validMaskData) {
                    flag.value = item.flag;
                    changePhoneMask(item.phoneMask)
                }
            });
        }
    }
);

onMounted(() => {
    tempPhone.value = props.defaultVal;
});
</script>
<style lang="scss" scoped>
.dark .vti__dropdown-item:hover {
    background-color: rgba(41, 49, 69) !important;
}

.vti__dropdown-item:hover {
    background-color: #f3f3f3;
}
</style>
