export default {
    user: state => state.user,
    loggedIn: state => state.loggedIn,
    subscribed: state => state.user?.isSubscribed ?? false,
    ownerSubscribed: state => state.user?.ownerIsSubscribed ?? false,
    isPaymentMethod: state => state.user?.isPaymentMethod ?? false,
    isWebSites: state => state.isWebSites,
    getAppSettings: state => state?.appSettings,
    haveAccess: state => state.user && state.user.haveAccess,

    hasRole: state => state.user && state.user.roles && state.user.roles.length > 0,
    isSales: state => state.user && state.user.roles && state.user.roles.length > 0 && state.user.roles[0].code === 'sales_team',
    isManager: state => state.user && state.user.roles && state.user.roles.length > 0 && state.user.roles[0].code === 'office_manager',
    isOwner: state => state.user && state.user.roles && state.user.roles.length > 0 && state.user.roles[0].code === 'owner',
    isAdmin: state => state.user && state.user.roles && state.user.roles.length > 0 && state.user.roles[0].code === 'admin',

    subTypeMeasureBasic: state => state.user.subData && state.user.subData.planType === 0,
    subTypeMeasurePro: state => state.user.subData && state.user.subData.planType === 1,
    subTypeBasic: state => state.user.subData && state.user.subData.planType === 2,
    subTypePremium: state => state.user.subData && state.user.subData.planType === 3,
    unwantedSubTypes: state => state.user?.unwantedSubType ?? false,
    
    getTrialPlanIsExpired: state => state.trialPlanIsExpired,
}
