export default function checkSubType({ next, store }) {
    const subTypeMeasureBasic = store.getters['auth/subTypeMeasureBasic'];

    if (subTypeMeasureBasic) {
        return next({
            name: 'dashboard'
        });
    }

    return next();
}