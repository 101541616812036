import { useStore } from 'vuex';

export default {
    computed: {
        getVisibilityTooltipStyle() {
            let store = useStore();
            let darkMode = store.getters['main/darkMode'];

            return {
                color: darkMode ? '#a0ffa0' : '#3EAF69'
            }
        }
    }
}