import Toastify from 'toastify-js'

export default {
    install: (app) => {
        app.config.globalProperties.$toast = (options) => {
            const background =
                options.type === 'success' ? 'linear-gradient(to right, #11998e, #38ef7d)' :
                options.type === 'warning' ? 'linear-gradient(to left, #f12711, #f5af19)' :
                options.type === 'danger' ? 'linear-gradient(to right, #ff416c, #ff4b2b)' :
                null

            Toastify({
                duration: 5000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true,
                text: options.text,
                style: {
                    background
                }
            }).showToast()
        }
        app.provide('toast', app.config.globalProperties.$toast)
    }
}
