<template>
    <div>
        <label
            v-if="label.length > 0"
            class="form-label cursor-pointer"
        >
            <slot name="label">
                {{ label }}
            </slot>
        </label>
        <div :style="{border: errors && errors.length > 0 ? '1px solid #C9000E' : ''}" class="rounded">
            <select
                v-select="{ props, emit, computedOptions }"
                :class="['tom-select', classMap]"
                @change="(e) => emit('changeSelected', e.target.value)"
            >
                <slot />
            </select>
        </div>
        <template v-if="errors && errors.length > 0">
            <div
                v-for="(err, index) in errors"
                :key="index"
                class="text-theme-6 mt-2"
            >
                {{ err.$message }}
            </div>
        </template>
    </div>
</template>

<script>
import { defineComponent, computed, watch, defineEmits } from 'vue'
import { setValue, init, reInit } from './index'


export default defineComponent({
    directives: {
        select: {
            mounted(el, { value }) {
                const clonedEl = cash(el).clone().insertAfter(el)[0];
                cash(el).attr('hidden', true);
                setValue(clonedEl, value.props);
                init(clonedEl, value.emit, value.computedOptions);
            },
            updated(el, { value }) {
                const clonedEl = cash(el).next()[0];
                setValue(clonedEl, value.props);
                reInit(clonedEl, value.props, value.emit, value.computedOptions);
            }
        }
    },
    props: {
        options: {
            type: Object,
            default() {
                return {}
            }
        },
        modelValue: {
            type: [String, Number, Array],
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        removeButton: {
            type: Boolean,
            default: true
        },
        errors: {
            type: Array,
            default: []
        },
        classMap: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const emit = defineEmits(['update:modelValue', 'changeSelected']);

        const computedOptions = computed(() => {
            let options = {
                ...props.options,
                plugins: {
                    dropdown_input: {},
                    ...props.options.plugins
                }
            }

            if (Array.isArray(props.modelValue)) {
                let pluginsData = { ...options.plugins };

                if (props.removeButton) {
                    pluginsData = {
                        ...options.plugins, remove_button: {
                            title: 'Remove this item'
                        },
                    };
                }

                options = {
                    persist: false,
                    create: true,
                    onDelete: function (values) {
                        return confirm(
                            values.length > 1
                                ? 'Are you sure you want to remove these ' +
                                values.length +
                                ' items?'
                                : 'Are you sure you want to remove "' + values[0] + '"?'
                        )
                    },
                    ...options,
                    plugins: pluginsData
                }
            }

            return options
        });


        watch(
            () => props.modelValue,
            (value) => {
                context.emit('change')
            }
        );

        return {
            emit,
            props,
            ...context,
            computedOptions
        }
    }
})
</script>