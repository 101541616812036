<template>
    <MainBlock>
        <template #body>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <form @submit.prevent="save">
                        <div class="flex items-center">
                            <Title title="Password" type-title="medium" class="!mb-2 !mt-0" />
                        </div>
                        <fieldset>
                            <FormInput
                                v-model="validate.current_password.$model"
                                :errors="validate.current_password.$errors"
                                label="Old Password"
                                id="change-password-form-1"
                                type="password"
                                placeholder="Input old password"
                            />

                            <div class="mt-3">
                                <FormInput
                                    v-model="validate.password.$model"
                                    :errors="validate.password.$errors"
                                    label="New Password"
                                    id="change-password-form-2"
                                    type="password"
                                    placeholder="Input new password"
                                />
                            </div>

                            <div class="mt-3">
                                <FormInput
                                    v-model="validate.password_confirmation.$model"
                                    :errors="validate.password_confirmation.$errors"
                                    label="Confirm New Password"
                                    id="change-password-form-3"
                                    type="password"
                                    placeholder="Input new password"
                                />
                            </div>
                            <div class="items-center flex justify-center">
                                <MainButton
                                    :type-button="loading ? 'disabled' : ''"
                                    type="submit"
                                    class-map="mt-4"
                                    title="Change Password"
                                />
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div>
                    <form @submit.prevent="saveProfileInfo">
                        <div class="flex items-center">
                            <Title title="Profile" type-title="medium" class="!mb-2 !mt-0" />
                        </div>
                        <div class="mb-3">
                            <FormInput
                                v-model.trim="validateInfo.first_name.$model"
                                :errors="validateInfo.first_name.$errors"
                                label="First Name"
                                id="update-profile-form-1"
                                placeholder="First Name"
                            />
                        </div>
                        <div class="mb-3">
                            <FormInput
                                v-model.trim="validateInfo.last_name.$model"
                                :errors="validateInfo.last_name.$errors"
                                label="Last Name"
                                id="update-profile-form-2"
                                placeholder="Last Name"
                            />
                        </div>
                        <div class="mb-3">
                            <FormInput
                                v-model.trim="validateInfo.email.$model"
                                :errors="validateInfo.email.$errors"
                                type="email"
                                label="Email"
                                id="update-profile-form-4"
                                placeholder="Email"
                            />
                        </div>

                        <div class="items-center flex justify-center">
                            <MainButton
                                :type-button="loading ? 'disabled': ''"
                                type="submit"
                                title="Save"
                                class-map="mt-3"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </template>
    </MainBlock>
</template>

<script setup>
import { email, minLength, required, sameAs } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { computed, inject, onMounted, reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import FormInput from "@components/UI/Input.vue";
import Title from "@components/Title.vue";
import MainButton from "@components/Button/MainButton.vue";
import MainBlock from "../../Components/MainBlock.vue";


const store = useStore();
const toast = inject('toast');
const user = computed(() => store.getters['auth/user']);
const loading = ref(false);
const formData = reactive({
    current_password: '',
    password: '',
    password_confirmation: ''
});
const formData_v = {
    current_password: { required, minLength: minLength(8) },
    password: { required, minLength: minLength(8) },
    password_confirmation: { required, sameAs: sameAs(computed(()=> formData.password))},
}
const formDataInfo = reactive({
    first_name: '',
    last_name: '',
    email: '',
    company_name: ''
});
const formDataInfo_v = {
    first_name: { required, minLength: minLength(2) },
    last_name: { required, minLength: minLength(2) },
    email: { required, email }
}
const validate = useVuelidate(formData_v, toRefs(formData));
const validateInfo = useVuelidate(formDataInfo_v, toRefs(formDataInfo));

const resetForm = () => {
    formData.current_password = '';
    formData.password = '';
    formData.password_confirmation = '';

    validate.value.$reset();
}

const save = async () => {
    validate.value.$touch();

    if (!validate.value.$invalid) {
        loading.value = true;
        try {
            await store.dispatch('auth/changePassword', formData);
            resetForm();
            toast({
                type: 'success',
                text: 'Password successfully updated!'
            });
        } catch (e) {
            toast({
                type: 'danger',
                text: e.response.data.message
            });
        } finally {
            loading.value = false;
        }
    }
}


const saveProfileInfo = async () => {
    validateInfo.value.$touch();

    if (!validateInfo.value.$invalid) {
        loading.value = true;
        try {
            await store.dispatch('auth/changeProfileInformation', formDataInfo);
            const { data: { data } } = await store.dispatch('auth/user')
            store.commit('auth/setUser', data);
            toast({
                type: 'success',
                text: 'Profile information successfully updated.'
            })
        } catch (e) {
            toast({
                type : 'danger',
                text: e.response.data.message
            });
        } finally {
            loading.value = false;
        }
    }
}

onMounted(() => {
    formDataInfo.first_name = user.value?.first_name;
    formDataInfo.last_name = user.value?.last_name;
    formDataInfo.email = user.value?.email;
    formDataInfo.company_name = user.value?.company_name;
});
</script>
