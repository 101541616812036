<template>
    <div class="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 mt-5">
        <template v-for="(sub, index) in subscriptions" :key="`sub-${index}`">
            <div
                v-if="sub.type !== 2"
                class="box rounded-md relative cursor-pointer h-full p-4"
                @click="setActive(sub)"
            >
                <div class="block font-medium text-center">
                    <div>{{ sub.name }} <span v-if="sub.type === 1">(For Influencer)</span></div>
                    <Line />
                    <div class="flex flex-col justify-start text-left gap-2">
                        <div>Subscribe price: {{ sub.price }}</div>
                        <div class="break-all">Subscribe description: {{ sub.description }}</div>
                        <div>Subscribe type: {{ sub.typeName }}</div>
                        <div>Plan type: {{ sub.planName }}</div>
                    </div>
                </div>
            </div>
        </template>
        <a
            :data-target="'#addSub'"
            href="javascript:void(0);"
            class="block col-span-1"
            data-toggle="modal"
            @click="openModalAdd"
        >
            <div class="add-new zoom-in rounded-md text-center border border-theme-32 h-full">
                <div class="text-4xl h-full flex items-center justify-center text-theme-32">+</div>
            </div>
        </a>
    </div>

    <div
        id="addSub"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>
                    <form @submit="saveSub">
                        <div class="grid gap-4">
                            <div>
                                <CustomSelect
                                    v-model="validate.type.$model"
                                    :options="types"
                                    label="Sub Type"
                                    class="w-full"
                                />
                            </div>
                            <div>
                                <CustomSelect
                                    v-model="validate.plan_type.$model"
                                    :options="subTypes"
                                    label="Plan Type"
                                    class="w-full"
                                />
                            </div>
                            <TomSelect
                                v-model="validate.type.$model"
                                label="Sub Type"
                                class="w-full"
                            >
                                <option
                                    v-for="(type, index) in types"
                                    v-text="type.name"
                                    :key="index"
                                    :value="type.value"
                                />
                            </TomSelect>
                            <FormInput
                                type="text"
                                label="Name"
                                v-model="validate.name.$model"
                                :errors="validate.name.$errors"
                            />
                            <FormInput
                                type="number"
                                label="Price"
                                v-model="validate.price.$model"
                                :errors="validate.price.$errors"
                            >
                                <template #prepend>
                                    <div
                                        class="input-group-text"
                                        v-text="$store?.getters['website/website']?.currency?.sign ?? '$'"
                                    />
                                </template>
                            </FormInput>
                            <div class="form-group mt-1">
                                <FormTextarea
                                    v-model="validate.description.$model"
                                    :errors="validate.description.$errors"
                                    label="Description:"
                                    cols="60"
                                    rows="5"
                                />
                            </div>
                            <div class="flex justify-center">
                                <MainButton
                                    :type-button="disabledSaveButton ? 'disabled' : ''"
                                    title="Save"
                                    type="submit"
                                    class-map="inline-block my-2"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div
        id="updateSub"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal" @click="closeModalUpdate"></i>
                    <form @submit.prevent="updateSub">
                        <FormInput
                            v-model="validateUpdate.name.$model"
                            :readonly="validateUpdate.type.$model === '2' || validateUpdate.type.$model === '3'"
                            :errors="validateUpdate.name.$errors"
                            id="subscribe-name"
                            type="text"
                            class="mb-4"
                            label="Subscribe name"
                        />

                        <FormInput
                            v-model="validateUpdate.price.$model"
                            :errors="validateUpdate.price.$errors"
                            type="number"
                            class="mb-4"
                            label="Subscribe price"
                            placeholder="0.00"
                        >
                            <template #prepend>
                                <div
                                    class="input-group-text"
                                    v-text="store?.getters['website/website']?.currency?.sign ?? '$'"
                                />
                            </template>
                        </FormInput>

                        <FormTextarea
                            v-model="validateUpdate.description.$model"
                            :errors="validateUpdate.description.$errors"
                            label="Description:"
                            cols="60"
                            rows="5"
                        />

                        <div class="flex">
                            <input class="mr-1" type="checkbox" v-model="validateUpdate.is_show.$model" :checked="validateUpdate.is_show.$model">
                            <label for="">Hide</label>
                        </div>

                        <input v-model="validateUpdate.type.$model" type="hidden">

                        <div class="flex justify-center gap-4 mt-4">
                            <MainButton
                                :type-button="disabledButton ? 'disabled' : ''"
                                title="Save"
                                style="z-index: 1000000000"
                                type="submit"
                            />
<!--                            <MainButton-->
<!--                                :type-button="disabledButton ? 'disabled' : 'danger'"-->
<!--                                title="Delete"-->
<!--                                style="z-index: 1000000000"-->
<!--                                type="button"-->
<!--                                @click.stop="deleteSub"-->
<!--                            />-->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, computed, defineProps, defineEmits, watch, inject, } from 'vue';
import { maxLength, required, numeric, minValue, requiredIf, minLength } from "@vuelidate/validators";
import { useVuelidate } from '@vuelidate/core';
import { useStore } from "vuex";
import axios from 'axios';
import cash from 'cash-dom/dist/cash';
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import Line from "@components/Line.vue";
import FormTextarea from "@components/UI/FormTextarea.vue";
import { isEmpty } from "lodash";
import CustomSelect from "@components/UI/CustomSelect.vue";


const props = defineProps({
    subscriptions: {
        type: Array
    }
});
const requestDataUpdate = reactive({
    name: null,
    price: null,
    description: null,
    type: null,
    planType: null,
    is_show: true,
});
const rulesUpdate = reactive({
    name: { required, minLength: maxLength(40) },
    description: { required, minLength: maxLength(400) },
    price: { },
    type: { required },
    is_show: { required },
});
const subTypes = ref([]);
const types = ref([
    { name: 'Influencer', value: 1 },
]);
const requestData = reactive({
    name: '',
    description: '',
    price: 0,
    type: 1,
    plan_type: 2,
});
const rules = computed(() => {
    return {
        name: { required, maxLength: maxLength(40), minLength: minLength(3) },
        description: { required, maxLength: maxLength(400) },
        price: { required, numeric, minValue: minValue(1) },
        type: { required },
        plan_type: { required },
    }
});
const validate = useVuelidate(rules, requestData);
const validateUpdate = useVuelidate(rulesUpdate, requestDataUpdate);
const emit = defineEmits([ 'deleted', 'updated', 'save' ]);
const activeSub = ref(null);
const store = useStore();
const toast = inject('toast');
const disabledSaveButton = ref(false);
const disabledButton = ref(false);


const setActive = (sub) => {
    requestDataUpdate.name = sub.name;
    requestDataUpdate.description = sub.description;
    requestDataUpdate.price = sub.price;
    requestDataUpdate.type = sub.type;
    requestDataUpdate.planType = sub.planType;
    requestDataUpdate.is_show = sub.isShow;
    validateUpdate.value.$reset();

    rulesUpdate.price = { required, numeric, minValue: minValue(sub.planType === 0 ? 0 : 1) }
    rules.price = { required, numeric, minValue: minValue(1) }

    activeSub.value = sub;

    cash('#updateSub').modal('show');
}

const closeModalUpdate = () => {
    activeSub.value = null;
    cash('#updateSub').modal('hide');
}

const removeSearch = () => {
    const dropdowns = document.querySelectorAll('.dropdown-input-wrap');

    dropdowns.forEach(dropdown => dropdown.remove());
}

const openModalAdd = () => {
    validate.value.$reset();
    requestData.name = '';
    requestData.price = 0;
    requestData.description = '';
    requestData.type = 1;
    requestData.plan_type = 2;

    activeSub.value = null;

    removeSearch();
}

const deleteSub = () => {
    disabledButton.value = true;

    axios
        .delete('/api/delete-subscription/' + activeSub.value.priceId)
        .then((response) => {
            activeSub.value = null;
            cash('#updateSub').modal('hide');

            emit('deleted');

            toast({
                type: 'success',
                text: response.data.message
            });
        })

        .catch((e) => {
            toast({
                type: 'danger',
                text: 'Plan plan was not deleted'
            });
        }).finally(() => {
            disabledButton.value = false;
        });
}

const saveSub = (e) => {
    e.preventDefault();
    validate.value.$touch();

    if (!validate.value.$invalid) {
        disabledSaveButton.value = true;
        axios
            .post('/api/create-subscription', requestData)
            .then(() => {
                cash('#addSub').modal('hide');

                emit('save');

                setTimeout(() => {
                    disabledSaveButton.value = false;
                }, 200);

                requestData.name = '';
                requestData.description = '';
                requestData.price = '';
                validate.value.$reset();
            })
            .catch((e) => {
                disabledSaveButton.value = false;
            });
    }
}

const updateSub = async () => {
    validateUpdate.value.$touch();

    if (!validateUpdate.value.$invalid) {
        disabledButton.value = true;

        axios.put(`/api/update-subscription/${activeSub.value.priceId}`, requestDataUpdate).then((res) => {
            toast({
                type: 'success',
                text: res.data.message
            });

            emit('updated');

            cash('#updateSub').modal('hide');
        }).catch((e) => {
            toast({
                type: 'danger',
                text: e.response.data.message
            });

        }).finally(() => {
            disabledButton.value = false;
        });
    }
}


watch(
    () => requestData.type,
    (value) => {
        if (value === '1' && requestData.trial_end === '') {
            requestData.trial_end = '7_days';
        } else {
            requestData.trial_end = '';
        }

        removeSearch();

        setTimeout(() => {
            removeSearch();
        }, 200);
    }
);

watch(
    () => requestData.plan_type,
    () => {
        removeSearch();

        setTimeout(() => {
            removeSearch();
        }, 200);
    }
);

watch(
    () => requestData.trial_end,
    () => {
        removeSearch();

        setTimeout(() => {
            removeSearch();
        }, 200);
    }
);


watch(
    () => props.subscriptions,
    (subscriptions) => {

        if(!isEmpty(subscriptions)) {
            for (const item of props.subscriptions) {
                if (item.planType > 1 && item.type == 0) {
                    subTypes.value.push({ name: item.name, value: item.planType });
                }
            }
        }
    }
);
</script>
<style scoped>
.product-modal-image-wrapper :deep(.height-img-wrapper) {
    height: 10rem;
    width: 10rem;
}
</style>
