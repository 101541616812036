import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import main from './main';
import admin from './admin';
import auth from './auth';
import messages from './messages';
import sideMenu from './side-menu';
import simpleMenu from './simple-menu';
import topMenu from './top-menu';
import website from './website';
import notification from './notification';
import quote from './quote';
import general from './general';
import map from './map'

const store = createStore({
    modules: {
        admin,
        main,
        sideMenu,
        simpleMenu,
        topMenu,
        auth,
        messages,
        website,
        notification,
        quote,
        general,
        map
    },
    plugins: [createPersistedState()]
})

export function useStore() {
    return store
}

export default store
