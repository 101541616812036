<template>
    <Layout title="Website Settings">
        <template #tip>
            <TipWebsiteSettings />
        </template>
        <template #body>
            <WebsiteNav />
            <div class="grid grid-cols-12 gap-6">
                <router-view v-slot="{ Component, route }">
                    <transition :name="route.meta.transition || 'fade'">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </div>
        </template>
    </Layout>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from 'vuex';
import WebsiteNav from './Partials/Nav.vue';
import TipWebsiteSettings from "@components/Tooltips/TipWebsiteSettings.vue";
import Title from "@components/Title.vue";
import Layout from "@layouts/Layout.vue";


const store = useStore();
const user = computed(() => store.getters['auth/user']);
const darkMode = computed({
    get() {
        return store.getters['main/darkMode'];
    },
    set(value) {
        store.commit('main/setDarkMode', value);
    }
});
</script>
