<template>
    <GeneralLayout>
        <DarkModeSwitcher />
        <div>
            <div class="min-h-screen">
                <div class="flex justify-center text-center items-center flex-col h-screen mx-4">
                    <div class="pb-[4rem]">
                        <img :src="logoTransparent" alt="" class="w-[205px]">
                    </div>
                    <MainBlock class-map="w-full max-w-[550px]" title="Sign In">
                        <template #body>
                            <form class="w-full" @submit.prevent="login">
                                <fieldset :disabled="loading">
                                    <div class="intro-x mt-8">
                                        <FormInput
                                            v-model="validate.email.$model"
                                            :errors="validate.email.$errors"
                                            placeholder="Enter email"
                                            label="Email"
                                        />
                                        <div class="mt-4">
                                            <FormInput
                                                v-model="validate.password.$model"
                                                :errors="validate.password.$errors"
                                                type="password"
                                                placeholder="Enter password"
                                                label="Password"
                                            />
                                        </div>
                                    </div>
                                    <div class="intro-x flex justify-end mt-4 mb-5">
                                        <router-link
                                            to="/forgot-password"
                                            class="text-theme-32 dark:text-theme-10"
                                        >
                                            Forgot your password ?
                                        </router-link>
                                    </div>
                                    <div class="flex justify-center gap-4">
                                        <MainButton title="Login" :type-button="loading ? 'disabled' : ''" type="submit" />
                                        <router-link
                                            to="/register"
                                            type="button"
                                            class="button"
                                        >
                                            Sign up
                                        </router-link>
                                    </div>
                                    <div class="mt-5 xl:mt-8 text-center xl:text-left">
                                        By signing up, you agree to our
                                        <a
                                            class="text-theme-32 dark:text-theme-10 text-decoration-underline"
                                            href="/terms-and-conditions"
                                            target="_blank"
                                        >
                                            Terms and Conditions
                                        </a>
                                        &
                                        <a
                                            class="text-theme-32 dark:text-theme-10 text-decoration-underline"
                                            href="/privacy-policy"
                                            target="_blank"
                                        >
                                            Privacy Policy
                                        </a>
                                    </div>
                                </fieldset>
                            </form>
                        </template>
                    </MainBlock>
                </div>
            </div>
        </div>
    </GeneralLayout>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { email, minLength, required } from '@vuelidate/validators';
import { useStore } from "vuex";
import { computed, getCurrentInstance, inject, onMounted, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import DarkModeSwitcher from '@components/DarkModeSwitcher.vue';
import cash from 'cash-dom/dist/cash';
import logoTransparent from '@images/svg/logo.svg';
import FormInput from "@components/UI/Input.vue";
import MainBlock from "@components/MainBlock.vue";
import MainButton from "@components/Button/MainButton.vue";
import GeneralLayout from "../../Layouts/GeneralLayout.vue";


const store = useStore();
const router = useRouter();
const { proxy } = getCurrentInstance();
const loading = computed(() => store.getters['main/loading']);
const toast = inject('toast');
const formData = reactive({
    email: '',
    password: '',
});
const formData_v = {
    email: { required, email },
    password: { required, minLength: minLength(8) },
}
const validate = useVuelidate(formData_v, toRefs(formData));


const login = async () => {
    store.commit('main/setLoading', true);
    validate.value.$touch();

    if (!validate.value.$invalid) {
        const two_factor = false
        try {
            if (!two_factor) {
                const { data } = await store.dispatch('auth/login', formData);

                const isAdmin = data.roles.findIndex((e) => {
                    return e.code === 'admin'
                }) !== -1
                await store.commit('auth/setUser', data);
                await store.commit('auth/setLoggedIn', true);

                const dataSettings = await store.dispatch('auth/settings');
                await store.commit('auth/setAppSettings', dataSettings.data);

                if (isAdmin) {
                    await router.push({ name: 'users-management' });
                } else if (data.owner_id === null && !store.getters['auth/subscribed']) {
                    document.location.replace('/add-plan')
                } else if (!data.owner_id && (!data.isWebSites || data.currentStep < 3)) {
                    document.location.replace('/guide');
                } else {
                    await router.push({ name: 'dashboard' });
                }
            }
        } catch (e) {
            proxy.$errorHandler(e);
        }
    }

    store.commit('main/setLoading', false);
}


onMounted(() => {
    store.commit('sideMenu/resetState');
    store.commit('website/resetWebsiteData');
    store.commit('main/setLoading', false);

    cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login');
});

</script>
