<template>
    <LayoutTooltip :content="content" />
</template>
<script setup>
import { computed, defineProps, onMounted, watch } from 'vue';
import LayoutTooltip from "./LayoutTooltip.vue";


const props = defineProps({
    typeAttribute: {
        type: [ Number, String ],
        required: true
    },
    label: {
        type: String,
        default: ''
    }
});
const metric = computed(() => props.label.replace('Price ', ''));

const setContent = () => {
    switch (props.typeAttribute) {
        case 5:
        case '5':
            return 'Single Unit Items have a single fixed price (or price range), which is added to the total price of the job. Your customers have the option to add more than just one of each item.';
        case 2:
        case '2':
            return `This is your regular price per ${metric.value === 'SQFT' ? 'square' : 'linear' } foot for this option.`;
        case 3:
        case '3':
            return `This cost will be added to the normal price/${metric.value === 'SQFT' ? 'square' : 'linear' } foot. For example, if you enter "10" the additional cost for the Upgrade will be $10*total ${metric.value.toLowerCase()} (for each option).`;
        default:
            return `The price you enter will be added to the ${metric.value === 'SQFT' ? 'square' : 'linear' } foot cost of the project. For example, if you enter '10' the additional cost for the add on will equal 10*${metric.value}.`;
    }
}
const content = computed(() => setContent());

watch(
    () => props.label,
    (label) => {
        setContent()
    }
);
</script>