<script setup>
import { computed, defineProps, ref, useSlots, watch } from "vue";

const props = defineProps({
    options: {
        type: Array,
        default: []
    },
    modelValue: {
        type: [ String, Number, Array ],
        default: ''
    },
    label: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        required: false,
        default: '',
    },
    removeButton: {
        type: Boolean,
        default: true
    },
    errors: {
        type: Array,
        default: []
    },
    classMap: {
        type: String,
        default: ''
    }
});
const emit = defineEmits([ 'update:modelValue' ]);
const slots = useSlots();
const isOpen = ref(false);
const selectedOption = ref(null);
const showLabel = computed(() => {
    return !!slots['label']
});


const selectOption = (option) => {
    selectedOption.value = option.value;
    emit('update:modelValue', option.value);
    isOpen.value = false;
}

const onClickOutside = () => {
    isOpen.value = false;
}

selectedOption.value = props.modelValue;

watch(() => props.modelValue, (newValue) => {
    selectedOption.value = newValue;
});
</script>

<template>
    <label
        v-if="label.length > 0 || showLabel"
        class="form-label cursor-pointer"
    >
        <slot name="label">
            {{ label }}
        </slot>
    </label>

    <div>
        <div v-click-outside="onClickOutside" class="dropdown">
            <div :class="['dropdown-selected', {'error': errors && errors.length > 0}, classMap]" @click="isOpen = !isOpen">
                {{ options.find(option => option.value === selectedOption)?.name || props.placeholder }}
                <span class="arrow" :class="{ 'open': isOpen }">
                    <svg
                        data-v-1202f497=""
                        width="7"
                        height="14"
                        viewBox="0 0 9 13"
                        fill="#3EAF69"
                        xmlns="http://www.w3.org/2000/svg"
                        style="transform: rotate(-90deg);"
                    >
                        <path
                            d="M5.70872 12.1009L5.70798 12.1002L1.18625 7.41377C1.05151 7.27413 0.957851 7.1308 0.897178 6.98408C0.836662 6.83773 0.804687 6.67771 0.804687 6.49921C0.804687 6.32071 0.836662 6.1607 0.897178 6.01435C0.957851 5.86763 1.05151 5.7243 1.18625 5.58466L5.70799 0.898235C5.92955 0.6686 6.13834 0.556855 6.33288 0.51753C6.52642 0.478409 6.75515 0.500962 7.03517 0.625682C7.31508 0.750352 7.4979 0.910383 7.61448 1.09508C7.73191 1.2811 7.80416 1.52717 7.80469 1.85823L7.80469 11.1406C7.80469 11.4724 7.73259 11.7188 7.61523 11.9049C7.49874 12.0896 7.31585 12.2497 7.03545 12.3744C6.75477 12.4992 6.52585 12.5216 6.33246 12.4823C6.13813 12.4429 5.92971 12.331 5.70872 12.1009Z"
                            stroke="#3EAF69"
                        />
                    </svg>
                </span>
            </div>
            <div class="dropdown-options" v-if="isOpen">
                <div
                    v-for="option in options"
                    class="dropdown-option"
                    :key="option.value"
                    :class="[{'selected': option.value === modelValue}]"
                    @click="selectOption(option)"
                >
                    {{ option.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.dropdown {
    position: relative;
    user-select: none;

    &.replace-border {
        border: none !important;
    }
}

.dropdown-selected {
    padding: 8px 12px;
    border: 1px solid #D3D3D3;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.25rem;
    background-color: #fff;

    &.error {
        border-color: #C9000E;
    }
}

.arrow {
    display: inline-block;
    transition: transform 0.3s ease;
}

.arrow.open {
    transform: scaleY(-1);
}

.dropdown-options {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100%;
    z-index: 1000;
    border: 1px solid #D3D3D3;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
}

.dropdown-option {
    padding: 8px 16px;
    cursor: pointer;
    color: #333333;

    &:hover {
        color: #3EAF69;
        background-color: white;
    }

    &.selected {
        color: white;
        background-color: #3EAF69;
    }
}
</style>