<template>
    <div>
        <label v-if="label.length > 0" class="form-label text-left w-full">
            <slot name="label">
                {{ label }}
            </slot>
            <slot name="tip" />
        </label>

        <div class="relative" v-click-outside="onClickOutside">
            <div
                :class="[classMapWrap,
                'block-custom-select border border-theme-33 flex justify-between cursor-pointer w-full items-center py-2 px-2', {'open': isOpen}, { '!border-theme-40': errors && errors.length > 0 }]"
                @click="clickOpen"
            >
                <div v-if="selectedItem && selectedItem.length > 0">
                    <div class="ml-2 mr-4">
                        <div>{{ selectedItem }}</div>
                    </div>
                </div>
                <template
                    v-else-if="modelValue && !selectedItem"
                    v-for="(option, index) in options_"
                    :key="index"
                >
                    <div v-if="options_.length > 0 && (option.name == modelValue || option.value == modelValue)" class="ml-2 mr-4">
                        <div>{{ option.name }}</div>
                    </div>
                </template>
                <div v-else>
                    <div class="ml-2 mr-4">{{ placeholder ?? 'Select Item' }}</div>
                </div>
                <DropdownArrow :width="7" :height="14" :active="isOpen" class-map="mr-3" />
            </div>

            <div
                v-if="isOpen"
                :class="['w-full border border-theme-33 absolute bg-theme-35 overflow-y-auto', {'blockOpen': isOpen}, classMap]"
                style="z-index: 100; max-height: 250px; transition: .5s;"
            >
                <div v-if="isSearch" class="w-full mb-2">
                    <input v-model="search" class="w-full p-2 border-0 border-b" placeholder="Search..." type="text">
                </div>

                <div
                    v-if="options_.length > 0"
                    v-for="(option, index) in options_"
                    :key="index"
                    :class="['w-full p-2 cursor-pointer', {'hover:text-theme-32 hover:bg-white': option.value != modelValue}, {'bg-theme-32 text-white': (option.name == modelValue || option.value == modelValue) || (selectedItem && (option.name == selectedItem || option.value == selectedItem))}]"
                    @click="selectItem(option)"
                >
                    {{ option.name }}
                </div>
                <div v-else class="w-[50[x] h-[50px] text-center pt-4">
                    Empty
                </div>
            </div>
        </div>

        <template v-if="errors && errors.length > 0">
            <div
                v-for="(error, index) in errors"
                :key="index"
                class="text-theme-6 mt-2"
            >
                {{ error.$message }}
            </div>
        </template>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import DropdownArrow from "./DropdownArrow.vue";


const props = defineProps({
    label: {
        type: String,
        default: ''
    },
    options: {
        type: Array,
        default: []
    },
    placeholder: {
        type: String,
        required: false,
        default: '',
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: [String, Number],
        default: null,
    },
    errors: {
        type: Array,
        required: false,
        default: '',
    },
    isSearch: {
        type: Boolean,
        default: false
    },
    classMap: {
        type: String,
        default: ''
    },
    classMapWrap: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(['update:modelValue']);
const options_ = ref(props.options);
const isOpen = ref(false);
const search = ref('');
const selectedItem = ref('');


const onClickOutside = () => {
    search.value = '';
    isOpen.value = false;
}

const clickOpen = () => {
    isOpen.value = !isOpen.value;
}

const selectItem = (item) => {
    selectedItem.value = item.name;
    search.value = '';

    isOpen.value = false;

    emit('update:modelValue', item.value);
}


watch(
    () => search.value,
    (searchValue) => {
        if (!searchValue) {
            options_.value = props.options;
        } else {
            const regex = new RegExp(`.*${searchValue}.*`, 'i');

            options_.value = options_.value.filter(option => regex.test(option.name));
        }
    }
);

watch(
    () => props.options,
    (options) => {
        options_.value = options;
    }
);

watch(
    () => props.modelValue,
    (data) => {
        if (data) {
            const filter = props.options.filter(option => option.value == data || option.name == data);

            if (filter[0] !== undefined) {
                emit('update:modelValue', filter[0].value);
            }
        }
    }
);
</script>

<style lang="scss" scoped>
.option {
    width: 100%;
}
</style>
