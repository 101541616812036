<template>
    <div>
        <ButtonBack class="mt-12" title="Products" :to="{name: 'SideMenu-pr-catalog'}" />

        <AttributesItems
            :attributeType="ATTRIBUTE_TYPE"
            @attributeChosen="attributeChosen"
        />

    </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import AttributesItems from './Partials/AttributesItems.vue';
import Title from "@components/Title.vue";
import ButtonBack from "@components/Button/ButtonBack.vue";


const selectedAttributeId = ref(null);
const router = useRouter();
const ATTRIBUTE_TYPE = 1;


const attributeChosen = (item) => {
    selectedAttributeId.value = item.id
}
</script>
