<template>
    <div class="top-bar">
        <template v-if="!isAdmin">
            <div class="mr-2 flex gap-4 justify-center flex-col md:flex-row">
                <TrialButton/>
                <ButtonHowToUse />
            </div>
            <NotificationComponent />
        </template>
        <div class="dropdown mr-5">
            <div
                class="flex items-center"
                :class="{
                    'dropdown-toggle': false
                }"
                role="button"
                aria-expanded="false"
            >
                <div>
                    <span
                        v-if="website?.code && !$store.getters['auth/user']?.roles?.includes(role => role.code === 'admin')"
                        v-text="`${website.company_name} (${website.code})`"
                    />
                    <span v-else>
                        Super Admin
                    </span>
                </div>
            </div>
            <div
                ref="topBarDropdownMenu"
                class="dropdown-menu w-56"
            >
                <div
                    class="dropdown-menu__content dark:bg-dark-6 text-white"
                >
                    <div class="p-4 flex flex-col gap-2">
                        <LoadingIcon
                            v-if="loading"
                            icon="puff"
                            class="w-8 h-8 mx-auto"
                        />

                        <template v-else>
                            <p class="text-center text-gray-600">
                                Websites are missing
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="dropdown w-8 h-8">
            <div
                class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
                role="button"
                aria-expanded="false"
            >
                <img
                    v-if="user.media_id"
                    class="rounded-md"
                    :alt="user.media.name"
                    :src="user.media.url"
                >
                <img
                    v-else
                    alt="Dummy image"
                    :src="require('../../assets/images/placeholders/placeholder.jpg').default"
                >
            </div>
            <div
                ref="topBarDropdownMenu"
                class="dropdown-menu rounded-lg w-56"
                style="box-shadow: 0 0 21px rgba(0, 0, 0, 0.08); backdrop-filter: blur(2px);"
            >
                <div class="dropdown-menu__content bg-theme-35 dark:bg-dark-6">
                    <div class="p-4 border-b border-theme-39 dark:border-dark-3">
                        <div class="font-medium">
                            {{ user.full_name }}
                        </div>
                        <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
                            {{ user.company_name }}
                        </div>
                    </div>
                    <div v-if="user?.roles?.filter(role => role.code === 'admin').length">
                        <router-link
                            :to="{ name: 'settings-admin' }"
                            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-white dark:hover:bg-dark-3"
                        >
                            <SettingsIcon class="w-4 h-4 mr-2" style="stroke: #3EAF69" />
                            Settings
                        </router-link>
                    </div>
                    <div v-if="!user?.roles?.filter(role => role.code === 'admin').length">
                        <router-link
                            :to="{ name: 'profile' }"
                            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-white dark:hover:bg-dark-3"
                        >
                            <UserIcon class="w-4 h-4 mr-2" style="stroke: #3EAF69" />
                            Profile
                        </router-link>
                    </div>
                    <div
                        v-if="!user?.roles?.filter(role => role.code === 'admin').length && !user.owner_id"
                        class="border-t border-theme-39 dark:border-dark-3"
                    >
                        <a
                            href="/billing-portal"
                            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-white dark:hover:bg-dark-3"
                        >
                            <img
                                class="w-4 h-4 mr-2"
                                :src="require(`../../assets/images/svg/top-bar/billing-portal.svg`).default"
                                alt=""
                            >
                            Billing portal
                        </a>
                    </div>
                    <template v-if="$store.getters['auth/isOwner'] && !user?.roles?.filter(role => role.code === 'admin').length">
                        <div
                            v-if="user.isUpdatePlan"
                            class="border-t cursor-pointer border-theme-39 dark:border-dark-3"
                        >
                            <router-link
                                :to="{name: 'plans'}"
                                class="flex items-center p-2 block transition duration-300 ease-in-out hover:bg-white dark:hover:bg-dark-3"
                            >
                                <img
                                    class="w-4 h-4 mr-2"
                                    :src="require(`../../assets/images/svg/notification/update-sub.svg`).default"
                                    alt=""
                                >
                                Update plan
                            </router-link>
                        </div>
                        <div
                            v-if="user.isCanCancel"
                            id="cancel"
                            class="border-t cursor-pointer border-theme-39 dark:border-dark-3"
                        >
                            <span
                                v-if="!user.isCancel"
                                class="flex items-center p-2 block transition duration-300 ease-in-out hover:bg-white dark:hover:bg-dark-3"
                                @click.prevent="openModalCancel"
                            >
                                <img
                                    class="w-4 h-4 mr-2"
                                    :src="require(`../../assets/images/svg/top-bar/cancelSub.svg`).default"
                                    alt=""
                                >
                                Cancel subscription
                            </span>
                        </div>
                    </template>

                    <div class="border-t border-theme-39 dark:border-dark-3">
                        <button
                            type="button"
                            class="flex items-center w-full p-2 block transition duration-300 ease-in-out hover:bg-white dark:hover:bg-dark-3"
                            @click.prevent="logout"
                        >
                            <img
                                class="w-4 h-4 mr-2"
                                :src="require(`../../assets/images/svg/top-bar/billing-portal.svg`).default"
                                alt=""
                            >
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <DeleteModal
        :text="textDeleteModel"
        id="cancelWindowId"
        type-image="x"
        @delete="cancelSubscribe"
    />
</template>

<script>
import { MAIN_TYPE } from '@scripts/constants/pages';
import { inject, ref } from "vue";
import axios from "axios";
import cash from "cash-dom/dist/cash";
import NotificationComponent from '@components/Partials/NotificationComponent.vue';
import DeleteModal from "./Modal/DeleteModal.vue";
import ButtonHowToUse from "./Partials/ButtonHowToUse.vue";
import TrialButton from './Partials/TrialButton.vue'


export default {
    components: { TrialButton, ButtonHowToUse, DeleteModal, NotificationComponent },
    setup: function () {
        const toast = inject('toast');
        const textDeleteModel = ref('If you cancel your subscription now, you can still access the system until');

        return { toast: toast, textDeleteModel: textDeleteModel };
    },
    computed: {
        loading() {
            return this.$store.getters['main/loading']
        },
        user() {
            return this.$store.getters['auth/user']
        },
        website() {
            return this.$store.getters['website/website']
        },
        isAdmin() {
            const roles = this.$store.getters['auth/user'].roles;

            if (roles) {
                return roles.some(role => role.code === 'admin');
            }

            return false;
        }
    },

    async created() {
        this.$store.commit('main/setLoading', true)

        try {
            const userId = this.user.owner_id ?? this.user.id;
            const { data: { website } } = await this.$store.dispatch('website/index', userId);
            this.$store.commit('website/setWebsite', website);

            const { data: { pages } } = await this.$store.dispatch('website/pages', website.id);
            const {
                data: {
                    websiteSettings,
                    emailSettings
                }
            } = await this.$store.dispatch('website/setWebsiteSettings', website.id);

            this.$store.commit('website/setMainPage', pages.filter(page => page.page_type == MAIN_TYPE).pop());
            this.$store.commit('website/setWebsiteSettings', websiteSettings);
            this.$store.commit('website/setEmailSettings', emailSettings);

            const dataSettings = await this.$store.dispatch('auth/settings');
            this.$store.commit('auth/setAppSettings', dataSettings.data);

            const { data: { data } } = await this.$store.dispatch('general/actionGetMetrics');
            this.$store.commit('general/setMetrics', data);
        } catch (e) {
            if (e.response && e.response.status === 401) {
                this.$store.dispatch('auth/logout')
                    .then(() => this.$store.commit('auth/setLoggedIn', false))
                    .then(() => this.$router.push({ name: 'login' }))
            } else {
                if (e.response) {
                    this.$errorHandler(e)
                }
            }
        }

        this.$store.commit('main/setLoading', false)
    },

    methods: {
        async logout() {
            await this.$store.dispatch('auth/logout')
            this.$store.commit('auth/setLoggedIn', false)
            this.$refs.topBarDropdownMenu.remove();

            if (window.Intercom) {
                Intercom('shutdown');
            }
            this.$store.commit('auth/setUser', {});

            await this.$router.push({ name: 'login' })
        },
        cancelSubscribe() {
            axios.post('/api/v1/user/cancel-subscribe').then((response) => {
                cash('#cancelWindowId').modal('hide');
                document.getElementById('cancel').style.display = 'none';
                this.toast({
                    type: 'success',
                    text: response.data.message,
                });
            }).catch((e) => {
                this.toast({
                    type: 'danger',
                    text: 'Error'
                });
            }).finally(() => {
                window.location.reload()
            });
        },
        openModalCancel() {
            axios.get('/api/v1/user/get-end-date-subscription').then((response) => {
                this.textDeleteModel = `If you cancel your subscription now, you can still access the system until (${response.data})`;
            });

            setTimeout(() => {
                cash('#cancelWindowId').modal('show');
                document.getElementsByClassName('dropdown-menu')[1].classList.remove('show');
            }, 200);
        },
        setWebsite(website) {
            this.$store.commit('website/setWebsite', website)
        }
    }
}
</script>
