<template>
    <div style="margin-top: -0.3rem">
        <div class="flex items-center">
            <Title title="Personal Information" type-title="medium" class="!mb-2 !mt-0" />
        </div>
        <form @submit.prevent="saveProfileInfo">
            <fieldset>
                <div class="mb-3">
                    <FormInput
                        v-model.trim="validate.first_name.$model"
                        :errors="validate.first_name.$errors"
                        label="First Name"
                        id="update-profile-form-1"
                        placeholder="First Name"
                    />
                </div>
                <div class="mb-3">
                    <FormInput
                        v-model.trim="validate.last_name.$model"
                        :errors="validate.last_name.$errors"
                        label="Last Name"
                        id="update-profile-form-2"
                        placeholder="Last Name"
                    />
                </div>
                <div class="mb-3">
                    <FormInput
                        v-model.trim="validate.company_name.$model"
                        :errors="validate.company_name.$errors"
                        label="Company Name"
                        id="update-profile-form-3"
                        placeholder="Company Name"
                    />
                </div>
                <div class="mb-3">
                    <FormInput
                        v-model.trim="validate.email.$model"
                        :errors="validate.email.$errors"
                        type="email"
                        label="Email"
                        id="update-profile-form-4"
                        placeholder="Email"
                    />
                </div>
            </fieldset>
            <div class="items-center flex justify-center">
                <MainButton
                    :type-button="loading ? 'disabled': ''"
                    type="submit"
                    title="Save"
                    class-map="mt-3"
                />
            </div>
        </form>
    </div>
    <div class="m-auto">
        <form>
            <div class="w-40 mx-auto xl:mr-0 xl:ml-6">
                <div class="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                    <img
                        v-if="user.media_id"
                        class="rounded-md"
                        :alt="user.media.name"
                        :src="user.media.url"
                    >

                    <DefaultImg
                        v-else
                        class-map="rounded-md"
                        alt="Dummy image"
                    />
                </div>
            </div>
            <div class="mx-auto cursor-pointer relative mt-5 xl:mr-0 xl:ml-6">
                <div class="flex justify-center">
                    <MainButton :type-button="loading ? 'disabled' : ''" title="Change Photo" class-map="z-50" @click="testClick"/>
                </div>

                <input
                    :disabled="loading"
                    type="file"
                    class="w-full h-full top-0 left-0 absolute opacity-0 input-file"
                    @change="fileUploaded"
                >
            </div>
        </form>
    </div>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { email, minLength, required } from '@vuelidate/validators'
import {computed, getCurrentInstance, inject, onMounted, reactive, toRefs} from "vue";
import { useStore } from "vuex";
import FormInput from "@components/UI/Input.vue";
import MainButton from "@components/Button/MainButton.vue";
import Title from "@components/Title.vue";
import DefaultImg from "../../Components/UI/DefaultImg.vue";


const store = useStore();
const toast = inject('toast');
const { proxy } = getCurrentInstance();
const loading = computed(() => store.getters['main/loading']);
const user = computed(() => store.getters['auth/user']);
const formData = reactive({
    first_name: '',
    last_name: '',
    company_name: '',
    email: ''
});
const formData_v = {
    first_name: { required, minLength: minLength(2) },
    last_name: { required, minLength: minLength(2) },
    company_name: { required, minLength: minLength(2) },
    email: { required, email }
}
const validate = useVuelidate(formData_v, toRefs(formData));


const saveProfileInfo = async () => {
    store.commit('main/setLoading', true)
    validate.value.$touch();

    if (!validate.value.$invalid) {
        try {
            await store.dispatch('auth/changeProfileInformation', formData);
            const { data: { data } } = await store.dispatch('auth/user')
            store.commit('auth/setUser', data);
            toast({
                type: 'success',
                text: 'Profile information successfully updated.'
            })
        } catch (e) {
            proxy.$errorHandler(e);
        }
    }

    store.commit('main/setLoading', false)
}

const fileUploaded = async (e) => {
    store.commit('main/setLoading', true);

    try {
        const formData = new FormData
        formData.append('media', e.target.files[0])
        const { data: { message: text } } = await store.dispatch('auth/changeAvatar', formData)
        toast({
            type: 'success',
            text
        })
        const { data: { data } } = await store.dispatch('auth/user')
        store.commit('auth/setUser', data)
    } catch (e) {
        toast({
            type : 'danger',
            text: e.response.data.message
        });
    }

    e.target.value = null;
    store.commit('main/setLoading', false);
}


const testClick = () => {
    const fileInput = document.getElementsByClassName('input-file')[0];
    fileInput.click();
}

onMounted(() => {
    formData.first_name = user.value?.first_name;
    formData.last_name = user.value?.last_name;
    formData.company_name = user.value?.company_name;
    formData.email = user.value?.email;
});
</script>