<template>
    <Layout title="Customer Details">
        <template v-if="customerData" #body>
            <div>
                <MainBlock class-map="!text-left max-w-[25rem] !px-8">
                    <template v-if="customerData" #body>
                        <div class="mb-4">
                            <span class="block-title_">Name:</span>
                            {{ customerData.name }}
                        </div>
                        <div class="mb-4">
                            <span class="block-title_">Email:</span>
                            {{ customerData.email }}
                        </div>
                        <div class="mb-4">
                            <span class="block-title_">Phone:</span>
                            {{ customerData.phone }}
                        </div>
                        <div class="mb-4">
                            <span class="block-title_">Address:</span>
                            {{ customerData.address }}
                        </div>
                        <div v-if="customerData.city" class="mb-4">
                            <span class="block-title_">City:</span>
                            {{ customerData.city }}
                        </div>
                    </template>
                </MainBlock>

                <ButtonBack class="my-6 block" title="Customers" :to="{name: 'customers'}" />

                <div v-if="!isEmpty(calculationsData.data)" id="details-table" class="flex">
                    <table class="border border-2 rounded-md w-full">
                        <thead class="border-b">
                            <tr class="text-theme-34 dark:bg-dark-1 text-white">
                                <th class="whitespace-nowrap border border-2">Map Screenshot</th>
                                <th class="whitespace-nowrap border border-2">Quote</th>
                                <th class="whitespace-nowrap border border-2">Services</th>
                                <th class="whitespace-nowrap border border-2">Upgrades</th>
                                <th class="whitespace-nowrap border border-2">Options</th>
                                <th v-if="!store.getters['auth/isSales']" class="whitespace-nowrap">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(calculation, index) in calculationsData.data"
                                :key="index"
                            >
                                <td class="border-b border-r dark:border-dark-5 text-center" valign="center">
                                    <a
                                        href="javascript:void(0);"
                                        data-toggle="modal"
                                        data-target="#show-image-modal"
                                        class="flex items-center justify-center text-theme-6 ml-3"
                                        style="color: rgba(145, 199, 20, var(--tw-border-opacity))"
                                        @click="mapScreenUrl = calculation.media.url"
                                    >
                                        <i class="gg-image mr-2" style="width: 17px; height: 13px"></i>
                                    </a>
                                </td>
                                <td class="text-theme-37 border-b border-r dark:border-dark-5 text-center" valign="center">
                                    <template v-if="typeof calculation.result.total === 'object'">
                                        {{ calculation.result.currency }}{{ setFormatPrice(calculation.result.total.low) }} -
                                        {{ calculation.result.currency }}{{ setFormatPrice(calculation.result.total.high) }}
                                    </template>
                                    <template v-else>
                                        {{ calculation.result.currency }}{{ setFormatPrice(calculation.result.total) }}
                                    </template>
                                </td>
                                <td class="border-b border-r dark:border-dark-5" valign="top">
                                    <ul>
                                        <li
                                            v-for="(layerItem, layerIndex) in calculation.result.items"
                                            :key="layerIndex"
                                            class="p-3"
                                        >
                                            <MainBlock class-wrap="p-4">
                                                <template #body>
                                                    <div class="block justify-between items-center">
                                                        <div class="pb-2">
                                                            <div>{{ capitalize(layerItem.layer.name) }}</div>
                                                            <div>
                                                                <span>{{ layerItem.layer.metric.fullName }} -</span>
                                                                <span class="text-theme-37 font-medium">{{ layerItem.layer.feet }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="text-white bg-theme-32 text-center rounded-full py-1 px-2">
                                                            <template v-if="layerItem.option.price === 'Price TBD'">
                                                                {{ layerItem.option.price }}
                                                            </template>
                                                            <template v-else-if="typeof layerItem.layer.price === 'object'">
                                                                {{ calculation.result.currency }}{{ setFormatPrice(layerItem.option.price.low) }} -
                                                                {{ calculation.result.currency }}{{ setFormatPrice(layerItem.option.price.high) }}
                                                            </template>
                                                            <template v-else>
                                                                {{ calculation.result.currency }}{{ setFormatPrice(layerItem.option.price) }}
                                                            </template>
                                                        </div>
                                                    </div>
                                                </template>
                                            </MainBlock>
                                        </li>
                                    </ul>
                                </td>
                                <td class="border-b border-r dark:border-dark-5" valign="top">
                                    <template
                                        v-for="(item, index) in calculation.result.items"
                                        :key="index"
                                    >
                                        <ul v-if="!isEmpty(item.upgrades)">
                                            <li
                                                v-for="(upgradeItem, upgradeIndex) in item.upgrades"
                                                :key="upgradeIndex"
                                                class="p-3"
                                            >
                                                <MainBlock class-wrap="p-4">
                                                    <template #body>
                                                        <div class="block justify-between items-center">
                                                            <div class="pb-2">
                                                                {{ upgradeItem.name }}
                                                            </div>
                                                            <div class="text-white bg-theme-32 text-center rounded-full py-1 px-2">
                                                                <template v-if="upgradeItem.price === 'Price TBD'">
                                                                    {{ upgradeItem.price }}
                                                                </template>
                                                                <template v-else-if="typeof upgradeItem.price === 'object'">
                                                                    {{ calculation.result.currency }}{{ setFormatPrice(upgradeItem.price.low) }} -
                                                                    {{ calculation.result.currency }}{{ setFormatPrice(upgradeItem.price.high) }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ calculation.result.currency }}{{ setFormatPrice(upgradeItem.price) }}
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </MainBlock>
                                            </li>
                                        </ul>
                                    </template>
                                </td>
                                <td class="border-b border-r dark:border-dark-5" valign="top">
                                    <ul v-if="!isEmpty(calculation.result.simpleUnitItems)">
                                        <li
                                            v-for="(unitItem, unitItemIndex) in calculation.result.simpleUnitItems"
                                            :key="unitItemIndex+'-gate'"
                                            class="p-3"
                                        >
                                            <MainBlock class-wrap="p-4">
                                                <template #body>
                                                    <div class="block justify-between items-center">
                                                        <div class="pb-2">
                                                            {{ unitItem.name }}
                                                        </div>
                                                        <div class="text-white bg-theme-32 text-center rounded-full py-1 px-2">
                                                            <template v-if="typeof unitItem.price === 'object'">
                                                                {{ calculation.result.currency }}{{ setFormatPrice(unitItem.price.low) }} -
                                                                {{ calculation.result.currency }}{{ setFormatPrice(unitItem.price.high) }}
                                                            </template>
                                                            <template v-else>
                                                                {{ calculation.result.currency }}{{ setFormatPrice(unitItem.price) }}
                                                            </template>
                                                        </div>
                                                    </div>
                                                </template>
                                            </MainBlock>
                                        </li>
                                    </ul>
                                </td>
                                <td v-if="!store.getters['auth/isSales']" class="border-b dark:border-dark-5 text-center" valign="center">
                                    <div class="flex w-full items-center justify-center p-2">
                                        <a
                                            class="button danger flex text-theme-6"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#delete-modal"
                                            @click="deleteId(calculation.id)"
                                        >
                                            Delete
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <DeleteModal id="delete-modal" @delete="deleteCalculation" />

                <div
                    id="show-image-modal"
                    class="modal"
                    tabindex="-1"
                    aria-hidden="true"
                >
                    <div class="modal-dialog" style="min-width: 48%;">
                        <div class="modal-content">
                            <div class="modal-body p-4 flex flex-col">
                                <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>
                                <img class="p-3" :src="mapScreenUrl" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Layout>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { isEmpty } from "lodash";
import { setFormatPrice } from "@scripts/plugins/functions";
import axios from 'axios';
import cash from 'cash-dom/dist/cash';
import ButtonBack from "@components/Button/ButtonBack.vue";
import Layout from "@layouts/Layout.vue";
import MainBlock from "@components/MainBlock.vue";
import DeleteModal from "@components/Modal/DeleteModal.vue";


const customerData = reactive({});
const calculationsData = reactive({});
const router = useRouter();
const mapScreenUrl = ref();
const store = useStore();
// eslint-disable-next-line no-underscore-dangle1
const customerId = router.currentRoute._value.params.id;
// eslint-disable-next-line no-return-assign
let deleteRowId = null;

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

const getData = (id) => {
    axios
        .get(`/api/customer/${id}`)
        .then((response) => {
            const { data } = response;
            customerData.name = data.name;
            customerData.email = data.email;
            customerData.phone = data.phone;
            customerData.address = data.address;
            customerData.city = data.city;
            calculationsData.data = data.calculations;
        })
        .catch((e) => {
            router.push({ name: 'customers' });
        });
}

const deleteId = (id) => {
    deleteRowId = id;
};

const deleteCalculation = () => {
    axios.delete(`/api/calculation/${deleteRowId}`).then(() => {
        getData(customerId);
    });

    cash('#delete-modal').modal('hide');
}

watch(
    () => router.currentRoute,
    (currentRoute) => {
        const customerId = currentRoute?._value?.params?.id;

        if (customerId) {
            getData(customerId);
        }
    },
    {
        deep: true
    }
)


onMounted(() => {
    getData(customerId);
});
</script>

<style lang="scss" scoped>
.gg-image {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 20px;
    height: 16px;
    overflow: hidden;
    box-shadow: 0 0 0 2px;
    border-radius: 2px
}

.gg-image::after,
.gg-image::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border: 2px solid
}

.gg-image::after {
    transform: rotate(45deg);
    border-radius: 3px;
    width: 16px;
    height: 16px;
    top: 9px;
    left: 6px
}

.gg-image::before {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    top: 2px;
    left: 2px
}

.table {
    min-width: 1590px;
}

.gg-arrow-long-right {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    box-shadow: inset 0 0 0 2px;
    width: 11px;
    height: 6px
}

.gg-arrow-long-right::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    right: 0;
    bottom: -2px
}

#details-table {
    overflow-x: auto;

    th {
        padding: 15px 0;
    }
}

.show-name {
    @apply truncate font-medium max-w-[135px] px-2
}
</style>
