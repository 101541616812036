<template>
    <div class="p-5 mt-5">
        <PriceCatalogTabulatorFilter @search="search"/>

        <div class="overflow-x-auto scrollbar-hidden">
            <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 table-report table-report--tabulator"
            />
        </div>

        <div class="flex justify-center">
            <MainButton
                v-show="showButton"
                title="Save changes"
                class-map="mt-6"
                @click="saveChange"
            />
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed, ref, watch, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import Tabulator from 'tabulator-tables';
import feather from 'feather-icons';
import tippy from 'tippy.js';
import axios from 'axios';
import PriceCatalogTabulatorFilter from './PriceCatalogTabulatorFilter.vue';
import MainButton from "@components/Button/MainButton.vue";


const props = defineProps({
    ajaxUrl: String,
    columns: {
        type: Array,
        default: () => []
    },
    ajaxParams: {
        type: Object,
        default: () => {}
    },
});

const tabulator = ref();
const tableRef = ref();
const tableData = ref([]);
const showButton = ref(false);
const requestData = ref([]);
const store = useStore();
const toast = inject('toast');
const oldTableData = ref(null);
const darkMode = computed(() => store.getters['main/darkMode']);
const parent_id = computed(() => props.ajaxParams.attribute_id);


const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
        data: tableData.value,
        layout: "fitColumns",
        printAsHtml: true,
        printStyled: true,
        pagination: false,
        placeholder: 'No matching records found',
        columns: props.columns,
        renderComplete() {
            feather.replace({ 'stroke-width': 1.5 })
        },
        cellEdited: function (cell) {
            if (cell.getField() === 'priceOption') {
                let data = cell.getRow().getData();
                let option = data.option;
                let price = data.priceOption;
                let rows = tabulator.value.getRows();

                rows.forEach(function (row) {
                    let optionCell = row.getData();

                    if (optionCell.option === option) {
                        row.update({ 'priceOption': price });
                    }
                });
            }
        },
    })
}

const getTableData = async () => {
    await axios.get(props.ajaxUrl, {
        params: props.ajaxParams
    }).then((response) => {
        tableData.value = response.data
        oldTableData.value = JSON.parse(JSON.stringify(response.data))
    })
}


const search = (data) => {
    tabulator.value.setFilter([
        [
            { field: "option", type: 'like', value: data },
            { field: "upgrade", type: 'like', value: data },
        ]
    ]);
}

const getRequestData = () => {
    requestData.value = [];
    tableData.value.forEach((val, index) => {
        requestData.value.push(val)
    });
}

const saveChange = () => {
    getRequestData();
    let updateData = [];

    requestData.value.forEach((tabItem) => {
        if (tabItem.hidePriceId !== undefined && tabItem.hidePriceId !== '-') {
            updateData.push({
                attribute_id: tabItem.hidePriceId,
                attribute_data: {
                    value: tabItem.hidePrice
                },
                optionId: tabItem?.optionId,
            });
        }

        if (tabItem.upgradeId !== undefined && tabItem.upgradeId !== '-') {
            updateData.push({
                attribute_id: tabItem.upgradeId,
                attribute_data: {
                    price: tabItem.priceUpgrade
                }
            });
        }

        if (tabItem.optionId !== undefined && tabItem.optionId !== '-') {
            updateData.push({
                attribute_id: tabItem.optionId,
                attribute_data: {
                    price: tabItem.priceOption
                }
            });
        }
    });

    axios.patch(`${ props.ajaxUrl }`, updateData).then(() => {
        toast({
            type: 'success',
            text: 'Changes saved!'
        });
    }).catch((e) => {
        toast({
            type: 'danger',
            text: e.response.data
        });
    });
}

const fixTickCross = () => {
    let svgElementList = document.querySelectorAll('div.Tabulator-row div[Tabulator-field="hidePrice"] svg')
    svgElementList.forEach((svgElement) => {
        svgElement.classList.add('inline')
    })
}


watch(
    darkMode,
    (val) => {
        document.querySelectorAll('.price-tip span').forEach(tip => {
            tip.style.color = val ? '#a0ffa0' : 'rgba(28, 63, 170, var(--tw-bg-opacity))'
        });
});

watch(
    parent_id,
    async (value) => {
        watch(
            () => tableData.value,
            (tableData) => {
                fixTickCross();
                showButton.value = tableData.length > 0;
            });

        await getTableData();

        initTabulator();

        let tooltipClone = document.querySelector('.price-tip').cloneNode(true);

        tooltipClone.style.display = 'initial';

        document.querySelectorAll('.tabulator-col-title')[document.querySelectorAll('.tabulator-col-title').length - 1].appendChild(tooltipClone);

        tooltipClone.style.color = darkMode ? '#a0ffa0' : 'rgba(28, 63, 170, var(--tw-bg-opacity))';

        if (navigator.userAgent.match(/chrome|chromium|crios|edg|opr\//i)) {
            tooltipClone.style.top = '-0.4em'
        }

        tippy(tooltipClone, {
            content: "If you want to hide the cost of a certain product, select the 'Hide Price' button and your customer will get 'Price TBD' message on their Quote"
        });

        for (let i = 2; i < document.querySelectorAll('.price-tip').length; i++) {
            document.querySelectorAll('.price-tip')[i].remove();
        }
    },
    { deep: true }
);


onMounted(() => {
    initTabulator();
});
</script>
<style>
sup {
    top: -2px !important;
}
</style>