import { maskList } from '../maskList.js'
import { maskListRegulars } from '../maskListRegulars.js'
export function getArrayOfRegexes() {
    return maskListRegulars.map(mask => mask.code)
}

export function inputValid(value) {
    return maskListRegulars.some(mask => {
        return mask.code.test(value)
    })
}

export function telInputValid(value){
    return !/[a-zа-яё]/i.test(value);
}

export function usePhoneMask(selector) {
    function setMask() {
        let matrix = '+###############';

        maskList.forEach(item => {
            let code = item.code.replace(/[\s#]/g, ''),
                phone = this.value.replace(/[\s#-)(]/g, '');

            if (phone.includes(code)) {
                matrix = item.code;
            }
        });

        let i = 0,
            val = this.value.replace(/\D/g, '');

        this.value = matrix.replace(/(?!\+)./g, function(a) {
            return /[#\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a;
        });
    }

    let inputs = document.querySelectorAll(selector);

    inputs.forEach(input => {
        if (!input.value) input.value = '+';
        input.addEventListener('input', setMask);
        input.addEventListener('focus', setMask);
        input.addEventListener('blur', setMask);
    })
}