<template>
    <button
        type="button"
        :class="[
            'button',
            {
                'medium-size': size === 'medium',
                'small-size': size === 'small'
            }
        ]"
        @click.stop.prevent
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: 'UIButton',
    props: {
        type: {
            type: String,
            validator (value) {
                return ['primary', 'outline-secondary'].includes(value)
            },
            default: 'primary'
        },
        size: {
            type: String,
            default: ''
        }
    }
}
</script>
