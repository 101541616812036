<template>
    <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form
            id="tabulator-html-filter-form"
        >
<!--            <div class="sm:flex items-center sm:mr-4">-->
<!--                <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">-->
<!--                    Field-->
<!--                </label>-->
<!--                <TomSelect-->
<!--                    v-model="filter.field"-->
<!--                    :options="{placeholder: 'Select Field'}"-->
<!--                    class="w-full min-w-[10rem]"-->
<!--                >-->
<!--                    <option-->
<!--                        v-for="(fField, index) in filteringFields"-->
<!--                        :key="index"-->
<!--                        :value="fField.value"-->
<!--                        v-text="fField.name"-->
<!--                    />-->
<!--                </TomSelect>-->
<!--            </div>-->
<!--            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">-->
<!--                <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">-->
<!--                    Value-->
<!--                </label>-->
<!--                <input-->
<!--                    id="tabulator-html-filter-value"-->
<!--                    v-model="filter.value"-->
<!--                    type="text"-->
<!--                    class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"-->
<!--                    placeholder="Search..."-->
<!--                    @keyup.enter="onFilter"-->
<!--                >-->
<!--            </div>-->
<!--            <div class="flex gap-4 mt-2 xl:mt-0">-->
<!--                <MainButton-->
<!--                    id="tabulator-html-filter-go"-->
<!--                    title="Go"-->
<!--                    class-map="w-full"-->
<!--                    @click="onFilter"-->
<!--                />-->
<!--                <MainButton-->
<!--                    id="tabulator-html-filter-reset"-->
<!--                    title="Reset"-->
<!--                    class-map="w-full"-->
<!--                    @click="onResetFilter"-->
<!--                />-->
<!--            </div>-->
        </form>
    </div>
    <div class="overflow-x-auto scrollbar-hidden">
        <div
            id="tabulator"
            ref="tableRef"
            class="mt-5 table-report table-report--tabulator"
        />
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue';
import feather from 'feather-icons';
import Tabulator from 'tabulator-tables';
import MainButton from "../Button/MainButton.vue";


const props = defineProps({
    title: {
        type: String,
        default: 'Default title'
    },
    defaultFilterField: String,
    ajaxParams: {
        type: Object,
        default: () => {}
    },
    ajaxUrl: String,
    columns: {
        type: Array,
        default: () => []
    },
    filteringFields: {
        type: Array,
        default: () => []
    },
    rowClickCallback: {
        type: Function
    },
    isRefresh: {
        type: Boolean,
        default: false
    },
    detailingFilter: {
        type: Boolean,
        default: true
    },
    setPage: {
        type: Number,
        default: 1
    }
});
const tableRef = ref();
const tabulator = ref();
const filter = reactive({
    field: props.defaultFilterField,
    type: 'like',
    value: ''
});


const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: props.ajaxUrl,
        ajaxParams: props.ajaxParams,
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [ 10, 20, 30, 40 ],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: props.columns,
        rowClick(ev, row) {
            props.rowClickCallback(ev, row)
        },
        renderComplete() {
            feather.replace({ 'stroke-width': 1.5 })
        }
    });

    tabulator.value.setPage(props.setPage)
}

const reInitOnResizeWindow = () => {
    window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({ 'stroke-width': 1.5 })
    })
}

const onFilter = () => {
    tabulator.value.setFilter(filter.field, filter.type, filter.value)
}

const onResetFilter = () => {
    filter.field = props.defaultFilterField;
    filter.type = 'like';
    filter.value = '';
    onFilter();
}


watch(
    () => props.isRefresh,
    (value) => {
        if (value) {
            initTabulator();
        }
    }
);

onMounted(() => {
    initTabulator();
    reInitOnResizeWindow();
});
</script>
