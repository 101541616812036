export default function managersManagement ({ next, store }){
    const subTypeMeasureBasic = store.getters['auth/subTypeMeasureBasic'];

    if (subTypeMeasureBasic) {
        return next({
            name: 'dashboard'
        });
    }

    if (store.getters['auth/hasRole'] && store.getters['auth/loggedIn'] && (store.getters['auth/isManager'] || store.getters['auth/isSales'])) {
        return next({
            name: 'dashboard'
        });
    }

    return next();
}
