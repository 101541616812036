<template>
    <Layout title="Email settings">
        <template #body>
            <form method="POST" @submit="setSetting">
                <div class="rounded-md">
                    <Title title="Sender Info" type-title="medium" class="!my-0 pb-2" />
                    <div class="text-gray-600 pb-3">
                        If you leave these fields empty, the default sender email, 'support@help.ezestimate.io,' will be
                        used automatically.<br>However, please note that emails sent to this address will not be
                        forwarded to you.
                    </div>
                    <div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                        <div>
                            <FormInput
                                v-model="validate.settings.emailSettings.mailInfo.sender.recipient_email.$model"
                                :errors="validate.settings.emailSettings.mailInfo.sender.recipient_email.$errors"
                                label="Sender Email"
                                placeholder="Enter sender email"
                            >
                                <template #append>
                                    <div class="input-group-text">
                                        {{ domainRecipientEmail }}
                                    </div>
                                </template>
                                <template #tip>
                                    <RecipientTooltip />
                                </template>
                            </FormInput>
                        </div>
                        <div>
                            <FormInput
                                v-model="validate.settings.emailSettings.mailInfo.sender.forwarder_email.$model"
                                :errors="validate.settings.emailSettings.mailInfo.sender.forwarder_email.$errors"
                                type="email"
                                label="Forwarding Email"
                                placeholder="Enter forwarding email"
                            >
                                <template #tip>
                                    <ForwarderTooltip />
                                </template>
                            </FormInput>
                        </div>
                    </div>
                </div>
                <Line />
                <div class="rounded-md my-2">
                    <Title title="Company Info" type-title="medium" class="!my-0 pb-2" />
                    <div class="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4">
                        <div class="form-group my-2">
                            <FormInput
                                v-model="validate.settings.emailSettings.companyInfo.name.$model"
                                :errors="validate.settings.emailSettings.companyInfo.name.$errors"
                                label="Company name"
                                placeholder="Enter company name"
                            />
                        </div>
                        <div class="form-group my-2">
                            <FormInput
                                v-model="validate.settings.emailSettings.companyInfo.email.$model"
                                :errors="validate.settings.emailSettings.companyInfo.email.$errors"
                                label="Contact email"
                                placeholder="Enter contact email"
                            />
                        </div>
                        <div class="form-group my-2">
                            <InputPhone
                                v-model="requestData.settings.emailSettings.companyInfo.phone"
                                :default-val="validate.settings.emailSettings.companyInfo.phone.$model"
                                :errors="validate.settings.emailSettings.companyInfo.phone.$errors"
                                label="Company phone"
                                id="company_phone"
                                :index-z="49"
                            />
                        </div>
                    </div>
                </div>
                <Line />
                <div class="rounded-md my-2">
                    <div class="flex justify-between pb-2">
                        <Title title="Email Info" type-title="medium" class="!my-0" />
                    </div>
                    <div>
                        <div class="grid grid-cols-1 gap-4 mt-3">
                            <div class="form-group">
                                <FormInput
                                    v-model="validate.settings.emailSettings.mailInfo.title.$model"
                                    :errors="validate.settings.emailSettings.mailInfo.title.$errors"
                                    label="Mail title"
                                    placeholder="Enter mail title"
                                />
                            </div>
                            <div class="form-group mt-1">
                                <FormTextarea
                                    v-model="validate.settings.emailSettings.mailInfo.text.$model"
                                    :errors="validate.settings.emailSettings.mailInfo.text.$errors"
                                    label="Mail text"
                                    placeholder="Enter mail text"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center w-full">
                    <MainButton
                        :type-button="processing ? 'disabled' : ''"
                        class-map="my-2"
                        type="submit"
                        title="Save"
                    />
                </div>
            </form>
        </template>
    </Layout>
</template>

<script setup>
import { inject, computed, reactive, ref, onMounted, getCurrentInstance, watch } from "vue";
import { useStore } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { telInputValid } from '@mixins/composition/phoneMask';
import { email, minLength, required, helpers, maxLength, requiredIf } from '@vuelidate/validators';
import axios from 'axios';
import cash from 'cash-dom/dist/cash';
import FormTextarea from "@components/UI/FormTextarea.vue";
import Title from "@components/Title.vue";
import InputPhone from "@components/UI/InputPhone.vue";
import Layout from "@layouts/Layout.vue";
import MainButton from "@components/Button/MainButton.vue";
import Line from "@components/Line.vue";
import FormInput from "@components/UI/Input.vue";
import RecipientTooltip from "@components/Tooltips/Sender/RecipientTooltip.vue";
import ForwarderTooltip from "@components/Tooltips/Sender/ForwarderTooltip.vue";


const store = useStore();
const toast = inject('toast');
const processing = ref(false);
const recipientEmails = ref([]);
const formData = new FormData();
const currentWebsite = computed(() => store.getters['website/website']);
const emailSettings = computed(() => store.getters['website/getEmailSettings']);
const user = computed(() => store.getters['auth/user']);
const { proxy } = getCurrentInstance();
const domainRecipientEmail = ref('@ezstimate.io');
const unique = (value) => {
    return !recipientEmails.value.includes(value);
}
const specialCharsPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
const customValidation = (value) => {
    return !specialCharsPattern.test(value);
}
const requestData = reactive({
    settings: {
        emailSettings: {
            companyInfo: {
                name: emailSettings?.value?.companyInfo?.name,
                email: emailSettings?.value?.companyInfo?.email,
                phone: emailSettings?.value?.companyInfo?.phone,
            },
            mailInfo: {
                title: emailSettings?.value?.mailInfo?.title,
                text: emailSettings?.value?.mailInfo?.text,
                sender: {
                    recipient_email: emailSettings?.value?.mailInfo?.sender?.recipient_email ?? '',
                    forwarder_email: emailSettings?.value?.mailInfo?.sender?.forwarder_email ?? '',
                },
            }
        }
    },
    website_id: currentWebsite.value.id,
    type: 'EmailSettings'
});
const requestDataSender = computed(() => requestData.settings.emailSettings.mailInfo.sender);
const rules = reactive({
    settings: {
        emailSettings: {
            companyInfo: {
                name: { required, minLength: minLength(1) },
                email: { required, email },
                phone: {
                    required,
                    minLength: minLength(1),
                    phone: helpers.withMessage('Phone number is invalid', telInputValid)
                }
            },
            mailInfo: {
                title: { required, minLength: minLength(1) },
                sender: {
                    recipient_email: {
                        maxLength: maxLength(40),
                        withParams: helpers.withParams({ type: 'unique' }, value => unique(value)),
                        withMessage: helpers.withMessage('The email has already been taken.', unique),
                        withParamsSpecialChars: helpers.withParams({ type: 'noSpecialChars' }, value => customValidation(value)),
                        withMessageSpecialChars: helpers.withMessage('The sender email cannot contain special chars.', customValidation),
                        required: requiredIf(() => {
                            return (requestDataSender.value.forwarder_email === '' && requestDataSender.value.recipient_email === '' && requestDataSender.value.forwarder_email === null && requestDataSender.value.recipient_email === null) ? false : requestDataSender.value.forwarder_email !== '' && requestDataSender.value.forwarder_email !== null;
                        }),
                    },
                    forwarder_email: {
                        maxLength: maxLength(40),
                        required: requiredIf(() => {
                            return (requestDataSender.value.forwarder_email === '' && requestDataSender.value.recipient_email === '' && requestDataSender.value.forwarder_email === null && requestDataSender.value.recipient_email === null) ? false : requestDataSender.value.recipient_email !== '' && requestDataSender.value.recipient_email !== null;
                        }),
                    },
                },
                text: { required, minLength: minLength(1) }
            }
        }
    },
    website_id: currentWebsite.value.id
});
const validate = useVuelidate(rules, requestData);


const fileUploaded = (file) => {
    formData.append('file', file);

    axios.post('/api/v1/media', formData).then((res) => {
        cash('#upload-photo').modal('hide');
        requestData.settings.emailSettings.mailInfo.sender.photo = res.data.media.url;
    });
}

const getData = async () => {
    const { data } = await axios.get('/api/settings', { params: { settings: [ 'emailSettings' ] } });

    if (data.settings.length) {
        data.settings.forEach(setting => {
            if (setting.name === 'emailSettings') {
                requestData.settings.emailSettings = JSON.parse(setting.value);

                if (typeof requestData.settings.emailSettings.mailInfo.sender !== 'object') {
                    requestData.settings.emailSettings.mailInfo.sender = {};
                }
            }
        });
    }
}

const setSetting = async (e) => {
    e.preventDefault();
    validate.value.$touch();

    if (!validate.value.$invalid) {
        processing.value = true;

        axios.post('/api/settings/', requestData).then(() => {
            toast({
                type: 'success',
                text: 'Email settings successfully updated.'
            });

            store.commit('website/setEmailSettings', requestData.settings.emailSettings);

            setTimeout(() => {
                processing.value = false;
            }, 500);
        }).catch((e) => {
            proxy.$errorHandler(e);
            processing.value = false;
        });
    }
}


onMounted(() => {
    getData();

    axios.get('/api/get-recipient-emails').then(({ data }) => {
        if (emailSettings?.value?.mailInfo?.sender?.recipient_email) {
            data = data.filter((item) => item !== emailSettings?.value?.mailInfo?.sender?.recipient_email);
        }

        recipientEmails.value = data
    });
});
</script>

<style lang="scss" scoped>
.product-image-wrapper :deep(.height-img-wrapper) {
    height: 10rem;
    width: 10rem;
}

:deep(.height-img-wrapper) {
    height: 10rem;
    width: 10rem;
}

img {
    height: 10rem;
    width: 10rem;
}
</style>
