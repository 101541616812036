[<template>
    <Layout title="Products">
        <template #body>
            <div v-if="productId && productId > 0" class="mb-4">
                <AttributeTypeComponent
                    :product-id="Number(productId)"
                    :attribute-type="attributes.layer"
                    title="Services"
                    @itemChosen="itemChosen"
                    @itemDeselect="itemDeselect"
                    @clearItemChosen="clearItemChosen"
                />
                <template v-if="unwantedSubTypes">
                    <AttributeTypeComponent
                        v-if="chosen.layer && !chosen.unit"
                        :attribute-type="attributes.option"
                        :product-id="Number(productId)"
                        :parent-id="chosen.layer"
                        :label-price="labelPrice"
                        title="Options"
                        @itemChosen="itemChosen"
                        @itemDeselect="itemDeselect"
                    />
                    <AttributeTypeComponent
                        v-if="chosen.option"
                        :attribute-type="attributes.upgrade"
                        :product-id="Number(productId)"
                        :parent-id="chosen.option"
                        :label-price="labelPrice"
                        title="Upgrades"
                        @itemChosen="itemChosen"
                        @itemDeselect="itemDeselect"
                    />

                  <AttributeTypeComponent
                      v-if="chosen.unit"
                      :attribute-type="attributes.singleUnitItem"
                      :product-id="Number(productId)"
                      :parent-id="chosen.layer"
                      title="Options"
                      label-price="Per Unit Price"
                  />
                </template>
            </div>



            <DeleteModal id="delete-product" @delete="deleteProduct" />
        </template>
    </Layout>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { computed, inject, ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import AttributeTypeComponent from './Partials/AttributeTypeComponent.vue';
import cash from 'cash-dom/dist/cash';
import axios from 'axios';
import Layout from "@layouts/Layout.vue";
import DeleteModal from "../../Components/Modal/DeleteModal.vue";


const router = useRouter();
const store = useStore();
const formData = new FormData();
const toast = inject('toast');
const currentWebsite = computed(() => store.getters['website/website']);
const unwantedSubTypes = computed(() => store.getters['auth/unwantedSubTypes']);
const chosen = ref({
    layer: 0,
    option: 0,
    upgrade: 0,
    unit: 0
});
const attributes = ref({
    hidePrice: null,
    layer: null,
    option: null,
    singleUnitItem: null,
    upgrade: null,
    unit: 5
});
const product = ref({});
const productId = ref('');
const heightItem = ref();
const labelPrice = ref('');


const getProductData = async () => {
    if (!currentWebsite.value.id) return false;

    await axios
        .get(`/api/get-products-for-current-website/${ currentWebsite.value.id }`)
        .then(response => {
            const { data } = response;

            productId.value = data.products[0]['id'];
            attributes.value = data.attributes;
        });

    if (productId.value) {
        await axios
            .get(`/api/product/${ productId.value }`)
            .then(response => {
                const { data } = response;
                product.value = data;
            });
    }
}

const resetChosen = () => {
    chosen.value = {
        option: null,
        upgrade: null,
        layer: null,
        unit: null
    }
}

const itemChosen = (item) => {
    switch (Number(item.type)) {
        case attributes.value.layer:
          if (item.metric.currency.toLowerCase() === 'per unit') {
            resetChosen()
            chosen.value.unit = item.id
            chosen.value.layer = item.id
          } else {
            chosen.value = {
              option: chosen.value.layer !== item.id ? null : chosen.value.option,
              upgrade: chosen.value.layer !== item.id ? null : chosen.value.upgrade,
              layer: item.id,
            }
          }
            break
        case attributes.value.option:
            chosen.value.option = item.id;
            chosen.value.upgrade = null;
            chosen.value.unit = null
            break
        case attributes.value.upgrade:
            chosen.value.upgrade = null;
            chosen.value.unit = null
            heightItem.value = item;
            break
        default:
            resetChosen();
    }

    labelPrice.value = `Price ${store.getters['website/metricValue'](item)}`;
}

const clearItemChosen = () => {
    resetChosen();
}

const itemDeselect = (type) => {
    switch (Number(type)) {
        case attributes.value.layer:
            resetChosen();
            break
        case attributes.value.option:
            chosen.value.option = null;
            chosen.value.upgrade = null;
            break
        case attributes.value.upgrade:
            chosen.value.upgrade = null;
            break
        default:
    }
}

const formDataAppend = (keysValueToAppend) => {
    for (let key in keysValueToAppend) {
        formData.append(key, keysValueToAppend[key]);
    }
}

const fileUploaded = (file) => {
    formData.append('file', file)
}

const deleteProduct = () => {
    axios.post(`/api/product/${ product.value.id }`, {
        id: product.value.id,
        _method: 'delete'
    }).then(() => {
        cash('#delete-product').modal('hide')
        router.push({ name: 'products' })
    })
}


onBeforeMount(async () => {
    if (store.getters['auth/isSales']) {
        return router.push({ name: 'dashboard' });
    }

    await getProductData();

    formDataAppend({'_method': 'put'});
});
</script>


<style lang="scss" scoped>
.product-image-wrapper :deep(.height-img-wrapper) {
    height: 10rem;
    width: 10rem;
}

:deep(.height-img-wrapper) {
    height: 10rem;
    width: 10rem;
}
</style>
